import { JsonDecoder } from 'ts.data.json';
import { accountDecoder } from '../account/decoder';
import { advisorDecoder } from '../advisor/decoder';
import { signingStatusDecoder } from '../docusign/signing-status/decoder';
import { firmDecoder } from '../firm/decoder';
import { holdingOptionDecoder } from '../holding-option/decoder';
import { stringDocusignDateDecoder } from '../../store/models/common';
import {
    orderApprovalConfigDecoder,
    orderApprovalsDecoder,
    orderCurrentApprovalDecoder
} from '../order-approve/decoder';
import { orderFormDecoder } from '../order-form/decoder';
import { productDecoder } from '../product/decoder';
import { profileRepCodeDecoder } from '../profile/decoder';
import {
    DocumentFile,
    ElectronicSignature,
    eSignEnvelope,
    eSignEnvelopeIdCheckType,
    eSignEnvelopeRecipient,
    eSignEnvelopeRecipientStatusType,
    eSignEnvelopeRecipientType,
    eSignEnvelopeStatusType,
    MappedSignatures,
    Order,
    OrderId,
    OrderRecord,
    OrdersStatusHistory,
    OrderValidation,
    OrderValidationFailure,
    PresignatureReview,
    PurchaseType,
    RequiredDocument,
    ReviewStep,
    ReviewStepStatus,
    SettlementDetails,
    SupplementalFile,
    SupplementalFileType
} from '../../models/order/model';

export const orderValidationFailureDecoder = JsonDecoder.object<OrderValidationFailure>(
    {
        code: JsonDecoder.string,
        debugInfo: JsonDecoder.string,
        message: JsonDecoder.string,
        path: JsonDecoder.string
    },
    'OrderValidationFailure'
);

export const orderValidationDecoder = JsonDecoder.object<OrderValidation>(
    {
        failures: JsonDecoder.array<OrderValidationFailure>(
            orderValidationFailureDecoder,
            'OrderValidationFailure[]'
        ),
        orderPassed: JsonDecoder.oneOf(
            [JsonDecoder.boolean, JsonDecoder.string.map(str => str === 'true')],
            'boolean'
        )
    },
    'OrderValidation'
);

export const supplementalFileDecoder = JsonDecoder.object<SupplementalFile>(
    {
        id: JsonDecoder.string,
        name: JsonDecoder.string,
        tooltip: JsonDecoder.optional(JsonDecoder.string)
    },
    'SupplementalFile'
);

export const requiredDocument = JsonDecoder.object<RequiredDocument>(
    {
        id: JsonDecoder.string,
        contextMap: JsonDecoder.optional(JsonDecoder.string),
        dismissedDate: JsonDecoder.optional(JsonDecoder.string),
        dismissedReason: JsonDecoder.optional(JsonDecoder.string),
        required: JsonDecoder.optional(JsonDecoder.boolean).map(Boolean),
        documents: JsonDecoder.optional(JsonDecoder.array<string>(JsonDecoder.string, 'string[]')),
        type: JsonDecoder.optional(JsonDecoder.string),
        tooltip: JsonDecoder.optional(JsonDecoder.string),
        firmId: JsonDecoder.optional(JsonDecoder.number),
        fundId: JsonDecoder.optional(JsonDecoder.number),
        holdingOptionId: JsonDecoder.optional(JsonDecoder.number),
        entityName: JsonDecoder.optional(JsonDecoder.string),
        descriptionHeader: JsonDecoder.optional(JsonDecoder.string),
        descriptionFooter: JsonDecoder.optional(JsonDecoder.string),
        descriptionBullets: JsonDecoder.optional(
            JsonDecoder.array<string>(JsonDecoder.string, 'string[]')
        )
    },
    'RequiredDocument'
);

export const supplementalFileTypeDecoder = JsonDecoder.object<SupplementalFileType>(
    {
        name: JsonDecoder.string
    },
    'SupplementalFileType'
);

export const fileDecoder = JsonDecoder.object<DocumentFile>(
    {
        id: JsonDecoder.string,
        name: JsonDecoder.string,
        formId: JsonDecoder.optional(JsonDecoder.string),
        type: JsonDecoder.string,
        supplementalFileTypeId: JsonDecoder.optional(JsonDecoder.string),
        supplementalFileType: JsonDecoder.optional(supplementalFileTypeDecoder),
        contextMap: JsonDecoder.optional(JsonDecoder.string),
        isUserUploaded: JsonDecoder.optional(JsonDecoder.boolean),
        originalUploadedAt: JsonDecoder.optional(JsonDecoder.string),
        createdAt: JsonDecoder.optional(JsonDecoder.string),
        orderStatus: JsonDecoder.optional(JsonDecoder.string),
        config: JsonDecoder.optional(orderApprovalConfigDecoder),
        firmId: JsonDecoder.optional(JsonDecoder.number),
        fundId: JsonDecoder.optional(JsonDecoder.number),
        holdingOptionId: JsonDecoder.optional(JsonDecoder.number),
        // For internal usage, these don't come from the api
        screenName: JsonDecoder.constant(undefined),
        index: JsonDecoder.constant(undefined),
        descriptionHeader: JsonDecoder.constant(undefined),
        descriptionFooter: JsonDecoder.constant(undefined),
        descriptionBullets: JsonDecoder.constant(undefined)
    },
    'DocumentFile'
);

export const eSignEnvelopeRecipientDecoder = JsonDecoder.object<eSignEnvelopeRecipient>(
    {
        id: JsonDecoder.optional(JsonDecoder.string),
        type: JsonDecoder.oneOf<eSignEnvelopeRecipientType>(
            [
                JsonDecoder.isExactly<'investor'>('investor'),
                JsonDecoder.isExactly<'advisor'>('advisor'),
                JsonDecoder.isExactly<'additional'>('additional'),
                JsonDecoder.isExactly<'postAdvisor'>('postAdvisor')
            ],
            'eSignEnvelopeRecipientType'
        ),
        fullName: JsonDecoder.string,
        email: JsonDecoder.string,
        phoneMobile: JsonDecoder.optional(JsonDecoder.string),
        status: JsonDecoder.optional(
            JsonDecoder.oneOf<eSignEnvelopeRecipientStatusType>(
                [
                    JsonDecoder.isExactly<'AutoResponded'>('AutoResponded'),
                    JsonDecoder.isExactly<'Completed'>('Completed'),
                    JsonDecoder.isExactly<'Created'>('Created'),
                    JsonDecoder.isExactly<'Declined'>('Declined'),
                    JsonDecoder.isExactly<'Delivered'>('Delivered'),
                    JsonDecoder.isExactly<'Sent'>('Sent')
                ],
                'eSignEnvelopeRecipientStatusType'
            )
        ),
        timestamp: JsonDecoder.optional(stringDocusignDateDecoder),
        routingOrder: JsonDecoder.number
    },
    'eSignEnvelopeRecipient'
);

export const eSignEnvelopeDecoder = JsonDecoder.object<eSignEnvelope>(
    {
        id: JsonDecoder.optional(JsonDecoder.string),
        status: JsonDecoder.optional(
            JsonDecoder.oneOf<eSignEnvelopeStatusType>(
                [
                    JsonDecoder.isExactly<'Completed'>('Completed'),
                    JsonDecoder.isExactly<'Created'>('Created'),
                    JsonDecoder.isExactly<'Declined'>('Declined'),
                    JsonDecoder.isExactly<'Delivered'>('Delivered'),
                    JsonDecoder.isExactly<'Sent'>('Sent')
                ],
                'eSignEnvelopeStatusType'
            )
        ),
        created: JsonDecoder.optional(JsonDecoder.string),
        sent: JsonDecoder.optional(JsonDecoder.string),
        idCheckConfig: JsonDecoder.optional(
            JsonDecoder.oneOf<eSignEnvelopeIdCheckType>(
                [
                    JsonDecoder.isExactly<'sms'>('sms'),
                    JsonDecoder.isExactly<'kba'>('kba'),
                    JsonDecoder.isExactly<'phone'>('phone')
                ],
                'eSignEnvelopeIdCheckType'
            )
        ),
        recipients: JsonDecoder.optional(
            JsonDecoder.array<eSignEnvelopeRecipient>(
                eSignEnvelopeRecipientDecoder,
                'eSignEnvelopeRecipients[]'
            )
        )
    },
    'eSignEnvelope'
);

export const eSignDecoder = JsonDecoder.object<ElectronicSignature>(
    {
        isAvailable: JsonDecoder.optional(JsonDecoder.boolean),
        acceptedByUser: JsonDecoder.optional(JsonDecoder.boolean),
        isSelected: JsonDecoder.optional(JsonDecoder.boolean),
        reasons: JsonDecoder.optional(JsonDecoder.array<string>(JsonDecoder.string, 'reasons[]')),
        envelope: JsonDecoder.optional(eSignEnvelopeDecoder),
        wetSignRoles: JsonDecoder.optional(
            JsonDecoder.array<string>(JsonDecoder.string, 'wetSignRoles[]')
        ),
        mappedSignatures: JsonDecoder.optional(
            JsonDecoder.object<MappedSignatures>(
                {
                    status: JsonDecoder.optional(JsonDecoder.string)
                },
                'mappedSignatures'
            )
        )
    },
    'ElectronicSignature'
);

export const settlementDetailsDecoder = JsonDecoder.object<SettlementDetails>(
    {
        markComplete: JsonDecoder.optional(JsonDecoder.boolean),
        tradeDate: JsonDecoder.optional(JsonDecoder.string),
        settlementDate: JsonDecoder.optional(JsonDecoder.string),
        purchaseAmount: JsonDecoder.optional(JsonDecoder.number),
        numberOfShares: JsonDecoder.optional(JsonDecoder.number),
        pricePerShare: JsonDecoder.optional(JsonDecoder.number),
        fundAccountNumber: JsonDecoder.optional(JsonDecoder.string),
        processingNotes: JsonDecoder.optional(JsonDecoder.string),
        markedCompletedDate: JsonDecoder.optional(JsonDecoder.string),
        markedCompletedByFirm: JsonDecoder.optional(JsonDecoder.string),
        updatedByFirm: JsonDecoder.optional(JsonDecoder.string),
        updatedAt: JsonDecoder.optional(JsonDecoder.string)
    },
    'SettlementDetails'
);

export const reviewStepDecoder = JsonDecoder.object<ReviewStep>(
    {
        approvalName: JsonDecoder.string,
        entityType: JsonDecoder.string,
        name: JsonDecoder.string,
        status: JsonDecoder.oneOf<ReviewStepStatus>(
            [
                JsonDecoder.isExactly<'not yet sent'>('not yet sent'),
                JsonDecoder.isExactly<'pending'>('pending'),
                JsonDecoder.isExactly<'approved'>('approved')
            ],
            'ReviewStepStatus'
        ),
        updatedAt: JsonDecoder.optional(JsonDecoder.string)
    },
    'reviewStepDecoder'
);

export const presignatureReviewDecoder = JsonDecoder.object<PresignatureReview>(
    {
        required: JsonDecoder.boolean,
        steps: JsonDecoder.array<ReviewStep>(reviewStepDecoder, 'ReviewStep[]')
    },
    'PresignatureReview'
);

export const orderDecoder = JsonDecoder.object<Order>(
    {
        title: JsonDecoder.optional(JsonDecoder.string).map(title => title || ''),
        id: JsonDecoder.string,
        status: JsonDecoder.string,
        statusLabel: JsonDecoder.string,
        completed: JsonDecoder.number,
        orderNumber: JsonDecoder.number,
        purchaseAmount: JsonDecoder.number,
        customData: JsonDecoder.succeed,
        orderSpecificData: JsonDecoder.succeed,
        purchaseType: JsonDecoder.oneOf<PurchaseType>(
            [
                JsonDecoder.isExactly<'Initial'>('Initial'),
                JsonDecoder.isExactly<'Additional'>('Additional')
            ],
            'PurchaseType'
        ),
        account: accountDecoder,
        holdingOption: holdingOptionDecoder,
        fund: productDecoder,
        advisor: JsonDecoder.optional(advisorDecoder),
        forms: JsonDecoder.dictionary(orderFormDecoder, '{[name: string]: OrderForm}'),
        files: JsonDecoder.array(fileDecoder, 'DocumentFile[]'),
        requiredSupplementalFiles: JsonDecoder.array(requiredDocument, 'RequiredDocument[]'),
        repCode: profileRepCodeDecoder,
        signingStatus: JsonDecoder.optional(signingStatusDecoder),
        validation: JsonDecoder.optional(orderValidationDecoder),
        approvals: JsonDecoder.optional(orderApprovalsDecoder),
        currentApproval: JsonDecoder.optional(orderCurrentApprovalDecoder),
        firm: firmDecoder,
        envelopeId: JsonDecoder.string,
        eSign: JsonDecoder.optional(eSignDecoder),
        wetSign: JsonDecoder.optional(JsonDecoder.boolean),
        settlementDetails: JsonDecoder.oneOf(
            [JsonDecoder.nullable(settlementDetailsDecoder), JsonDecoder.isUndefined(null)],
            'SettlementDetails'
        ),
        createdBy: JsonDecoder.optional(advisorDecoder),
        presignatureReview: JsonDecoder.optional(presignatureReviewDecoder)
    },
    'Order'
);

export const orderRecordDecoder = JsonDecoder.object<OrderRecord>(
    {
        id: JsonDecoder.string,
        orderNumber: JsonDecoder.number,
        purchaseAmount: JsonDecoder.optional(JsonDecoder.number),
        status: JsonDecoder.string,
        queueLabel: JsonDecoder.string,
        firmId: JsonDecoder.number,
        firmName: JsonDecoder.string,
        title: JsonDecoder.optional(JsonDecoder.string).map(title => title || ''),
        fundName: JsonDecoder.string,
        ticker: JsonDecoder.string,
        updatedAt: JsonDecoder.string,
        role: JsonDecoder.optional(JsonDecoder.string),
        repCode: profileRepCodeDecoder,
        advisorFirstName: JsonDecoder.string,
        advisorLastName: JsonDecoder.string,
        advisorFullName: JsonDecoder.optional(JsonDecoder.string),
        additionalAdvisorNames: JsonDecoder.optional(JsonDecoder.string),
        custodianName: JsonDecoder.string,
        createdByName: JsonDecoder.string
    },
    'OrderRecord'
);

export const orderIdDecoder = JsonDecoder.object<OrderId>(
    {
        id: JsonDecoder.string,
        validation: JsonDecoder.optional(orderValidationDecoder)
    },
    'OrderId'
);

export const orderFiltersDecoder = JsonDecoder.array(
    JsonDecoder.object<OrdersStatusHistory>(
        {
            status: JsonDecoder.string,
            updatedBy: JsonDecoder.string,
            updatedByOrganization: JsonDecoder.string,
            updatedByType: JsonDecoder.string,
            createdAt: JsonDecoder.string,
            role: JsonDecoder.string
        },
        'OrdersStatusHistory'
    ),
    'OrdersStatusHistory[]'
);
