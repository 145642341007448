import {
    ChangeDetectorRef,
    Component,
    effect,
    forwardRef,
    input,
    model,
    OnDestroy,
    OnInit,
    output
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormsModule,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { validatePhoneFactory } from '@trade-platform/ui-utils';
import { Subscription } from 'rxjs';
import { isString } from 'lodash-es';
import { CountryCode } from '../aix-country-code-dropdown/country-codes';
import { AixNumberComponent } from '../aix-number/aix-number.component';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { AixPhoneComponent } from '../aix-phone/aix-phone.component';
import { NgIf } from '@angular/common';
import { AixCountryCodeDropdownComponent } from '../aix-country-code-dropdown/aix-country-code-dropdown';

export interface IntlPhoneValue {
    countryCode: string;
    phoneNumber: string;
}

@Component({
    selector: 'aix-intl-phone',
    styleUrls: ['./aix-intl-phone.component.scss'],
    templateUrl: './aix-intl-phone.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixIntlPhoneComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: AixIntlPhoneComponent
        }
    ],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        AixCountryCodeDropdownComponent,
        NgIf,
        AixPhoneComponent,
        AixDataTestingDirective,
        AixNumberComponent
    ]
})
export class AixIntlPhoneComponent implements ControlValueAccessor, OnInit, OnDestroy {
    config = input<any>();
    isStandalone = input<boolean>(true);
    isRequired = input<boolean>(false);
    isDisabled = input<boolean>(false);
    isValid = input<boolean>(false);
    isDirty = model<boolean>(false);
    label = input<string>('Phone Number');
    initialValue = input<IntlPhoneValue>();

    valueChanges = output<IntlPhoneValue>();
    setDirty = output();

    _initialValue: IntlPhoneValue = {
        countryCode: '1',
        phoneNumber: ''
    };
    form: UntypedFormGroup;

    showProgress = false;
    subscriptions: Subscription[] = [];

    private _onChange: (value: any | null | undefined) => void;
    _propagateChanges: (value: string) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    constructor(private _fb: UntypedFormBuilder, private cd: ChangeDetectorRef) {
        this._createFormGroup();
        this._setupObservables();

        effect(() => {
            if (this.initialValue()) {
                if (isString(this.initialValue())) {
                    this._initialValue.phoneNumber = this.initialValue() as unknown as string;
                } else {
                    this._initialValue.phoneNumber = (
                        this.initialValue() as unknown as IntlPhoneValue
                    ).phoneNumber;
                    this._initialValue.countryCode = (
                        this.initialValue() as unknown as IntlPhoneValue
                    ).countryCode;
                }

                this.form.patchValue({
                    phoneNumber: this._initialValue.phoneNumber,
                    countryCode: this._initialValue.countryCode
                });
                this.form.updateValueAndValidity();
                this.cd.detectChanges();
            }
        });

        effect(() => {
            const phoneNumber: AbstractControl | null = this.form.get('phoneNumber');

            if (this.isRequired()) {
                if (this._initialValue.countryCode !== '1') {
                    phoneNumber?.setValidators([
                        Validators.required,
                        Validators.pattern(/^[0-9]+$/)
                    ]);
                } else {
                    phoneNumber?.setValidators([Validators.required, validatePhoneFactory()]);
                }
            } else {
                if (this._initialValue.countryCode !== '1') {
                    phoneNumber?.setValidators([Validators.pattern(/^[0-9]+$/)]);
                } else {
                    phoneNumber?.setValidators([validatePhoneFactory()]);
                }
            }

            this.form.get('phoneNumber')?.updateValueAndValidity();
            this.form.updateValueAndValidity();
            this.cd.detectChanges();
        });
    }

    ngOnInit() {
        this.form.patchValue({});
    }

    onUserInput(value: string) {
        if (!this.isDirty()) {
            this.setDirty.emit();

            // It's standalone, we set the dirty state here instead through the store
            if (this.isStandalone()) {
                this.isDirty.set(true);
            }
        }
        this._propagateChanges(value);
    }

    onUserChangesCountryCode(code: CountryCode) {
        const phoneNumber: string =
            code.value === '1'
                ? this.form.get('phoneNumber')?.value.slice(0, 10)
                : this.form.get('phoneNumber')?.value;
        const newValue = {
            phoneNumber: phoneNumber,
            countryCode: code.value
        };

        this._initialValue.countryCode = code.value;
        this.form.patchValue(newValue);
        this.form.updateValueAndValidity();
        this.valueToSend(newValue);
        this.cd.detectChanges();
    }

    valueToSend(rawValue: IntlPhoneValue | string) {
        if (isString(rawValue)) {
            this._initialValue.phoneNumber = rawValue;
        } else {
            this._initialValue.phoneNumber = rawValue.phoneNumber;
            this._initialValue.countryCode = rawValue.countryCode;
        }

        this.valueChanges.emit(this._initialValue);
        return this._initialValue;
    }

    clearForm() {
        this._initialValue.countryCode = '1';
        this.form.setValue({
            countryCode: this._initialValue.countryCode,
            phoneNumber: null
        });
    }

    validate(ctrl: UntypedFormControl) {
        const isValid = !this.isRequired || this.form.valid ? null : { invalid: true };

        if (this._initialValue.countryCode === '1') {
            return !ctrl.value || ctrl.value.phoneNumber?.match(/\d\d\d\d\d\d\d\d\d\d/g)
                ? null
                : isValid;
        } else {
            return isValid;
        }
    }

    writeValue(value: any | null | undefined): void {
        this.form.setValue({
            countryCode: value?.hasOwnProperty('countryCode') ? value.countryCode : '1',
            phoneNumber: value?.hasOwnProperty('phoneNumber') ? value.phoneNumber : value
        });
        this.form.updateValueAndValidity();
    }

    registerOnChange(fn: (value: any | null | undefined) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        // TODO: implement this method
        // throw new Error('registerOnTouched not implemented');
    }

    clearField(path: string) {
        this.form.get(path)?.setValue(null);
    }

    private _createFormGroup() {
        this.form = this._fb.group({
            countryCode: this._fb.control('1', []),
            phoneNumber: this._fb.control(null, [Validators.required, validatePhoneFactory()])
        });
    }

    private _setupObservables() {
        this.subscriptions.push(
            this.form.valueChanges.subscribe(value => {
                const phoneNumber: AbstractControl | null = this.form.get('phoneNumber');

                if (value.countryCode && value.countryCode !== this._initialValue.countryCode) {
                    this._initialValue.countryCode = value.countryCode;
                    if (value.countryCode !== '1') {
                        phoneNumber?.setValidators([
                            Validators.required,
                            Validators.pattern(/^[0-9]+$/)
                        ]);
                    } else {
                        phoneNumber?.setValidators([Validators.required, validatePhoneFactory()]);
                    }
                    this.form.get('phoneNumber')?.updateValueAndValidity();
                }

                if (
                    isString(value.phoneNumber) &&
                    value.phoneNumber !== this._initialValue.phoneNumber
                ) {
                    this._initialValue.phoneNumber = value.phoneNumber;
                    this.valueToSend(value);
                }

                if (this._onChange) {
                    this._onChange(value);
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
