import { NavigationExtras } from '@angular/router';
import { ProductType } from '@trade-platform/ui-shared';

export const routeConstants = {
    routes: {
        notFound: { index: () => ['/not-found'] },
        register: { index: () => ['/register'] },
        terms: { index: () => ['/end-user-access-agreement'] },
        accounts: {
            index: () => ['/accounts'],
            base: (accountId: string) => ['/accounts', accountId.toString()],
            show: (accountId: string) => ['/accounts', accountId.toString(), 'overview'],
            performance: (accountId: string) => ['/accounts', accountId.toString(), 'performance'],
            positions: (accountId: string) => ['/accounts', accountId.toString(), 'positions'],
            documents: (accountId: string) => ['/accounts', accountId.toString(), 'documents'],
            orders: (accountId: string) => ['/accounts', accountId.toString(), 'orders'],
            documentViewer: (accountId: string, id: string) => [
                '/accounts',
                accountId.toString(),
                'document-viewer',
                id
            ]
        },
        products: {
            index: () => ['/products'],
            explore: () => ['/products', 'explore'],
            exploreProfile: (fundSponsorId: string) => [
                '/products',
                'explore',
                'profile',
                fundSponsorId
            ],
            detail: (fundId: number, productId: number) => [
                '/products',
                'detail',
                fundId,
                productId,
                'materials'
            ],
            detailOverview: (fundId: number, productId: number) => [
                '/products',
                'detail',
                fundId,
                productId,
                'overview'
            ],
            send: (fundId: number, productId: number) => [
                '/products',
                'detail',
                fundId,
                productId,
                'materials',
                'send'
            ],
            documentViewer: (fundId: number, productId: number, materialId: string) => [
                '/products',
                'detail',
                fundId,
                productId,
                'materials',
                materialId,
                'document-viewer'
            ]
        },
        accessRequest: {
            index: () => ['/orders', 'access-request'],
            detail: (requestId: string) => [
                '/orders',
                'access-request',
                requestId.toString(),
                'detail'
            ]
        },
        productMaterialsOrders: {
            status: () => ['/orders', 'product-materials', 'status'],
            detailOverview: (orderId: string) => [
                '/orders',
                'product-materials',
                'detail',
                orderId.toString(),
                'overview'
            ],
            detailProductMaterials: (orderId: string) => [
                '/orders',
                'product-materials',
                'detail',
                orderId.toString(),
                'product-materials'
            ],
            documentViewer: (orderId: string, materialId: string) => [
                '/orders',
                'product-materials',
                orderId.toString(),
                'document-viewer',
                materialId.toString()
            ]
        },
        purchase: {
            start: (fundId: number, type?: ProductType) => [
                '/orders',
                'buy',
                type || 'product',
                fundId.toString()
            ],
            startParams: (shareClass: number | null = null): NavigationExtras => ({
                queryParams: { shareClass: shareClass }
            }),
            detailOverview: (orderId: string) => [
                '/orders',
                'buy',
                'detail',
                orderId.toString(),
                'overview'
            ],
            documentViewer: (orderId: string, docId: string) => [
                '/orders',
                'buy',
                orderId.toString(),
                'document-viewer',
                docId
            ],
            supportingDocuments: (orderId: string) => [
                '/orders',
                'buy',
                'detail',
                orderId.toString(),
                'supporting-documents'
            ],
            detailStatus: (orderId: string) => [
                '/orders',
                'buy',
                'detail',
                orderId.toString(),
                'status'
            ],
            detailUploadDocuments: (orderId: string) => [
                '/orders',
                'buy',
                'detail',
                orderId.toString(),
                'upload-documents'
            ],
            status: () => ['/orders', 'buy', 'status'],
            process: {
                overview: (orderId: string) => ['/orders', 'buy', 'process', orderId.toString()],
                completeForms: (orderId: string) => [
                    '/orders',
                    'buy',
                    'process',
                    orderId.toString(),
                    'complete-forms'
                ],
                uploadDocuments: (orderId: string) => [
                    '/orders',
                    'buy',
                    'process',
                    orderId.toString(),
                    'upload-documents'
                ],
                reviewOrder: (orderId: string) => [
                    '/orders',
                    'buy',
                    'process',
                    orderId.toString(),
                    'review-order'
                ],
                collectSignatures: (orderId: string) => [
                    '/orders',
                    'buy',
                    'process',
                    orderId.toString(),
                    'collect-signatures'
                ],
                finalizeOrder: (orderId: string) => [
                    '/orders',
                    'buy',
                    'process',
                    orderId.toString(),
                    'finalize-order'
                ]
            },
            form: (orderId: string, formId: string) => [
                '/orders',
                'buy',
                'process',
                orderId.toString(),
                'forms',
                formId.toString()
            ]
        },
        maintenanceProducts: { index: () => ['/products', 'maintenance'] },
        maintenance: {
            start: (fundId: number, type?: ProductType) => [
                '/orders',
                'maintenance',
                type === 'generic-maintenance' ? type : 'start',
                fundId.toString()
            ],
            startParams: (shareClass: number | null = null): NavigationExtras => ({
                queryParams: { shareClass: shareClass }
            }),
            status: () => ['/orders', 'maintenance', 'status'],
            detailOverview: (orderId: string) => [
                '/orders',
                'maintenance',
                'detail',
                orderId.toString(),
                'overview'
            ],
            documentViewer: (orderId: string, docId: string) => [
                '/orders',
                'maintenance',
                orderId.toString(),
                'document-viewer',
                docId
            ],
            supportingDocuments: (orderId: string) => [
                '/orders',
                'maintenance',
                'detail',
                orderId.toString(),
                'supporting-documents'
            ],
            detailStatus: (orderId: string) => [
                '/orders',
                'maintenance',
                'detail',
                orderId.toString(),
                'status'
            ],
            detailUploadDocuments: (orderId: string) => [
                '/orders',
                'maintenance',
                'detail',
                orderId.toString(),
                'upload-documents'
            ],
            process: {
                overview: (orderId: string) => [
                    '/orders',
                    'maintenance',
                    'process',
                    orderId.toString()
                ],
                completeForms: (orderId: string) => [
                    '/orders',
                    'maintenance',
                    'process',
                    orderId.toString(),
                    'complete-forms'
                ],
                uploadDocuments: (orderId: string) => [
                    '/orders',
                    'maintenance',
                    'process',
                    orderId.toString(),
                    'upload-documents'
                ],
                reviewOrder: (orderId: string) => [
                    '/orders',
                    'maintenance',
                    'process',
                    orderId.toString(),
                    'review-order'
                ],
                collectSignatures: (orderId: string) => [
                    '/orders',
                    'maintenance',
                    'process',
                    orderId.toString(),
                    'collect-signatures'
                ],
                finalizeOrder: (orderId: string) => [
                    '/orders',
                    'maintenance',
                    'process',
                    orderId.toString(),
                    'finalize-order'
                ]
            },
            form: (orderId: string, formId: string) => [
                '/orders',
                'maintenance',
                'process',
                orderId.toString(),
                'forms',
                formId.toString()
            ]
        },
        admin: {
            repcodes: () => ['/admin', 'repcodes'],
            newRepcode: () => ['/admin', 'repcodes', 'new'],
            editRepcode: (repcode: string) => ['/admin', 'repcodes', repcode.toString(), 'edit'],
            accessGroups: () => ['/admin', 'access-groups'],
            newAccessGroup: () => ['/admin', 'access-groups', 'new'],
            editAccessGroup: (accessGroup: string) => [
                '/admin',
                'access-groups',
                accessGroup.toString(),
                'edit'
            ],
            users: (filter = '') =>
                filter ? ['/admin', 'users', { filter: filter }] : ['/admin', 'users'],
            userNew: () => ['/admin', 'users', 'new'],
            userEdit: (id: string) => ['/admin', 'users', id.toString(), 'edit'],
            branding: () => ['/admin', 'branding'],
            forms: () => ['/admin', 'forms'],
            products: () => ['/admin', 'products'],
            product: (productId: number) => ['/admin', 'products', 'detail', productId],
            productMaterial: (productId: number, materialId: string) => [
                '/admin',
                'products',
                productId,
                'materials',
                materialId,
                'document-viewer'
            ],
            productDetail: (productId: number) => ['/admin', 'products', 'detail', productId],
            formDocumentViewer: (formId: string) => ['/admin', 'forms', formId, 'document-viewer'],
            firms: () => ['/admin', 'firms'],
            firm: (firmId: number) => ['/admin', 'firms', 'detail', firmId],
            firmMaterial: (firmId: number, materialId: string) => [
                '/admin',
                'firms',
                firmId,
                'materials',
                materialId,
                'document-viewer'
            ]
        },
        logout: {
            index: () => ['/logout'],
            params: (usePreviousUrl = false): NavigationExtras => ({
                queryParams: { usePreviousUrl: usePreviousUrl }
            })
        },
        externalDocusignRedirect: {
            index: (url: string) => ['/externalDocusignRedirect', { externalDocusignUrl: url }],
            params: (usePreviousUrl = false): NavigationExtras => ({
                queryParams: { usePreviousUrl: usePreviousUrl }
            })
        }
    }
};
