@if (title()) {
<p class="u-mt16">
    {{ title() }} @if (isRequired()) {
    <span>*</span>
    }
</p>
} @for (op of options(); track op) {

<div #radio class="radio-custom-wrapper-full" data-testing="radio-group-item">
    <label
        class="radio-custom radio-custom-label-size"
        [data-testing]="op[valueField()]"
        [class.aix-disabled]="isDisabled() || op.disabled"
    >
        <input
            type="radio"
            data-testing="input"
            [name]="name"
            [value]="op[valueField()]"
            [checked]="value() === op[valueField()]"
            (click)="onUserInput($event)"
            [class.aix-disabled]="isDisabled() || op.disabled"
            [attr.disabled]="isDisabled() || op.disabled ? '' : null"
            [attr.tabindex]="isDisabled() || op.disabled ? '-1' : ''"
        />
        <span
            class="radio-custom__indicator"
            [class.aix-form__control--invalid]="
                (value() === null || value() === '') && isRequired()
            "
            [class.aix-form__control--invalid-required]="
                (value() === null || value() === '') && isRequired()
            "
        ></span>
        <span class="radio-custom__label radio-custom__label--xl">
            <span data-testing="title">
                {{ op[labelField()] }}
                @if (op[descriptionField()]) {
                <span class="radio-custom__sublabel" data-testing="description">
                    {{ op[descriptionField()] }}
                </span>
                }
            </span>
        </span>
        @if (op[tooltipField()]) {
        <span
            aixTooltip
            [aixTooltipContent]="op[tooltipField()]"
            [data-testing]="op[valueField()] + '__tooltip'"
        ></span>
        }
    </label>
</div>
}
