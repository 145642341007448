@if (title && arrayForms && arrayForms.length > 0) {
<div class="flex-left-center">
    <h5>{{ title }}</h5>
</div>
} @for (item of arrayForms; track item) {
    <div class="flex-col" [attr.data-testing]="'required-forms-' + item.id">
        <div class="order-step-form-row" data-testing="form-list-row">
            <div class="order-step-form-row-container">
                <div>
                    @if (store.order?.status !== orderStatus.pendingManualSubmission) {
                    <a
                        class="order-step-form-row-title"
                        data-testing="form-list-title"
                        [ngClass]="{ 'br-link': (item.isOnboarded !== false || isFormNotOnboardedWithComments(item) || isFormNIGOUploaded(item) || (isFormUserUploaded(item) && currentStepIsNot(store.orderSteps$ | async, 1))), 'u-no-underline': (isFormNotOnboardedWithoutComments(item) && !isFormNIGOUploaded(item)) }"
                        [class.selectable]="clickable && (item.isOnboarded !== false || isFormNotOnboardedWithComments(item))"
                        (click)="viewEvent(item)"
                    >
                        {{ item.name }}
                    </a>
                    } @if (store.order?.status === orderStatus.pendingManualSubmission) {
                    <span class="order-step-form-row-title" data-testing="form-list-title">
                        {{ item.name }}
                    </span>
                    } @if (isFormNotOnboardedWithoutComments(item) && !isFormNIGOUploaded(item) &&
                    currentStepIsNot(store.orderSteps$ | async, 5) && !(isFormNotOnboardedInReview(item)
                    && isFormUserUploaded(item))) {
                    <span
                        class="formlist-icon u-pt4"
                        (click)="viewEvent(item)"
                        [class.selectable]="clickable"
                        [aixTooltipContent]="getNotOnboardedTooltipText(item)"
                        aixTooltip
                    >
                    </span>
                    } @if (currentStepIsNot(store.orderSteps$ | async, 1) && item.isOnboarded &&
                    item.noSignatures) {
                    <span
                        class="formlist-icon u-pt4"
                        [aixTooltipContent]="getNotOnboardedTooltipText(item)"
                        aixTooltip
                    >
                    </span>
                    } @if (!hideFormDocumentOwner) {
                    <p class="order-step-form-row-subtitle" data-testing="form-list-subtitle">
                        <span>{{ item.documentOwner === 'Alternative Investment Exchange' ? 'Your Firm' : item.documentOwner }}</span>
                    </p>
                    }
                </div>
            </div>
            @if (store.order?.status !== orderStatus.pendingManualSubmission) {
            <div class="order-step-form-row__actions" data-testing="form-list-actions">
                <span class="order-step-action-items br-primary__color">
                    @if (item.status === formStatus.completed && !store.order?.wetSign &&
                    (item.isOnboarded !== false || (isFormNotOnboardedWithoutComments(item) &&
                    currentStepIsNot(store.orderSteps$ | async, 3) && currentStepIsNot(store.orderSteps$
                    | async, 4)))) {
                    <span
                        class="fa-regular fa-check"
                        data-testing="check-icon"
                        [class.u-green]="!store.order?.wetSign && isPending"
                    >
                    </span>
                    } @if (!store.order?.wetSign && isPending && (item.isOnboarded !== false ||
                    isFormNotOnboardedWithoutComments(item))) {
                    <span class="u-green"> &nbsp;Signed </span>
                    }
                    
                    @if (showManageMenu(item)) {
                        <span class="order-step-action-items order-step-action-items__remove">
                            <aix-inline-dropdown
                                [dropdownOptions]="getManageDropdownOptions(item)"
                                [label]="'Manage'"
                                (optionSelected)="onOptionSelected($event, item)"
                                [disabledOptions]="isDisabledFile(item.file)"
                            >
                            </aix-inline-dropdown>
                        </span>
                    }
                    
                    @if ((item?.status !== formStatus.completed) && getFormCommentsCount(item.id) > 0)
                    {
                    <span
                        (click)="viewEvent(item)"
                        [class.selectable]="clickable"
                        class="u-warning-orange u-ml16 u-pb6"
                        data-testing="action-items-count"
                    >
                        <i class="fa-regular fa-triangle-exclamation" title="Resolve NIGO comments">
                        </i>
                        {{ formCommentsLabel(item) }}
                    </span>
                    } @if (isFormNotOnboardedInReview(item) && !isFormUserUploaded(item)) {
                    <aix-button
                        [buttonType]="uploadButtonType"
                        [buttonLabel]="!item.isOnboarded ? 'Upload Form' : 'Upload'"
                        [icon]="'fa-upload'"
                        class="u-pl16 selectable"
                        data-testing="upload-button"
                        (click)="item.isOpen = true"
                    >
                    </aix-button>
                    }
                </span>
            </div>
            }
        </div>

        @if (showRelabelDialog(item)) {
            <div class="file-upload-container">
                <div>
                    <div class="flex-between-center u-mb16">
                        <span class="u-font-size--16 u-fw500">Label Forms</span>
                        <span
                            class="fa-regular fa-xmark u-color-tertiary-black"
                            (click)="relabelForm = null;"
                        >
                        </span>

                    </div>
                    <aix-label-uploaded-form-documents
                        class="aix-form__container u-block"
                        (filesUpdated)="aixFileUpload.onRelationsSaved()"
                        [relabelForm]="relabelForm"
                        (dismissModal)="relabelForm = null;"
                    >
                    </aix-label-uploaded-form-documents>
                </div>
            </div>
        }

        @if (showMissingLabel(item)) {
            <div class="missing-label-container">
                <aix-tag
                    class="missing-label-badge"
                    [color]="'warn'"
                    [icon]="'far fa-exclamation-triangle'"
                    [label]="'Document Missing Label'"
                    [labelColor]="'#263238'"
                ></aix-tag>
            </div>
        }
    
        @if (item.isOpen && (!isFormNotOnboardedInReview(item) || isFormUserUploaded(item))) {
        <div class="file-upload-container">
            @for (comment of unresolvedFileComments; track comment) {
            <p class="order-detail-modal-comments u-mb16" data-testing="unresolved-file-comment">
                {{ comment.text }}
            </p>
            } @if (unresolvedFileComments.length > 0) {
            <p class="u-mb16">{{ getOrderFileCommentsText() }}</p>
            }
            <div class="flex-between-center">
                <span class="u-font-size--16 u-fw500">Replace Upload</span>
                <span
                    class="fa-regular fa-xmark u-color-tertiary-black"
                    (click)="item.isOpen = false"
                ></span>
            </div>
            <div class="u-mt16">
                <aix-upload-document
                    [uploadUrl]="uploadUrl"
                    [type]="fileType.supplemental"
                    [multiple]="false"
                    [isModal]="true"
                    [replacePayload]="getReplacePayload(item)"
                    [fileSizeLimit]="fileSizeLimit"
                    (filesSelected)="onReplaceFilesSelected($event)"
                    (filesUploaded)="onFilesUploaded(item)"
                >
                </aix-upload-document>
            </div>
        </div>
        } @if (item.isOpen && (isFormNotOnboardedInReview(item) && !isFormUserUploaded(item))) {
        <div class="file-upload-container">
            <div class="flex-between-center">
                <span class="u-font-size--16 u-fw500" data-testing="upload-title">Upload</span>
                <span
                    class="fa-regular fa-xmark u-color-tertiary-black"
                    data-testing="close-upload-button"
                    (click)="item.isOpen = false"
                >
                </span>
            </div>
            <div class="u-mt16">
                {{ notOnboardedFormWithSignaturesInstructions }}
            </div>
            <aix-upload-document
                [uploadUrl]="uploadUrl"
                [type]="fileType.form"
                [formId]="item.id"
                [multiple]="false"
                [isModal]="true"
                [fileSizeLimit]="fileSizeLimit"
                (filesSelected)="onReplaceFilesSelected($event)"
                (filesUploaded)="onFilesUploaded(item)"
            >
            </aix-upload-document>
        </div>
        }
    </div>
}

@if (showUploadMoreForms()) {
    <div class="order-step-form-row no-border">
        <div class="order-step-form-row-container">
            <span class="order-step-form-row-title">
                Do you have other forms that need to be signed?
            </span>
            <span
                class="formlist-icon u-pt4"
                [aixTooltipContent]="notOnboardedFormWithSignaturesInstructions"
                aixTooltip
            >
            </span>
        </div>
        <div class="order-step-form-row__actions">
            <span class="order-step-action-items br-primary__color">
                <aix-button
                    [buttonType]="uploadButtonType"
                    buttonLabel="Upload Forms"
                    [icon]="'fa-upload'"
                    class="u-pl16 selectable"
                    [isDisabled]="aixFileUpload.fileUploadOpen"
                    (click)="aixFileUpload.onUploadDocuments()"
                >
                </aix-button>
            </span>
        </div>
    </div>
}

<aix-file-upload #aixFileUpload
    [type]="fileType.form"
    [instructionalText]="notOnboardedFormWithSignaturesInstructions"
>
</aix-file-upload>
