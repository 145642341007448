import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import {
    AbstractControl,
    ControlValueAccessor,
    FormsModule,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { ProfileRepCode, UserByAdvisor } from '@trade-platform/ui-shared';
import { ProfileStoreFacade } from '@advisor-ui/app-services';
import { ExternalFirm } from '../../store/fund-firms/model';
import {
    AixDataTestingDirective,
    AixDropdownComponent,
    AixNextPendingFieldDirective,
    AixRadioGroupComponent
} from '@trade-platform/ui-components';
import { NgIf } from '@angular/common';

@Component({
    selector: 'aix-advisor-firm-information',
    templateUrl: './advisor-firm-information.html',
    styleUrls: ['./advisor-firm-information.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AixAdvisorFirmInformationGroupComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: AixAdvisorFirmInformationGroupComponent,
            multi: true
        }
    ],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        AixDropdownComponent,
        AixDataTestingDirective,
        NgIf,
        AixRadioGroupComponent,
        AixNextPendingFieldDirective
    ]
})
export class AixAdvisorFirmInformationGroupComponent
    implements ControlValueAccessor, OnChanges, OnDestroy
{
    @Input() startOrder: any;
    @Input() firms: ExternalFirm[] = [];
    @Input() advisors: UserByAdvisor[] = [];

    @Output() onSelectRepCode: EventEmitter<string> = new EventEmitter<string>();
    @Output() loadTrainingData: EventEmitter<void> = new EventEmitter();

    form: UntypedFormGroup;

    externalFirmId: number | null;
    advisor: string | null;
    advisorTypes = [
        {
            value: 'brokerDealer',
            title: 'Broker Dealer'
        },
        {
            value: 'registeredInvestmentAdvisor',
            title: 'Registered Investment Advisor'
        }
    ];
    repCode: string | null;

    subscriptions: Subscription[] = [];

    private _onChange: (value: any | null | undefined) => void;
    private _destroy$: Subject<void> = new Subject<void>();

    constructor(private _fb: UntypedFormBuilder, public profileStore: ProfileStoreFacade) {
        this._createFormGroup();
        this._setupObservables();
    }

    selectFirm(value: ExternalFirm) {
        if (value) {
            this.externalFirmId = value.id;
            if (value.type === 'RIA') {
                this.form.patchValue({ firmType: 'registeredInvestmentAdvisor' });
            } else if (
                value.type === 'Broker Dealer' ||
                value.type === 'N/A' ||
                value.type === 'Insurance'
            ) {
                this.form.patchValue({ firmType: 'brokerDealer' });
            }
        }
    }

    selectRepCode(value: ProfileRepCode) {
        if (value.id && this.repCode !== value.id) {
            this.advisor = null;
            this.form.patchValue({ advisor: '' });
        }
        this.onSelectRepCode.emit(value.id);
    }

    selectAdvisor(value: UserByAdvisor) {
        const repCode = this.form.get('repCode')?.value;
        if (value && repCode?.length) {
            this.repCode = repCode[0]?.id as string;
            setTimeout(() => {
                this.loadTrainingData.emit();
            }, 100);
        }
    }

    firmLabelStrategy({ name, crdNumber, type }: ExternalFirm) {
        if (crdNumber && crdNumber !== '') {
            return `${crdNumber} - ${name} - ${type}`;
        } else {
            return `${name} - ${type}`;
        }
    }

    repCodeLabelStrategy(repCode: ProfileRepCode) {
        return `${repCode.fullName} - ${repCode.repCode}`;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.firms && this.firms.length === 1 && !this.externalFirmId) {
            this.form.patchValue({ firm: this.firms });
        }
        if (
            changes.startOrder &&
            this.startOrder &&
            this.startOrder.repCodes.length === 1 &&
            !this.repCode
        ) {
            this.repCode = this.startOrder.repCodes[0].id;
            this.form.patchValue({ repCode: this.startOrder.repCodes });
        }
        if (changes.advisors && this.advisors.length === 1 && !this.advisor) {
            this.form.patchValue({ advisor: this.advisors });
        }
    }

    patchForm() {
        const data = {};

        this.form.patchValue(data);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());

        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

    writeValue(value: any | null | undefined): void {
        if (!value) {
            return;
        }
    }

    validate(control: AbstractControl) {
        return this.form.valid ? null : { invalid: true };
    }

    registerOnChange(fn: (value: any | null | undefined) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        // TODO: implement this method
        // throw new Error('registerOnTouched not implemented');
    }

    private _createFormGroup() {
        this.form = this._fb.group({
            firmType: this._fb.control('', [Validators.required]),
            firm: this._fb.control('', [Validators.required]),
            repCode: this._fb.control('', [Validators.required]),
            advisor: this._fb.control('', [Validators.required])
        });
    }

    private _setupObservables() {
        this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(value => {
            if (this._onChange) {
                this._onChange(value);
            }
        });
    }
}
