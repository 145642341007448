<div class="aix-dropdown aix-blink">
    <button
        class="aix-dropdown__btn aix-btn aix-btn--dropdown"
        type="button"
        tabindex="-1"
        [ngClass]="{ open: isDropdownOpen }"
        (click)="toggleOpenDropdown()"
        [disabled]="isDisabled"
    >
        <span [ngClass]="customButton()"> </span>
    </button>

    <div class="aix-dropdown__control" [class.aix-dropdown__control-standalone]="standalone()">
        @if (multiple()) {
        <div class="aix-dropdown__chips">
            @for (item of _selection; track item) {
            <span
                class="aix-pill dropdown"
                [class.aix-dropdown__chips-btn__full]="!toggleBehaviour()"
            >
                <span class="u-text-left">{{ calculateLabel(item) }}</span>
                <span class="fa-regular fa-xmark u-ml8" (click)="removeChip(item)"></span>
            </span>
            }
            <input
                #searchFieldRef
                type="text"
                tabindex="0"
                autocomplete="new-password"
                class="aix-dropdown__form-control"
                data-testing="dropdown-input-multiple"
                [class.aix-dropdown__form-control-standalone]="standalone()"
                [value]="searchFieldValueLabel"
                [placeholder]="(_selection?.length > 0 || getSearchFieldValue()) ? '' : placeholder()"
                (click)="openDropdown()"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
                (focus)="onFocus($event)"
                (blur)="onBlur($event)"
                [disabled]="isDisabled"
            />
        </div>
        } @if (!multiple()) {
        <div class="aix-dropdown__input-container">
            <input
                #searchFieldRef
                type="text"
                tabindex="0"
                autocomplete="new-password"
                class="aix-dropdown__form-control"
                data-testing="dropdown-input"
                [class.aix-dropdown__form-control-standalone]="standalone()"
                [class.aix-disabled]="isDisabled"
                [value]="searchFieldValueLabel"
                [placeholder]="placeholder()"
                (click)="openDropdown()"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
                (focus)="onFocus($event)"
                (blur)="onBlur($event)"
                [disabled]="isDisabled"
            />
        </div>
        }
    </div>
    <div class="aix-dropdown__list-container">
        <ul
            #dropDownListRef
            class="aix-dropdown__list"
            data-testing="dropdown-list"
            [class.has-scroll]="hasScroll"
            (mousedown)="onMouseDownList()"
            (mouseup)="onMouseLeaveList()"
            (mouseout)="onMouseLeaveList()"
            (mouseleave)="onMouseLeaveList()"
            [hidden]="!isDropdownOpen"
        >
            @for (option of _getFilteredOptions(); track option) {
            <li
                class="aix-dropdown__item"
                data-testing="dropdown-item"
                [ngClass]="{ selected: option.selected, disabled: disableStrategy()(option.option) }"
                (click)="onClickOption($event, option)"
            >
                <ng-container
                    aixDropdownCellrenderer
                    [cellRenderer]="cellRenderer()"
                    [option]="option"
                >
                </ng-container>
            </li>
            } @if (itemMessage()) {
            <li
                class="aix-dropdown__item"
                data-testing="dropdown-item-message"
                [innerHTML]="itemMessage()"
            ></li>
            }
        </ul>
        @if (actionButtonLabel()) {
        <div
            class="aix-dropdown__item--action"
            [hidden]="!isDropdownOpen"
            [style.margin-top.px]="dropDownListRef.offsetHeight - 1"
        >
            <aix-button
                data-testing="dropdown-add-button"
                class="u-text-left u-full-width u-pt16 u-pb16 u-pr20 u-pl20"
                (click)="onClickActionButton()"
                [icon]="'fa-plus'"
                [buttonType]="linkButtonType"
                [buttonLabel]="actionButtonLabel()"
            >
            </aix-button>
        </div>
        }
    </div>
</div>
