<ng-template #signerStatus let-investors>
    @if (isLoading$ | async) {
    <aix-loading [relative]="true"></aix-loading>
    } @for (recipient of investors;track recipient) {
    <aix-signer [recipient]="recipient" [showSending]="true" (onMakeChanges)="onMakeChanges.emit()">
    </aix-signer>
    }
</ng-template>

<div class="aix-form aix-form--centered">
    <aix-header-section [sectionClasses]="'u-mt24'" [title]="'eSignatures'">
        <aix-button
            (click)="cancelSignatures()"
            [isDisabled]="isReadOnly || signaturesCompleted"
            [buttonType]="cancelEsignButtonType"
            [buttonLabel]="'Cancel eSign'"
            data-testing="cancel-e-sign"
        >
        </aix-button>
        @if (showResend) {
        <aix-button
            (click)="onClickResend()"
            [isDisabled]="isReadOnly"
            [buttonType]="resendEsignButtonType"
            [buttonLabel]="'Resend eSign'"
            data-testing="resend-e-sign"
            class="u-mr0"
        >
        </aix-button>
        } @if (showResend) {
        <span
            aixTooltip
            class="br-link"
            [aixTooltipContent]="'The eSignature email will be resent to the current signer. Previously collected signatures will not be impacted by resending.'"
        ></span>
        }
    </aix-header-section>

    @if (showSigningNotification) {
    <aix-notification
        [status]="notification.status"
        [isOpen]="true"
        [canClose]="true"
        (onCloseNotification)="showSigningNotification = false"
        data-testing="signing-status-notification"
        class="u-mb16"
    >
        <p>
            {{ notification.text }} @if (notification.extras) { @for (extra of
            notification.extras;track extra) {
            <span>
                @if (extra.type === 'text') {
                <span>{{ extra.text }}</span>
                } @if (extra.type === 'link') {
                <span (click)="store.dispatch(extra.action!)" class="br-link selectable"
                    >{{ extra.text }}</span
                >
                }
            </span>
            } }
        </p>
    </aix-notification>
    } @if (showResendNotification) {
    <aix-notification
        [status]="'ok'"
        [isOpen]="true"
        [canClose]="true"
        data-testing="resend-notification"
        class="u-block u-mb16"
    >
        <div>
            <h5>Success! Resent eSign.</h5>
            <p>If the email cannot be found, please check spam or whitelist {{ docusignEmail }}</p>
        </div>
    </aix-notification>
    }

    <div>
        <aix-error-box [states]="states"></aix-error-box>
        @if (signers.length === 0 || (signers | aixSignerFilter:recipientType.investor)?.length) {
        <div>
            <h5>Investors</h5>
        </div>
        } @if (signers.length === 0) {
        <div class="investor__loading" data-testing="investor-loading">
            <span>Emails have been sent to the investors.</span>
        </div>
        }

        <ng-template
            *ngTemplateOutlet="signerStatus; context: {$implicit: (signers | aixSignerFilter:recipientType.investor)}"
        ></ng-template>

        @if ((signers | aixSignerFilter:recipientType.additional).length > 0) {
        <div>
            <div class="u-mt16">
                <h5>Additional Signers</h5>
            </div>

            <ng-template
                *ngTemplateOutlet="signerStatus; context: {$implicit: (signers | aixSignerFilter:recipientType.additional)}"
            ></ng-template>
        </div>
        } @if ((signers | aixSignerFilter:recipientType.advisor).length > 0) {
        <div>
            <div class="u-mt16">
                <h5>Advisor</h5>
            </div>

            <ng-template
                *ngTemplateOutlet="signerStatus; context: {$implicit: (signers | aixSignerFilter:recipientType.advisor)}"
            ></ng-template>
        </div>
        } @if ((signers | aixSignerFilter:recipientType.postAdvisor).length > 0) {
        <div>
            <div class="u-mt16">
                <h5>Additional Signers</h5>
            </div>

            <ng-template
                *ngTemplateOutlet="signerStatus; context: {$implicit: (signers | aixSignerFilter:recipientType.postAdvisor)}"
            ></ng-template>
        </div>
        }
    </div>
</div>

<aix-modal
    #cancelSignaturesConfirmModal
    modalType="confirmation"
    [modalTitle]="'Cancel eSign?'"
    (optionSelected)="cancelSignaturesConfirmModalSelected($event)"
    [buttonDisableFunction]="acceptCancelESignConfirmed"
    (onCloseModal)="onCloseCancelESignModal()"
>
    <ng-template>
        <p class="u-fw500 u-mb24">
            Signatures will be voided and the order will return to a draft state. This action can
            not be undone.
        </p>
        <aix-checkbox [bodyClassNames]="" (valueChanges)="cancelESing($event)" [isRequired]="true"
            >I agree to void signatures
        </aix-checkbox>
    </ng-template>
</aix-modal>
