import {
    ChangeDetectionStrategy,
    Component,
    Output,
    EventEmitter,
    ViewChild,
    ChangeDetectorRef,
    OnInit,
    OnDestroy
} from '@angular/core';
import { AixModalComponent } from '@trade-platform/ui-components';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {
    addMilliseconds,
    differenceInMilliseconds,
    differenceInSeconds,
    formatDistanceStrict
} from 'date-fns';
import {
    ProfileStoreFacade,
    AixAuthService,
    InactivityService,
    KeepAliveService
} from '@advisor-ui/app-services';

@Component({
    selector: 'aix-inactivity-modal',
    templateUrl: './inactivity.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixModalComponent]
})
export class AixInactivityModalComponent implements OnInit, OnDestroy {
    @Output() logout = new EventEmitter<never>();

    @ViewChild('inactivityModal', { static: true }) inactivityModal: AixModalComponent;

    subscriptions: Subscription[] = [];

    timer: any;
    timeoutDate: Date;
    inactivityTimeout: number;
    isModalOpen = false;

    static INACTIVITY_WARNING_TIME = 60000;

    constructor(
        private cd: ChangeDetectorRef,
        private keepAliveService: KeepAliveService,
        public storeFacade: ProfileStoreFacade,
        private authService: AixAuthService,
        private inactivityService: InactivityService
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.inactivityService.startInactivityTimer$.subscribe(event => {
                this.inactivityTimeout = event.inactivityTimeout;
                this.timeoutDate = addMilliseconds(new Date(), this.inactivityTimeout);
                if (this.inactivityTimeout > AixInactivityModalComponent.INACTIVITY_WARNING_TIME) {
                    this.inactivityModal.closeModal();
                }

                this.startTimer();
            })
        );
    }

    startTimer() {
        if (this.timer) {
            this.stopTimer();
        }

        this.timer = setInterval(() => {
            const msDifference = differenceInMilliseconds(this.timeoutDate, new Date());
            if (msDifference <= 0) {
                this.stopTimer();
                this.forceLogout();
            } else if (msDifference <= AixInactivityModalComponent.INACTIVITY_WARNING_TIME) {
                if (!this.isModalOpen) {
                    this.showInactivityModal();
                }
            }
            this.cd.detectChanges();
        }, 1000);
    }

    stopTimer() {
        clearInterval(this.timer);
    }

    getModalTextLineOne(): string {
        const loggedOutTimer = Math.max(differenceInSeconds(this.timeoutDate, new Date()), 0);
        return `For your security, you will be logged out in ${loggedOutTimer} seconds.`;
    }

    getModalTextLineTwo(): string {
        const inactiveTime = formatDistanceStrict(
            new Date(),
            addMilliseconds(
                new Date(),
                this.inactivityTimeout - AixInactivityModalComponent.INACTIVITY_WARNING_TIME
            ),
            { roundingMethod: 'round' }
        );
        return `You have been inactive for ${inactiveTime}.`;
    }

    onInactivityModalOptionSelected(e: any) {
        this.stopTimer();
        switch (e) {
            case 'Yes, continue':
                this.keepAliveService.keepAlive().pipe(first()).subscribe();
                break;
            case 'Cancel':
                this.forceLogout();
                break;
        }
    }

    showInactivityModal() {
        this.inactivityService.showInactivityModal();
        this.inactivityModal.openModal();
    }

    forceLogout() {
        this.authService.forceLogout = true;
        this.logout.emit();
    }

    ngOnDestroy() {
        this.stopTimer();
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
