import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy
} from '@angular/core';
import { combineLatest, map, Observable, Subscription } from 'rxjs';
import {
    FormArray,
    FormControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import {
    emailValidator,
    ENVIRONMENT,
    getFromStorage,
    IEnvironment
} from '@trade-platform/ui-utils';
import {
    AixEffectActions,
    AixSharedLibModule,
    AppState,
    ErrorWrapper,
    OneOfModel,
    PingRegisterFailureAction,
    RegisterToken,
    RegisterType,
    SendRegisterAction,
    SendRegisterFailureAction,
    SendRegisterSuccessAction
} from '@trade-platform/ui-shared';
import { select, Store } from '@ngrx/store';
import { isInProgress, RemoteData, RemoteDataModule } from 'ngx-remotedata';
import { AixAuthService } from '@advisor-ui/app-services';
import { AdditionalAdvisorInformationComponent } from './additional-advisor-information/additional-advisor-information';

import { NgIf, NgFor } from '@angular/common';
import { AixUiComponentsModule } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        AixUiComponentsModule,
        AixSharedLibModule,
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        AdditionalAdvisorInformationComponent,
        RemoteDataModule
    ]
})
export class AixRegisterComponent implements OnDestroy {
    hideAccountType = false;
    formSent = false;
    duplicateSubmission = false;
    form: UntypedFormGroup;

    accountType: string;
    accountTypes: { value: string; title: string }[] = [
        {
            value: 'personal',
            title: 'Personal / Individual Investor'
        },
        {
            value: 'financial',
            title: 'Financial Professional'
        }
    ];

    role: string;
    roles: OneOfModel[] = [
        { id: 'advisor', name: 'Registered representative (RR) or investment advisor (IA)' },
        { id: 'assistant', name: 'Assistant / associate supporting one or more advisors' },
        { id: 'both', name: 'Both an advisor and assistant / associate' }
    ];

    registerInfo: RegisterToken;
    logo: string;

    isLoading$: Observable<boolean>;
    states: Observable<RemoteData<any, ErrorWrapper>>[];
    subscriptions: Subscription[] = [];

    seiProducts: { id: string; name: string }[] = [];

    constructor(
        private _fb: UntypedFormBuilder,
        private cd: ChangeDetectorRef,
        private store: Store<AppState>,
        private actions$: AixEffectActions,
        private authService: AixAuthService,
        @Inject(ENVIRONMENT) private readonly environment: IEnvironment
    ) {
        this.states = [
            store.pipe(select(state => state.registerToken)),
            store.pipe(select(state => state.register))
        ];
        this.isLoading$ = combineLatest([this.states]).pipe(
            map(states => states.some(isInProgress))
        );

        this.registerInfo = getFromStorage('register') as RegisterToken;

        if (this.registerInfo) {
            this.applyBrand();

            if (this.registerInfo.type === 'sei') {
                // create form
                this.form = this._fb.group({
                    accountType: this._fb.control(this.registerInfo.type, [Validators.required]),
                    email: this._fb.control(this.registerInfo.email, [
                        Validators.required,
                        emailValidator()
                    ]),
                    firstName: this._fb.control(null, [Validators.required]),
                    lastName: this._fb.control(null, [Validators.required]),
                    firmName: this._fb.control(null, [Validators.required]),
                    additionalInformation: this._fb.control(null),
                    collectiveTrustNames: this._fb.control(null, [Validators.required])
                });

                // get data
                const seiData = `https://s3.us-east-2.amazonaws.com/${
                    this.environment.connectionsBucket
                }/${this.registerInfo.tenant}.json?cacheBuster=${new Date().getTime()}`;
                fetch(seiData)
                    .then(response => response.json())
                    .then(data => {
                        const products = data.products.map((product: any) => {
                            return { id: product, name: product };
                        });
                        this.seiProducts = products;
                    });
            } else {
                this.form = this._fb.group({
                    accountType: this._fb.control(this.registerInfo.type, [Validators.required]),
                    email: this._fb.control(this.registerInfo.email, [
                        Validators.required,
                        emailValidator()
                    ]),
                    firstName: this._fb.control(null, [Validators.required]),
                    lastName: this._fb.control(null, [Validators.required]),
                    firmName: this._fb.control(null),
                    firmCRDNumber: this._fb.control(null),
                    role: this._fb.control(null),
                    crdNumber: this._fb.control(null),
                    investmentDetails: this._fb.control(null, [Validators.required]),
                    additionalAdvisors: this._fb.array([])
                });

                if (
                    (this.registerInfo.type as RegisterType) === 'personal' ||
                    (this.registerInfo.type as RegisterType) === 'financial'
                ) {
                    this.hideAccountType = true;
                    this.onClickAccountType(this.registerInfo.type as string);
                }
            }

            this.subscriptions.push(
                this.actions$
                    .ofClass<SendRegisterSuccessAction>(SendRegisterSuccessAction)
                    .subscribe(action => {
                        this.formSent = true;
                        this.cd.detectChanges();
                    }),
                this.actions$
                    .ofClass<SendRegisterFailureAction>(SendRegisterFailureAction)
                    .subscribe(action => {
                        this.cd.detectChanges();
                    }),
                this.actions$
                    .ofClass<PingRegisterFailureAction>(PingRegisterFailureAction)
                    .subscribe(action => {
                        // It's a duplicate submission, hide error and display OK message
                        if (action.payload.error.options.status === 409) {
                            this.formSent = true;
                            this.duplicateSubmission = true;
                        }
                        this.cd.detectChanges();
                    })
            );
        } else {
            this.authService.redirectOnLogin();
        }
    }

    get _additionalAdvisors(): UntypedFormArray {
        return this.form.get('additionalAdvisors') as UntypedFormArray;
    }

    addAdditionalAdvisor() {
        (this.form.get('additionalAdvisors') as FormArray).push(
            this._fb.control({
                fullName: null,
                crdNumber: null,
                email: null
            })
        );
    }

    removeAdditionalAdvisor(index: number) {
        (this.form.get('additionalAdvisors') as FormArray).removeAt(index);
    }

    applyBrand() {
        const timestamp = new Date().getTime();
        this.logo = `https://s3.us-east-2.amazonaws.com/${this.environment.brandingBucket}/${this.registerInfo.tenant}/logo?updated=${timestamp}`;
        const head = document.getElementsByTagName('body')[0];

        // First add new styling link;
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = `https://s3.us-east-2.amazonaws.com/${this.environment.brandingBucket}/${this.registerInfo.tenant}/css?updated=${timestamp}`;
        link.media = 'all';
        head.appendChild(link);
    }

    onClickRole(role: OneOfModel[]) {
        this.role = role[0].id;

        if (this.role === 'assistant') {
            (this.form.get('crdNumber') as FormControl).clearValidators();
        } else if (this.role !== 'assistant') {
            (this.form.get('crdNumber') as FormControl).setValidators([Validators.required]);
        }
        this.form.get('crdNumber')?.updateValueAndValidity();

        const additionalAdvisors = this.form.get('additionalAdvisors') as FormArray;
        additionalAdvisors.clear();

        if (this.role === 'assistant' || this.role === 'both') {
            additionalAdvisors.push(
                this._fb.control({
                    fullName: null,
                    crdNumber: null,
                    email: null
                })
            );
        }

        additionalAdvisors.updateValueAndValidity();

        this.form.updateValueAndValidity();
        this.cd.detectChanges();
    }

    onClickAccountType(account: string) {
        this.accountType = account;

        if (this.accountType === 'personal') {
            (this.form.get('firmName') as FormControl).clearValidators();
            (this.form.get('firmCRDNumber') as FormControl).clearValidators();
            (this.form.get('role') as FormControl).clearValidators();
        } else if (this.accountType === 'financial') {
            (this.form.get('firmName') as FormControl).setValidators([Validators.required]);
            (this.form.get('firmCRDNumber') as FormControl).setValidators([Validators.required]);
            (this.form.get('role') as FormControl).setValidators([Validators.required]);
        }

        this.form.get('firmName')?.updateValueAndValidity();
        this.form.get('firmCRDNumber')?.updateValueAndValidity();
        this.form.get('role')?.updateValueAndValidity();
        this.form.updateValueAndValidity();
    }

    onClickSubmit() {
        const body: any = { userData: {} };
        const accountType =
            this.accountTypes.find(accountType => {
                return accountType.value === this.form.value.accountType;
            })?.title || this.form.value.accountType;

        if (this.registerInfo.type === 'sei') {
            // TODO: check body is valid
            body.userData = {
                emailAddress: this.form.value.email,
                firstName: this.form.value.firstName,
                lastName: this.form.value.lastName,
                firmName: this.form.value.firmName,
                collectiveInvestmentTrustDetails: this.form.value.collectiveTrustNames
                    .map((trust: { id: string; name: string }) => {
                        return trust.name;
                    })
                    .join(', '),
                additionalInformation: this.form.value.additionalInformation
            };
        }
        // TODO - As this section stabilizes, adjust labels in coordination with email template so that they aren't so oddly specific - TRJ 8/28/23
        else if (this.form.value.accountType === 'personal') {
            body.userData = {
                emailAddress: this.form.value.email,
                firstName: this.form.value.firstName,
                lastName: this.form.value.lastName,
                yourPrimaryRole: accountType,
                investmentDetails: this.form.value.investmentDetails
            };
        } else {
            switch (this.form.value.role[0].id) {
                case 'advisor':
                    body.userData = {
                        emailAddress: this.form.value.email,
                        firstName: this.form.value.firstName,
                        lastName: this.form.value.lastName,
                        firmName: this.form.value.firmName,
                        firmCRD: this.form.value.firmCRDNumber,
                        yourPrimaryRole: this.form.value.role[0].name,
                        yourCRD: this.form.value.crdNumber,
                        investmentDetails: this.form.value.investmentDetails
                    };
                    break;

                case 'assistant':
                    body.userData = {
                        emailAddress: this.form.value.email,
                        firstName: this.form.value.firstName,
                        lastName: this.form.value.lastName,
                        firmName: this.form.value.firmName,
                        firmCRD: this.form.value.firmCRDNumber,
                        yourPrimaryRole: this.form.value.role[0].name,
                        investmentDetails: this.form.value.investmentDetails,
                        additionalAdvisors: this.form.value.additionalAdvisors.map(
                            (advisor: any) => {
                                return {
                                    advisorName: advisor.fullName,
                                    advisorCRD: advisor.crdNumber,
                                    advisorEmail: advisor.email
                                };
                            }
                        )
                    };
                    break;

                case 'both':
                    body.userData = {
                        emailAddress: this.form.value.email,
                        firstName: this.form.value.firstName,
                        lastName: this.form.value.lastName,
                        firmName: this.form.value.firmName,
                        firmCRD: this.form.value.firmCRDNumber,
                        yourPrimaryRole: this.form.value.role[0].name,
                        yourCRD: this.form.value.crdNumber,
                        investmentDetails: this.form.value.investmentDetails,
                        additionalAdvisors: this.form.value.additionalAdvisors.map(
                            (advisor: any) => {
                                return {
                                    advisorName: advisor.fullName,
                                    advisorCRD: advisor.crdNumber,
                                    advisorEmail: advisor.email
                                };
                            }
                        )
                    };
                    break;
            }
        }

        // Delete null values
        Object.keys(body.userData).forEach(k => {
            if (!body.userData[k]) {
                delete body.userData[k];
            }
        });

        this.store.dispatch(new SendRegisterAction(this.registerInfo.token, body));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
