<aix-templates #aixTemplates (onInit)="aixTemplatesInitialized = true"></aix-templates>

<ng-template #signaturesStepContent>
    <div class="order-step-content">
        @if (filesGenerated && viewState !== 'map-signatures') {
        <aix-pdf-generation
            [order]="order"
            [viewState]="viewState"
            [signaturesCompleted]="signaturesCompleted"
            [wetSignOnly]="wetSignOnly"
            (onMakeChanges)="makeChanges()"
            (onGenerateFiles)="filesGenerated = true"
        >
        </aix-pdf-generation>
        } @if (viewState === 'wet-sign' && order?.status === orderStatus.readyToSend) {
        <div>
            <aix-header-section [title]="'Download & Sign'" data-testing="download-and-sign-title">
                @if (!wetSignOnly) {
                <aix-button
                    data-testing="e-sign-button"
                    [isDisabled]="isReadOnly"
                    (click)="onClickESign()"
                    [buttonType]="linkButtonType"
                    [buttonLabel]="'I\'d Rather eSign'"
                >
                </aix-button>
                }
            </aix-header-section>
        </div>
        } @if (viewState === 'wet-sign' && order?.status === orderStatus.readyToSend) {
        <aix-form-upload #orderFormUpload [isReadOnly]="isReadOnly"></aix-form-upload>
        } @if (viewState === 'preview' && order?.status === orderStatus.readyToSend) {
        <aix-delivery
            [order]="order"
            [isReadOnly]="isReadOnly"
            [wetSignOnly]="wetSignOnly"
            (onMakeChanges)="makeChanges()"
            (clickContinue)="onSelectSigningMethod($event)"
        >
        </aix-delivery>
        } @if (viewState === 'map-signatures' && order?.status === orderStatus.readyToSend) {
        <aix-map-signatures
            (submitForm)="onSubmitMapSignatures()"
            (cancelMapSignatures)="clearSigningMethod()"
        >
        </aix-map-signatures>
        } @if (viewState === 'docu-sign' && order?.status === orderStatus.readyToSend) {
        <aix-docu-sign
            [isReadOnly]="isReadOnly"
            [signers]="order?.eSign?.envelope?.recipients || []"
            [states]="docuSignError"
            (cancelESign)="clearSigningMethod()"
            (submitForm)="onSubmitDocuSign($event)"
        >
        </aix-docu-sign>
        } @if (viewState === 'docu-sign' && order?.status !== orderStatus.readyToSend) {
        <aix-signatures
            [isReadOnly]="isReadOnly"
            (onSignaturesCompleted)="this.setSignaturesCompleted()"
            (onMakeChanges)="makeChanges()"
        >
        </aix-signatures>
        } @if (viewState === 'docu-sign' && order?.status !== orderStatus.readyToSend &&
        eSignWithWetSignEnabled) {
        <div class="u-mt24">
            <h5>{{ order?.eSign?.wetSignRoles }}</h5>

            @if (!signaturesCompleted) {
            <p class="u-mt16 u-mb16">
                After eSignatures have been completed, you will be prompted to collect {{
                order?.eSign?.wetSignRoles }} signatures.
            </p>
            } @if (signaturesCompleted && !eSignWithWetSignCompleted) {
            <p class="u-mt16 u-mb16">
                Download the forms that require {{ order?.eSign?.wetSignRoles }} signature and
                re-upload once signatures are complete.
            </p>
            } @if (signaturesCompleted && eSignWithWetSignCompleted) {
            <p class="u-mt16 u-mb16">All forms have been signed. Please continue.</p>
            } @if (signaturesCompleted) {
            <aix-form-upload #orderFormUploadPrincipal [isReadOnly]="isReadOnly"></aix-form-upload>
            }
        </div>
        } @if (viewState === 'wet-sign' || (viewState === 'docu-sign' && order?.status !== orderStatus.readyToSend)) {
        <div class="u-mt40">
            <aix-order-details-upload-documents
                [isReadOnly]="isReadOnly"
                [sectionClasses]="'-no-padding'"
            />
        </div>
        } @if (viewState === 'docu-sign' && order?.status !== orderStatus.readyToSend) {
        <aix-e-sign
            [isReadOnly]="isReadOnly"
            [signaturesCompleted]="signaturesCompleted && eSignWithWetSignCompleted"
            (onClickContinue)="onClickContinue()"
        ></aix-e-sign>
        } @if (viewState === 'wet-sign' && order?.status === orderStatus.readyToSend) {
        <aix-wet-sign [isReadOnly]="isReadOnly" (onClickContinue)="onClickContinue()">
        </aix-wet-sign>
        }
    </div>
</ng-template>

@if (aixTemplatesInitialized) {

<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(1, 4)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(2, 4)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(3, 4)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(4, 4)"
    [bodyTemplate]="signaturesStepContent"
    [isClosed]="false"
    [isDisabled]="false"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(5, 4)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>

}
