import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { DetailViewState } from '../overview';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixHeaderSectionComponent,
    AixNotificationComponent,
    AixRadioGroupComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { BaseOrder, eSignEnvelopeRecipient, OrderDeliveryType } from '@trade-platform/ui-shared';
import { idCheckType, recipientType } from '@trade-platform/lib-enums';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../../base.orders.store.facade';

@Component({
    selector: 'aix-delivery',
    templateUrl: './delivery.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixHeaderSectionComponent,
        AixDataTestingDirective,
        AixNotificationComponent,
        FormsModule,
        ReactiveFormsModule,
        AixRadioGroupComponent,
        AixButtonComponent
    ]
})
export class AixOrderProcessDeliveryComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('notification', { static: true }) notification: AixNotificationComponent;
    @Input() order: BaseOrder;
    @Input() isReadOnly = false;
    @Input() wetSignOnly = false;
    @Output() onMakeChanges: EventEmitter<void> = new EventEmitter();
    @Output() clickContinue = new EventEmitter<DetailViewState>();

    deliveryForm: UntypedFormGroup;
    deliveryMethod: OrderDeliveryType;
    deliveryMethods = [
        {
            value: 'docu-sign' as OrderDeliveryType,
            title: 'Send for eSign',
            description: 'Send documents for eSignature.',
            disabled: false
        },
        {
            value: 'wet-sign' as OrderDeliveryType,
            title: 'Download & Sign',
            description: 'Download and sign forms.',
            disabled: false
        }
    ];

    continueButtonType = BUTTON_TYPE.primary;

    subscriptions: Subscription[] = [];

    constructor(
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        private ref: ChangeDetectorRef,
        private _fb: UntypedFormBuilder
    ) {
        this.deliveryForm = this._fb.group({
            deliveryMethod: this._fb.control({}, Validators.required)
        });
    }

    ngOnInit() {
        if (this.wetSignOnly) {
            this.deliveryForm.setValue({ deliveryMethod: 'wet-sign' });
            this.deliveryMethod = 'wet-sign';
            this.ref.detectChanges();
        } else {
            this.deliveryForm.setValue({ deliveryMethod: 'docu-sign' });
            this.deliveryMethod = 'docu-sign';
            this.ref.detectChanges();
        }

        this.subscriptions.push(
            this.deliveryForm.valueChanges.subscribe(() => {
                this.deliveryMethod = this.deliveryForm.value.deliveryMethod;
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.order && changes.order.currentValue) {
            if (
                this.order.eSign &&
                this.order.eSign.envelope &&
                this.order.eSign.envelope.idCheckConfig === idCheckType.sms &&
                this.order.eSign.envelope.recipients &&
                this.order.eSign.envelope.recipients.find(
                    (r: eSignEnvelopeRecipient) =>
                        r.type === recipientType.investor && !r.phoneMobile
                )
            ) {
                this.wetSignOnly = true;
                this.deliveryForm.get('deliveryMethod')?.setValue('wet-sign');
                this.notification.openNotification();
            }

            this.deliveryMethods.forEach(d => {
                d.disabled = d.value === 'docu-sign' && this.order.eSign?.isAvailable === false;
            });

            this.ref.detectChanges();
        }
    }

    onClickContinue() {
        this.clickContinue.emit(this.deliveryMethod);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
