<ng-template
    #defaultHeaderTemplateRef
    let-title="title"
    let-subtitle="subtitle"
    let-dataTesting="dataTesting"
>
    <h6 class="data-overview-title" [data-testing]="dataTesting + '-title'">
        {{ title }} @if (subtitle) {
        <span class="u-italic">{{ subtitle }}</span>
        }
    </h6>
</ng-template>
<ng-template #defaultValueTemplateRef let-value="value" let-dataTesting="dataTesting">
    @if (!value?.join) {
    <p class="data-overview-info" [data-testing]="dataTesting + '-value'">{{ value }}</p>
    } @if (value?.join) {
    <div class="data-overview-info" [data-testing]="dataTesting + '-value'">
        @for (val of value;track val) {
        <p>{{ val }}</p>
        }
    </div>
    }
</ng-template>

<div class="data-overview">
    @for (element of elements();track element) {
    <div
        class="data-overview-element"
        [ngClass]="element.classList || []"
        [data-testing]="'data-overview'"
    >
        <ng-container
            [ngTemplateOutlet]="
                element.headerTemplate || headerTemplate() || defaultHeaderTemplateRef
            "
            [ngTemplateOutletContext]="{
                title: element.title,
                subtitle: element.subtitle,
                dataTesting: 'data-overview-' + (element.title | aixKebabCasePipe)
            }"
        >
        </ng-container>
        @for (value of element.values;track value) {
        <ng-container
            [ngTemplateOutlet]="element.infoTemplate || infoTemplate() || defaultValueTemplateRef"
            [ngTemplateOutletContext]="{
                value: (value | aixCitizenValue),
                dataTesting: 'data-overview-' + (element.title | aixKebabCasePipe)
            }"
        >
        </ng-container>
        }
    </div>
    }
</div>
