<aix-templates #aixTemplates (onInit)="aixTemplatesInitialized = true"></aix-templates>

<ng-template #finalizeStepContent>
    <div class="order-step-content">
        @if (order?.status !== orderStatus.offline) {
        <aix-order-submit-confirmation
            [approvalType]="approvalType"
            [isReadOnly]="isReadOnly"
            [isSubmitted]="isSubmitted()"
            (showPdf)="showPdf()"
            (onDownloadTradePackage)="downloadTradePackage()"
        >
        </aix-order-submit-confirmation>
        } @if (order?.status === orderStatus.offline) {
        <aix-order-offline (showPdf)="showPdf()" (onClickDownload)="downloadTradePackage()">
        </aix-order-offline>
        }
    </div>
</ng-template>

@if (aixTemplatesInitialized) {

<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(1, 5)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(2, 5)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(3, 5)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(4, 5)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(5, 5)"
    [bodyTemplate]="finalizeStepContent"
    [isClosed]="false"
    [isDisabled]="false"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>

}
