<ol class="breadcrumbs">
    @for (breadcrumb of breadcrumbs();track breadcrumb) {
    <li>
        @switch (breadcrumb.type) { @case ('link') {
        <a class="br-link" [routerLink]="[breadcrumb.value.routerLink]">{{
            breadcrumb.value.text
        }}</a>
        } @case ('text') {
        <span>{{ breadcrumb.value.text }}</span>
        } }
    </li>
    }
</ol>
