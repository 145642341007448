<div class="aix-slide-toggle-wrapper">
    <label
        class="aix-switch"
        [class.aix-disabled]="isDisabled()"
        [ngClass]="bodyClassNames()"
        data-testing="toggle-label"
    >
        <input
            #toggle
            id="toggle"
            type="checkbox"
            data-testing="toggle-input"
            [checked]="valueToRender()"
            (change)="valueToSend($event)"
            [class.aix-disabled]="isDisabled()"
            [required]="isRequired()"
            [disabled]="isDisabled()"
            [class.aix-form__control--invalid]="!isValid()"
            [class.aix-form__control--invalid-required]="isRequired() && !toggle.checked"
            [attr.tabindex]="isDisabled() ? '-1' : ''"
        />
        <span
            class="aix-slider round br-primary__border-color br-primary__border-color--before"
            [class.aix-disabled]="isDisabled()"
            [class.br-primary__background-color--before]="valueToRender()"
        ></span>
    </label>
    <label for="toggle" class="label-content"
        >{{ text }}
        <ng-container></ng-container>
    </label>
</div>
