import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { UntypedFormGroup, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { commentType } from '@trade-platform/lib-enums';
import {
    DocumentFile,
    OrderFormComment,
    OrderFormCommentAction,
    OrderFormCommentActionType
} from '@trade-platform/ui-shared';
import { Subscription } from 'rxjs';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixNotificationComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { DOCUSIGN_FILETYPE } from '../../process/overview/utils/order-utils';
import { getFilesToDisplay } from '../../order-document-viewer/document-viewer/document-viewer.helper';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../base.orders.store.facade';
import { AixOrderFormResolveCommentComponent } from '../order-form-resolve-comment/order-form-resolve-comment';
import { AixOrderFormCommentComponent } from '../order-form-comment/order-form-comment';
import { AixOrderFormCommentTagsComponent } from '../order-form-comment-tags/order-form-comment-tags';
import { NgIf } from '@angular/common';
import { AixOrderFormCommentsListComponent } from '../order-form-comments-list/order-form-comments-list';

export type OrderFormCommentsMode = 'modify' | 'resolve';

@Component({
    selector: 'aix-order-form-comments',
    templateUrl: 'order-form-comments.html',
    styleUrls: ['order-form-comments.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        AixDataTestingDirective,
        AixOrderFormCommentTagsComponent,
        AixButtonComponent,
        NgIf,
        AixNotificationComponent,
        AixOrderFormCommentsListComponent,
        AixOrderFormCommentComponent,
        AixOrderFormResolveCommentComponent
    ]
})
export class AixOrderFormCommentsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() canAddComments = true;
    @Input() canReply = false;
    @Input() orderId?: string | null = null;
    @Input() selectedFormId?: string;
    @Input() currentDocument: DocumentFile;
    @Input() documentName?: string;
    @Input() mode: OrderFormCommentsMode = 'modify';
    @Input() parentForm: UntypedFormGroup | null = null;
    @Input() comments: OrderFormComment[];
    activeComments: OrderFormComment[] = [];

    commentText = '';
    commentTag: string | null = null;

    isDocusignCertificate = false;

    subscriptions: Subscription[] = [];

    addButtonType = BUTTON_TYPE.primary;

    constructor(
        @Inject(ORDERS_STORE_FACADE) public store: BaseOrdersStoreFacade,
        private ref: ChangeDetectorRef,
        private router: Router
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.store.orderFormCommentCreateSuccess$.subscribe(() => {
                this.commentText = '';
                this.commentTag = null;
                this.loadComments();
                this.ref.detectChanges();
            }),
            this.store.orderFormCommentsRemoteData$.subscribe(() => {
                this.ref.detectChanges();
            })
        );

        this.store.actions.documentViewerHideNotification.dispatch();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.orderId &&
            changes.orderId.previousValue === null &&
            changes.orderId.currentValue
        ) {
            this.doCommentAction('list');
        }

        if (changes.currentDocument && changes.currentDocument.currentValue) {
            this.isDocusignCertificate = this.isDocusignCertificateActive();
        }
    }

    loadComments() {
        if (this.orderId) {
            this.doCommentAction('list');
        }
    }

    getDocumentId() {
        return this.currentDocument?.formId || this.currentDocument?.id;
    }

    openOrderForm(formId: string) {
        const order = this.store.order;
        const documentViewer = this.store.documentViewer;
        const filesToDisplay = getFilesToDisplay(order);

        for (let i = 0; i < filesToDisplay.length; i++) {
            const file = filesToDisplay[i];

            if ((file.formId && file.formId === formId) || file.id === formId) {
                // Activate this form, if it's not activated already;
                const document = documentViewer.filesToDisplay.find(
                    file => file.formId === formId || file.id === formId
                );
                if (document) {
                    this.router.navigate(
                        this.store.routes.documentViewer(order.id, document.id as string)
                    );
                }
                return;
            }
        }
    }

    onCommentAction(action: OrderFormCommentAction) {
        this.doCommentAction(action.type, action.comment);
    }

    doCommentAction(action: OrderFormCommentActionType, comment: OrderFormComment | null = null) {
        switch (action) {
            case 'list':
                this.store.actions.getFormComments.dispatch({
                    data: {
                        orderId: this.orderId as string,
                        documentId: this.getDocumentId()
                    }
                });
                break;
            case 'create':
                this.store.actions.createFormComment.dispatch({
                    parentId: null,
                    commentType: commentType.userGenerated,
                    commentTag: this.commentTag as string,
                    text: this.commentText.trim(),
                    documentName: this.documentName,
                    data: {
                        orderId: this.orderId as string,
                        documentId: this.getDocumentId()
                    }
                });
                break;
            case 'save':
                this.store.actions.updateFormComment.dispatch({
                    text: (comment as OrderFormComment).text,
                    commentTag: (comment as OrderFormComment).commentTag as string,
                    data: {
                        orderId: (comment as OrderFormComment).orderId,
                        documentId: (comment as OrderFormComment).documentId,
                        id: (comment as OrderFormComment).id
                    }
                });
                break;
            case 'delete':
                this.store.actions.deleteFormComment.dispatch({
                    orderId: (comment as OrderFormComment).orderId,
                    documentId: (comment as OrderFormComment).documentId,
                    id: (comment as OrderFormComment).id
                });
                break;
            case 'resolve':
                this.store.actions.resolveFormComment.dispatch({
                    isResolved: true,
                    data: {
                        id: (comment as OrderFormComment).id
                    }
                });
                break;
            case 'unresolve':
                this.store.actions.resolveFormComment.dispatch({
                    isResolved: false,
                    data: {
                        id: (comment as OrderFormComment).id
                    }
                });
                break;
            case 'link':
                this.openOrderForm((comment as OrderFormComment).documentId as string);
        }
        this.store.actions.documentViewerHideNotification.dispatch();
    }

    isDocusignCertificateActive(): boolean {
        return this.currentDocument?.supplementalFileType?.name === DOCUSIGN_FILETYPE;
    }

    commentTagChange(commentTag: string) {
        this.commentTag = commentTag;
        if (commentTag === 'change') {
            // run action bar
            this.store.actions.documentViewerShowNotification.dispatch({
                notification: {
                    text: 'Selecting “Needs Changes” may require new signatures to be collected for this order.',
                    status: 'alert'
                }
            });
        } else {
            this.store.actions.documentViewerHideNotification.dispatch();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.store.actions.documentViewerHideNotification.dispatch();
    }
}
