import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiFormError, ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { orderFiltersDecoder, orderIdDecoder } from '../../../decoders/order/decoder';
import { DocumentFile, OrderId, OrdersStatusHistory } from '../../../models/order/model';
import { maintenanceOrderDecoder } from '../../../decoders/order/maintenance/decoder';
import { MaintenanceOrder } from '../../../models/order/maintenance/model';

@Injectable()
export class MaintenanceOrderService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    getMaintenanceOrder(id: string): Observable<MaintenanceOrder> {
        return this.http
            .get<MaintenanceOrder>(`${this.environment.api}maintenance/${id}`)
            .pipe(concatMap(p => fromPromise(maintenanceOrderDecoder.decodeToPromise(p))));
    }

    pollMaintenanceOrder(id: string): Observable<MaintenanceOrder> {
        return this.getMaintenanceOrder(id);
    }

    getMaintenanceOrdersStatusHistory(orderId: string): Observable<OrdersStatusHistory[]> {
        return this.http
            .get<OrdersStatusHistory[]>(
                `${this.environment.api}maintenance/${orderId}/statusHistory`
            )
            .pipe(concatMap(p => fromPromise(orderFiltersDecoder.decodeToPromise(p))));
    }

    updateMaintenanceOrder(
        maintenanceOrder: MaintenanceOrder,
        formCompleted = false
    ): Observable<OrderId> {
        const payload: any = { ...maintenanceOrder };
        delete payload.id;
        const http = this.http.patch<OrderId>(
            `${this.environment.api}maintenance/${maintenanceOrder.id}?formCompleted=${formCompleted}`,
            payload
        );

        http.pipe(
            concatMap(o => fromPromise(orderIdDecoder.decodeToPromise(o))),
            map(o => {
                if (o.validation && !o.validation.orderPassed) {
                    // Force error because server validation errors are 200 OK
                    throw new ApiFormError('', o.validation.failures, '');
                }
                return o;
            })
        );

        return http;
    }

    updateMaintenanceOrderFiles(files: DocumentFile[], orderId: string): Observable<OrderId> {
        const filesToUpdate = files.map(file => {
            if (file.formId) {
                return {
                    id: file.id,
                    formId: file.formId,
                    name: file.name,
                    firmId: file.firmId,
                    fundId: file.fundId,
                    holdingOptionId: file.holdingOptionId
                };
            }
            return {
                id: file.id,
                contextMap: file.contextMap,
                supplementalFileTypeId: file.supplementalFileTypeId
            };
        });
        const http = this.http.put<OrderId>(
            `${this.environment.api}maintenance/${orderId}/files`,
            filesToUpdate
        );

        http.pipe(
            concatMap(o => fromPromise(orderIdDecoder.decodeToPromise(o))),
            map(o => {
                if (o.validation && !o.validation.orderPassed) {
                    // Force error because server validation errors are 200 OK
                    throw new ApiFormError('', o.validation.failures, '');
                }
                return o;
            })
        );

        return http;
    }
}
