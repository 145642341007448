<form class="u-mt40" [formGroup]="form">
    <div class="header--section">
        <div class="-heading">
            <h3>Choose a Product</h3>
        </div>
    </div>
    @if (activeInvestorError) {
    <aix-notification
        [isOpen]="true"
        [canClose]="false"
        status="error"
        [classList]="['u-mt16 u-mb16']"
    >
        <p>
            Your profile is not currently setup to initiate an order. Please contact
            <a href="mailto:support@aixplatform.com">support&#64;aixplatform.com</a> for assistance.
        </p>
    </aix-notification>
    }

    <div class="aix-form__container">
        <div class="aix-flex-grid__col">
            <div class="aix-form__group aix-flex-grid__col">
                <input
                    type="text"
                    autocomplete="new-password"
                    class="aix-form__control aix-disabled"
                    name="productLabel"
                    data-testing="productLabel"
                    [formControlName]="'productLabel'"
                    required
                />
                <label class="aix-form__label aix-label"> Product </label>
            </div>
        </div>

        @if (availableOptions?.length && firstShareClass?.shareclassShortName !== 'N/A') {
        <div class="aix-flex-grid__col u-mt-1">
            <div class="aix-form__group">
                <aix-dropdown
                    #productShareClassRef
                    class="aix-form__control--required"
                    [class.aix-form__control--dirty]="productShareClassRef.isDirty"
                    [class.aix-form__control--invalid-required]="(form.value.shareClassSelected?.length >= 0 ?
                            !form.value.shareClassSelected?.length :
                            !form.value.shareClassSelected)"
                    [class.aix-form__control--invalid]="(form.value.shareClassSelected?.length >= 0 ?
                            !form.value.shareClassSelected?.length :
                            !form.value.shareClassSelected)"
                    [formControlName]="'shareClassSelected'"
                    labelField="shareclassShortName"
                    valueField="id"
                    placeholder=""
                    data-testing="productShareClass"
                    [aixDisabled]="availableOptions.length === 1 && form.get('shareClassSelected')?.value"
                    [options]="availableOptions"
                    (ngModelChange)="onSelectShareClass($event)"
                >
                </aix-dropdown>
                <label class="aix-form__label aix-label">Share Class</label>
            </div>
        </div>
        }
    </div>
</form>
