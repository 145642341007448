import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
    addDays,
    addMonths,
    addYears,
    endOfYear,
    format,
    getDate,
    getMonth,
    isAfter,
    isBefore,
    isEqual,
    isValid,
    startOfDay,
    startOfYear,
    subDays,
    subMonths,
    subYears
} from 'date-fns';

//regex to match validator
const currentDate = () => /currentDate\.?(.+)?/;

const addYear = () => /addYear\((\d+?)\)/;
const subYear = () => /subYear\((\d+?)\)/;

const addDay = () => /addDay\((\d+?)\)/;
const subDay = () => /subDay\((\d+?)\)/;

const addMonth = () => /addMonth\((\d+?)\)/;
const subMonth = () => /subMonth\((\d+?)\)/;

const eOY = () => /endOfYear/;
const sOY = () => /startOfYear/;

const parseDate = () => /parse\(([0-9-]*)\)\.?(.+)?/;

//date format to show in the error
export function getformatDate(date: Date, formatString: string): string {
    let result = '';
    switch (formatString) {
        case 'yyyy':
            result = format(date, 'yyyy');
            break;
        case 'mmyyyy':
            result = format(date, 'MM/yyyy');
            break;
        case 'mmddyyyy':
        default:
            result = format(date, 'MM/dd/yyyy');
    }
    return result;
}

//Modify existing date using validator modifiers
export function modifyDate(dateToValidate: Date, rested: string): Date {
    const addYearRes = addYear().exec(rested);
    const subYearRes = subYear().exec(rested);

    const addDayRes = addDay().exec(rested);
    const subDayRes = subDay().exec(rested);

    const addMonthRes = addMonth().exec(rested);
    const subMonthRes = subMonth().exec(rested);

    const eoy = eOY().exec(rested);
    const soy = sOY().exec(rested);

    if (
        rested !== '' &&
        rested !== undefined &&
        addYearRes === null &&
        subYearRes === null &&
        addDayRes === null &&
        subDayRes === null &&
        addMonthRes === null &&
        subMonthRes === null &&
        eoy === null &&
        soy === null
    ) {
        throw new Error(
            `max-min-date-validator.modifyDate(): The pattern "${rested}" does not match any of the date validation options`
        );
    }

    if (addYearRes !== null) {
        dateToValidate = addYears(dateToValidate, parseInt(addYearRes[1], 10));
    }

    if (subYearRes !== null) {
        dateToValidate = subYears(dateToValidate, parseInt(subYearRes[1], 10));
    }

    if (addDayRes !== null) {
        dateToValidate = addDays(dateToValidate, parseInt(addDayRes[1], 10));
    }

    if (subDayRes !== null) {
        dateToValidate = subDays(dateToValidate, parseInt(subDayRes[1], 10));
    }

    if (addMonthRes !== null) {
        dateToValidate = addMonths(dateToValidate, parseInt(addMonthRes[1], 10));
    }

    if (subMonthRes !== null) {
        dateToValidate = subMonths(dateToValidate, parseInt(subMonthRes[1], 10));
    }

    if (eoy) {
        dateToValidate = endOfYear(dateToValidate);
    }

    if (soy) {
        dateToValidate = startOfYear(dateToValidate);
    }

    return dateToValidate;
}

//Compute date to be validated against
export function generateDate(rest: string): Date | null {
    let dateToValidate: Date;
    const currentDateRes = currentDate().exec(rest);
    const parseDateRes = parseDate().exec(rest);
    if (currentDateRes !== null) {
        dateToValidate = modifyDate(startOfDay(new Date()), currentDateRes[1]);
    } else if (parseDateRes !== null) {
        //date to parse must be ISO formatted
        if (parseDateRes[1] && isValid(new Date(parseDateRes[1]))) {
            dateToValidate = modifyDate(new Date(parseDateRes[1]), parseDateRes[2]);
        } else {
            return null;
        }
    } else {
        return null;
    }
    return dateToValidate;
}

// validation function
export function validateMaxDateFactory(formatString: string, rest: string): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
        if (sOY().test(rest) && eOY().test(rest)) {
            return {
                validateMaxDate: {
                    incorrectFormat: `The date validator can't handle endOfYear and startOfYear at the same time`
                }
            };
        }
        let result = null;
        // yyyy-mm-dd
        const regexp = /(\d{4})\-(\d{1,2})\-(\d{1,2})/;
        if (c.value && regexp.test(c.value)) {
            const dateParts = regexp.exec(c.value);
            const newDate = new Date();
            if (dateParts) {
                let dd: number = parseInt(dateParts[3], 10);
                let mm: number = parseInt(dateParts[2], 10) - 1;

                if (formatString === 'mmyyyy') {
                    dd = getDate(newDate);
                }

                if (formatString === 'yyyy') {
                    dd = getDate(newDate);
                    mm = getMonth(newDate);
                }

                const [_, yyyy] = dateParts;
                const d = new Date(parseInt(yyyy, 10), mm, dd);
                const dateToValidate = generateDate(rest);
                if (!dateToValidate) {
                    return {
                        validateMaxDate: {
                            incorrectFormat: `The date validator doesn't have a proper date format to validate against`
                        }
                    };
                }
                if (isAfter(d, dateToValidate) && !isEqual(d, dateToValidate)) {
                    result = {
                        validateMaxDate: {
                            maxDate: `Please enter a date ${
                                sOY().test(rest) ? '' : 'equal or '
                            }prior to ${getformatDate(dateToValidate, formatString)}`
                        }
                    };
                }
            }
        }

        return result;
    };
}

export function validateMinDateFactory(formatString: string, rest: string): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
        if (sOY().test(rest) && eOY().test(rest)) {
            return {
                validateMinDate: {
                    incorrectFormat: `The date validator can't handle endOfYear and startOfYear at the same time`
                }
            };
        }
        let result = null;
        // yyyy-mm-dd
        const regexp = /(\d{4})\-(\d{1,2})\-(\d{1,2})/;
        if (c.value && regexp.test(c.value)) {
            const dateParts = regexp.exec(c.value);
            const newDate = new Date();
            if (dateParts) {
                let dd: number = parseInt(dateParts[3], 10);
                let mm: number = parseInt(dateParts[2], 10) - 1;

                if (formatString === 'mmyyyy') {
                    dd = getDate(newDate);
                }

                if (formatString === 'yyyy') {
                    dd = getDate(newDate);
                    mm = getMonth(newDate);
                }

                const [_, yyyy] = dateParts;
                const d = new Date(parseInt(yyyy, 10), mm, dd);
                const dateToValidate = generateDate(rest);
                if (!dateToValidate) {
                    return {
                        validateMinDate: {
                            incorrectFormat: `The date validator doesn't have a proper date format to validate against`
                        }
                    };
                }
                if (isBefore(d, dateToValidate) && !isEqual(d, dateToValidate)) {
                    result = {
                        validateMinDate: {
                            minDate: `Please enter a date ${
                                eOY().test(rest) ? '' : 'equal or '
                            }later than ${getformatDate(dateToValidate, formatString)}`
                        }
                    };
                }
            }
        }

        return result;
    };
}

export function validateEqualDateFactory(formatString: string, rest: string): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
        if (sOY().test(rest) && eOY().test(rest)) {
            return {
                validateEqualDate: {
                    incorrectFormat: `The date validator can't handle endOfYear and startOfYear at the same time`
                }
            };
        }
        let result = null;
        // yyyy-mm-dd
        const regexp = /(\d{4})\-(\d{1,2})\-(\d{1,2})/;
        if (c.value && regexp.test(c.value)) {
            const dateParts = regexp.exec(c.value);
            const newDate = new Date();
            if (dateParts) {
                let dd: number = parseInt(dateParts[3], 10);
                let mm: number = parseInt(dateParts[2], 10) - 1;

                if (formatString === 'mmyyyy') {
                    dd = getDate(newDate);
                }

                if (formatString === 'yyyy') {
                    dd = getDate(newDate);
                    mm = getMonth(newDate);
                }

                const [_, yyyy] = dateParts;
                const d = new Date(parseInt(yyyy, 10), mm, dd);
                const dateToValidate = generateDate(rest);
                if (!dateToValidate) {
                    return {
                        validateEqualDate: {
                            incorrectFormat: `The date validator doesn't have a proper date format to validate against`
                        }
                    };
                }
                if (!isEqual(d, dateToValidate)) {
                    result = {
                        validateEqualDate: {
                            minDate: `Please enter a date equal to ${getformatDate(
                                dateToValidate,
                                formatString
                            )}`
                        }
                    };
                }
            }
        }

        return result;
    };
}
