<ul class="aix-viewer__data-list">
    @for (document of documents;track document;let i = $index) {
    <li
        class="aix-viewer__data-list__item"
        data-testing="document-list-item"
        [ngClass]="{ active: currentDocument === document.id }"
    >
        <span
            class="aix-viewer__data-list__item-text br-primary__color"
            data-testing="document-list-item-label"
            (click)="onActivateDocument.emit(document.id)"
        >
            {{ document.label }}
        </span>
    </li>
    }
</ul>
