<div class="u-full-width" [class]="{ 'u-mt16': mode === 'modify' }">
    @if (isLoading$ | async) {
    <aix-loading [relative]="true"> </aix-loading>
    }
    <ul class="u-full-width">
        @for (comment of comments | orderFormCommentsFilter:showSystemUpdates;track comment) {
        <li
            [ngClass]="{ 'read-only': (comment.commentType === commentType.systemGenerated && mode === 'modify') }"
            class="order-form-comments__comment"
            data-testing="comment-item"
        >
            <ng-template
                [ngTemplateOutlet]="commentRef"
                [ngTemplateOutletContext]="{$implicit: comment}"
            ></ng-template>
        </li>
        }
    </ul>
</div>
