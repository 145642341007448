<header [ngStyle]="headerStyle()" class="header--app br-navigation--primary__background-color">
    <div class="-container">
        <div class="-heading">
            <h1 class="u-ellipsis">{{ header() }}</h1>
            <div class="-subtext flex-between-top">
                @if (breadcrumbs().length > 0) {
                <aix-breadcrumbs [breadcrumbs]="breadcrumbs()"></aix-breadcrumbs>
                } @if (subHeader()) {
                <div class="flex-right-bottom">
                    <h5>{{ subHeader() }}</h5>
                </div>
                }
                <ng-content select="[subHeaderExtras]"></ng-content>
            </div>
        </div>
        <ng-content select="[extras]"></ng-content>
    </div>
</header>
