<div
    #field
    class="aix-field aix-colorfield"
    [ngClass]="{
        'has-focus': hasFocus,
        'is-invalid': !colorIsValid,
        'is-dirty': isDirty,
        'is-disabled': fieldDisabled(),
        'is-required': fieldRequired(),
        'is-first-change': isFirstChange
    }"
>
    <div #control class="aix-field__control">
        <div class="aix-colorfield__container flex-left-stretch">
            <div class="flex-fill">
                <label #label class="aix-field__label" [attr.for]="fieldId()">{{
                    fieldLabel()
                }}</label>
                <input
                    #input
                    class="aix-field__input"
                    data-testing="color-input"
                    [id]="fieldId()"
                    [value]="color()"
                    [placeholder]="fieldPlaceholder()"
                    [required]="fieldRequired() ? 'required' : null"
                    [disabled]="fieldDisabled()"
                    (input)="onInput($event)"
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                    pattern="^#[a-f0-9]{3}$|^#[a-f0-9]{6}$"
                />
            </div>
            <div
                #decorator
                class="aix-field__decorator"
                [ngClass]="{ 'is-hidden': isEmpty || !colorIsValid }"
            >
                <svg class="aix-colorfield__image" viewBox="0 0 1 1">
                    <rect
                        #swatch
                        class="aix-colorfield__swatch"
                        width="100%"
                        height="100%"
                        [attr.fill]="getSwatchFill()"
                    ></rect>
                </svg>
            </div>
        </div>
    </div>

    <div #hint class="aix-field__hint">
        @if (colorIsValid || isFirstChange) {
        <p>{{ fieldHint() }}</p>
        } @if (!colorIsValid && !isFirstChange) {
        <p>{{ fieldErrorHint() }}</p>
        }
    </div>
</div>
