<aix-notification
    #formsMustBeLabeledNotification
    [classList]="['u-mt24']"
    status="warning"
    [canClose]="true"
>
    All uploaded forms must be labeled to continue.
</aix-notification>

<div class="aix-toolbar u-mt24">
    <aix-button
        [buttonLabel]="'Continue'"
        [buttonType]="continueButtonType"
        (click)="onContinue()"
        [isDisabled]="unfinishedUploads || isReadOnly"
        data-testing="continue-button"
    >
    </aix-button>
</div>
