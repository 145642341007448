import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixNotificationComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '@advisor-ui/orders';
import { areFormUploadsCompletedInCurrentStep } from '../utils/order-utils';
import { fileType } from '@trade-platform/lib-enums';

@Component({
    selector: 'aix-wet-sign',
    templateUrl: './wet-sign.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixButtonComponent, AixDataTestingDirective, AixNotificationComponent]
})
export class AixOrderProcessWetSignComponent implements OnInit, OnDestroy {
    @Input() isReadOnly = false;
    @Output() onClickContinue: EventEmitter<never> = new EventEmitter<never>();

    @ViewChild('formsMustBeLabeledNotification')
    formsMustBeLabeledNotification: AixNotificationComponent;

    unfinishedUploads = true;
    areFormsUnlabeled = false;
    subscriptions: Subscription[] = [];
    continueButtonType = BUTTON_TYPE.primary;

    constructor(
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.storeFacade.orderSuccess$.subscribe(order => {
                this.unfinishedUploads = !areFormUploadsCompletedInCurrentStep(order);
                this.areFormsUnlabeled = order.files.some(
                    file => file.type === fileType.unassignedForm
                );
                this.formsMustBeLabeledNotification?.closeNotification();
                this.cd.detectChanges();
            })
        );
    }

    onContinue() {
        if (this.areFormsUnlabeled) {
            this.formsMustBeLabeledNotification.openNotification();
        } else {
            this.formsMustBeLabeledNotification.closeNotification();
            this.onClickContinue.emit();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
