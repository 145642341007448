import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentListItem } from '../../../models/document-viewer/model';
import { NgFor, NgClass } from '@angular/common';
import { AixDataTestingDirective } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-ui-document-list',
    templateUrl: './document-list.component.html',
    standalone: true,
    imports: [NgFor, AixDataTestingDirective, NgClass]
})
export class AixUIDocumentListComponent {
    @Input() documents: any[];
    @Input() currentDocument = '';

    @Output() onActivateDocument = new EventEmitter<string>();

    constructor() {}
}
