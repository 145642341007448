<ul class="filter-list">
    @for (filter of filters();track filter;let last = $last) {
    <li
        class="br-primary__color--hover"
        [attr.data-testing]="'filter-list-item-' + filter.id"
        [style.display]="filter.show === false ? 'none' : 'flex'"
        [class.br-link]="isActiveFilter(filter?.id)"
        [class.u-fw600]="isActiveFilter(filter?.id)"
        [class.group-divider]="last && showDivider()"
        (mousedown)="selectFilter(filter)"
    >
        @if (showCheckbox()) {
        <label class="checkbox-custom checkbox-custom-label-size u-mr0 u-mt2">
            <input type="checkbox" [checked]="isActiveFilter(filter?.id)" />
            <span class="checkbox-custom__indicator"></span>
        </label>
        }
        <span
            class="filter-list-label"
            data-testing="label"
            [class.br-primary__color]="isActiveFilter(filter?.id)"
        >
            {{ filter.label }}
        </span>
        @if (filter?.count || filter.count === 0) {
        <span class="aix-pill"> {{ filter.count }} </span>
        }
    </li>
    }
</ul>
