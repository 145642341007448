<div
    class="aix-form__group aix-form__group--textarea"
    [class.aix-form__control--dirty]="isDirty()"
    [class.aix-form__control--invalid]="!isValid()"
    [class.aix-form__control--invalid-required]="isRequired() && !inputField.value"
    [class.aix-form__control--invalid-disabled]="isDisabled() && !isValid()"
    [style.height.px]="height()"
>
    <textarea
        #inputField
        class="aix-form__control aix-form__control--textarea"
        type="text"
        autocomplete="new-password"
        [ngStyle]="inputStyle()"
        [style.height.px]="height()"
        [style.padding-top.px]="showLabel() ? paddingTop : 28"
        (input)="onUserInput($event)"
        (paste)="onUserInput($event)"
        [attr.placeholder]="config()?.placeholder ?? ''"
        [disabled]="isDisabled()"
        [required]="isRequired()"
        [class.aix-disabled]="isDisabled()"
        [class.aix-form__control--dirty]="isDirty()"
        [class.aix-form__control--invalid]="!isValid()"
        [class.aix-form__control--invalid-required]="isRequired() && !inputField.value"
        [class.aix-form__control--invalid-disabled]="isDisabled() && !isValid()"
        [data-testing]="config()?.refId!"
        [attr.tabindex]="isDisabled() ? '-1' : ''"
    ></textarea>
    @if (showLabel()) {
    <label #labelElement class="aix-form__label aix-label">
        <ng-content></ng-content>
    </label>
    }
</div>
