<aix-modal
    #modal
    [modalTitle]="'Edit Order and Cancel eSign'"
    (optionSelected)="modalSelected($event)"
    [buttonDisableFunction]="acceptCancelSignaturesConfirmed"
    (onCloseModal)="onCloseCancelSignaturesModal()"
    modalType="confirmation"
    data-testing="confirmation-modal"
>
    <ng-template>
        <p class="u-fw500 u-mb24">
            Signatures will be voided and the order will return to a draft state. This action cannot
            be undone.
        </p>
        <aix-checkbox
            [config]="{ refId: 'agreeCancelCheckbox' }"
            [bodyClassNames]=""
            (valueChanges)="cancelSignatures($event)"
            [isRequired]="true"
            >I agree to void signatures
        </aix-checkbox>
    </ng-template>
</aix-modal>
