<div class="offline--container">
    <h3 class="order-step-content-title">Your Order Needs to be Completed Offline</h3>
    <p class="u-mt16">
        <a class="br-link" (click)="onClickDownloadLink()">Download the order.</a>
    </p>
    <aix-button
        [buttonType]="viewPDFButtonType"
        class="offline--view-pdf"
        [buttonLabel]="'View PDF'"
        (click)="onClickViewPdf()"
        data-testing="view-pdf"
    ></aix-button>

    <hr />

    <div class="flex-center-center layout-full-h offline-confirmation u-mt24">
        <div class="flex-center-center layout-full-h offline-confirmation-message">
            <span class="u-full-width">{{ getSignaturesLabel() }}</span>
            <p class="u-mt16">
                <a class="br-link" (click)="onClickGoToOrders()">Go to Orders</a>
            </p>
        </div>
    </div>
</div>
