import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { SetOrderPathValueActionParams } from '@trade-platform/form-fields';
import { ApiFormError } from '@trade-platform/ui-utils';
import { RemoteData } from 'ngx-remotedata';
import { DocumentViewerModel } from '../models/document-viewer/model';
import { documentViewerReducer } from './document-viewer/reducer';
import {
    HideAuthorizedPersonAction,
    HideSecondAuthorizedPersonAction,
    ShowAuthorizedPersonAction,
    ShowSecondAuthorizedPersonAction
} from './dynamic-forms/actions';
import { flashMessagesReducer, FlashMessagesState } from './flash-messages/reducer';
import { stylesReducer, StylesState } from './styles/reducer';
import { LoadInvestorByTaxIdAction } from './investor/actions';
import { Investor } from '../models/investor/model';
import { investorReducer, InvestorState } from './investor/reducer';
import { ErrorWrapper } from './models/common';
import { RemoveInvestorFromOrder, SetOrderPathValueAction } from './order/actions';
import { Order, OrderId, UpdateOrderSuffix } from '../models/order/model';
import { LoadOrderState, orderReducerFactory } from './order/reducer';
import { outagesReducer, OutagesState } from './outages/reducer';
import { privacyPolicyReducer, PrivacyPolicyState } from './privacy-policy/reducer';
import {
    productExploreFiltersReducer,
    ProductExploreFiltersState,
    productExploreReducer,
    ProductExploreState,
    productFamilyReducer,
    ProductFamilyState,
    productFiltersReducer,
    ProductFiltersState,
    productReducer,
    productsExploreReducer,
    ProductsExploreState,
    productsReducer,
    ProductsState,
    ProductState,
    sendProductExploreReducer,
    SendProductExploreState
} from './product/reducer';
import {
    profilePasswordResetReducer,
    ProfilePasswordResetState,
    profileReducer,
    ProfileState,
    resetMFAReducer,
    ResetMFAState,
    sharedFirmsReducer,
    SharedFirmsState
} from './profile/reducer';
import { SharedRepCodesGridModel } from '../models/shared-rep-codes-grid/model';
import { SharedRepCodesGridReducer } from './shared-rep-codes-grid/reducer';
import { termsReducer, TermsState } from './terms/reducer';
import { updateReducerFactory } from './update/reducer';
import { SetFormDataAction } from './form/actions';
import { FileUploadModel } from '../models/file-upload/model';
import { fileUploadReducer } from './file-upload/reducer';
import {
    registerReducer,
    RegisterState,
    registerTokenReducer,
    RegisterTokenState
} from './register/reducer';
import { productMaterialsReducer, ProductMaterialsState } from './product-materials/reducer';
import {
    activePreviewBrandingReducer,
    applyBrandingReducer,
    brandingReducer,
    BrandingState,
    clearPreviewBrandingReducer,
    previewBrandingReducer,
    saveBrandingReducer
} from './branding/reducer';
import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

export const AUTH_PERSON_COUNT = 10;

export const ADVISOR_UI_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>(
    'Advisor-UI Reducers'
);

/**
 * App state interface
 */
export interface AppState {
    // TODO: (joanllenas) authPersonN is in deprecation. To be removed when Repeater is implemented in all forms?
    authPerson1: InvestorState;
    authPerson2: InvestorState;
    authPerson3: InvestorState;
    authPerson4: InvestorState;
    authPerson5: InvestorState;
    authPerson6: InvestorState;
    authPerson7: InvestorState;
    authPerson8: InvestorState;
    authPerson9: InvestorState;
    authPerson10: InvestorState;
    // ---
    router: RouterReducerState;
    profile: ProfileState;
    registerToken: RegisterTokenState;
    register: RegisterState;
    product: ProductState;
    products: ProductsState;
    productMaterials: ProductMaterialsState;
    productFilters: ProductFiltersState;
    productsExplore: ProductsExploreState;
    productExplore: ProductExploreState;
    productExploreFilters: ProductExploreFiltersState;
    sendProductExplore: SendProductExploreState;
    productFamily: ProductFamilyState;
    flashMessages: FlashMessagesState;
    styles: StylesState;
    order: LoadOrderState;
    orderUpdate: RemoteData<OrderId, ErrorWrapper | ApiFormError>;
    investor: InvestorState;
    joint: InvestorState;
    entity: InvestorState;
    terms: TermsState;
    privacyPolicy: PrivacyPolicyState;
    passwordReset: ProfilePasswordResetState;
    resetMFA: ResetMFAState;
    sharedFirms: SharedFirmsState;
    outages: OutagesState;
    sharedRepCodesGrid: SharedRepCodesGridModel;
    documentViewer: DocumentViewerModel;
    fileUpload: FileUploadModel;
    brand: BrandingState;
}

// TODO: (joanllenas) This is app specific. Every app needs to have its own actionFactory
// We don't want to drag app specific stores here just to satisfy this dependency.
// To be moved.
/**
 * Dynamic Forms Action Factory
 */
export const dynamicFormActions = {
    SetFormDataAction: (data: any, params: any) => new SetFormDataAction(params),
    RemoveInvestorFromOrder: (investor: Investor[]) =>
        new RemoveInvestorFromOrder({
            investor: investor[0],
            reducerSuffix: 'buy' // it doesn't matter which suffix we use here, because the order reducer is shared.
        }),
    // Show second and Hide second are deprecated actions, use Show auth and Hide auth now
    ShowSecondAuthorizedPersonAction: () => new ShowSecondAuthorizedPersonAction(),
    HideSecondAuthorizedPersonAction: () => new HideSecondAuthorizedPersonAction(),
    ShowAuthorizedPersonAction: (index: number) => new ShowAuthorizedPersonAction(index),
    HideAuthorizedPersonAction: (index: number) => new HideAuthorizedPersonAction(index),
    LoadInvestorByTaxIdAction: (taxId: string) =>
        new LoadInvestorByTaxIdAction({
            taxId: taxId,
            personTypeFilter: 'human',
            reducerSuffix: 'primary'
        }),
    SetOrderPathValueAction: (value: any, params: SetOrderPathValueActionParams) =>
        new SetOrderPathValueAction({
            value,
            params,
            reducerSuffix: 'buy' // it doesn't matter which suffix we use here, because the order reducer is shared.
        })
};

/**
 * Root reducer
 */
export const reducers = {
    // TODO: (joanllenas) authPersonN is in deprecation. To be removed when Repeater is implemented in all forms?
    authPerson1: investorReducer('authPerson1'),
    authPerson2: investorReducer('authPerson2'),
    authPerson3: investorReducer('authPerson3'),
    authPerson4: investorReducer('authPerson4'),
    authPerson5: investorReducer('authPerson5'),
    authPerson6: investorReducer('authPerson6'),
    authPerson7: investorReducer('authPerson7'),
    authPerson8: investorReducer('authPerson8'),
    authPerson9: investorReducer('authPerson9'),
    authPerson10: investorReducer('authPerson10'),
    router: routerReducer,
    profile: profileReducer,
    registerToken: registerTokenReducer,
    register: registerReducer,
    product: productReducer,
    productFilters: productFiltersReducer,
    productsExplore: productsExploreReducer,
    productExplore: productExploreReducer,
    sendProductExplore: sendProductExploreReducer,
    productExploreFilters: productExploreFiltersReducer,
    products: productsReducer,
    productMaterials: productMaterialsReducer,
    productFamily: productFamilyReducer,
    flashMessages: flashMessagesReducer,
    styles: stylesReducer,
    order: orderReducerFactory(['buy', 'maintenance']),
    orderUpdate: updateReducerFactory<Order, { formId: string }, OrderId, UpdateOrderSuffix>(
        'orderUpdate'
    ),
    investor: investorReducer('primary'),
    joint: investorReducer('joint'),
    entity: investorReducer('entity'),
    terms: termsReducer,
    privacyPolicy: privacyPolicyReducer,
    passwordReset: profilePasswordResetReducer,
    resetMFA: resetMFAReducer,
    sharedFirms: sharedFirmsReducer,
    outages: outagesReducer,
    sharedRepCodesGrid: SharedRepCodesGridReducer,
    documentViewer: documentViewerReducer,
    fileUpload: fileUploadReducer,
    brand: brandingReducer,
    applyBranding: applyBrandingReducer,
    saveBranding: saveBrandingReducer,
    previewBranding: previewBrandingReducer,
    clearBranding: clearPreviewBrandingReducer,
    activePreviewBranding: activePreviewBrandingReducer
};

export function getBaseReducers() {
    return reducers;
}
