import { Component, input, output } from '@angular/core';
import { AixButtonComponent, BUTTON_TYPE } from '../aix-button/aix-button.component';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgIf } from '@angular/common';

@Component({
    selector: 'aix-thumbnail',
    templateUrl: './aix-thumbnail.component.html',
    styleUrls: ['./aix-thumbnail.component.scss'],
    standalone: true,
    imports: [NgIf, AixButtonComponent, AixDataTestingDirective]
})
export class AixThumbnailComponent {
    thumbnail = input<string | undefined>();
    id = input<string>();
    isSelected = input(false);
    actionClick = output<string>();

    buttonLabelButtonType = BUTTON_TYPE.primary;

    onActionClicked() {
        this.actionClick.emit(this.id() as unknown as string);
    }
}
