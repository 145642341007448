<section>
    <aix-header-section [title]="'Admin Actions'"></aix-header-section>
    <aix-notification
        #notificationRef
        [status]="notificationType"
        [canClose]="true"
        [isOpen]="false"
        [classList]="['u-mb16']"
    >
        {{ notificationMessage }}
    </aix-notification>
    <div class="flex-col flex-left-stretch">
        <div class="admin-action-card admin-action-container">
            <div>
                <h5 class="u-pb4">Convert Order to Wetsign</h5>
                <p>Subsequent signers will be prompted to download and upload signed documents or, if enabled, to drop signatures in Docusign.</p>
            </div>

            <div class="u-text-right">
                <aix-button
                    (click)="onClickUpdateOrder('toWetsign')"
                    [isDisabled]="isWetsign || !canConvertToWetsign"
                    [buttonType]="buttonTypeSecondary"
                    [buttonLabel]="'Convert to Wetsign'"
                >
                </aix-button>
                @if (isWetsign) {
                    <p class="sublabel">Order is already set to wetsign.</p>
                }
                @if (!canConvertToWetsign) {
                    <p class="sublabel">Order is not in the correct status.</p>
                }
            </div>
        </div>
        <div class="admin-action-card">
            <div class="admin-action-container">
                <div>
                    <h5 class="u-pb4">Push Updated Form</h5>
                    <p>The specified form on the order will be replaced with the most recently deployed version.</p>
                </div>
    
                <div class="u-text-right">
                    <aix-button
                        (click)="toggleUpdateFormOpen()"
                        [isDisabled]=""
                        [buttonType]="buttonTypeLink"
                        [icon]="updateFormOpen ? 'fa-caret-up' : 'fa-caret-down'"
                    >
                    </aix-button>
                </div>
            </div>
            <div [class.open]="updateFormOpen" [class.closed]="!updateFormOpen">
                <div class="admin-action-container u-pt24">
                    <div class="form-selection-container">
                        <h5 class="u-pb4">Select Form to Update</h5>
                        <form [formGroup]="updateFormSelection">
                            <div class="aix-form__group">
                                <aix-dropdown
                                    class="standalone-dropdown aix-form__control--required"
                                    formControlName="formId"
                                    labelField="name"
                                    valueField="id"
                                    placeholder="Select Form"
                                    [options]="orderForms"
                                    (ngModelChange)="selectForm($event)"
                                >
                                </aix-dropdown>
                                <label class="aix-form__label aix-label">Order Form</label>
                            </div>
                        </form>
                    </div>
                    <div class="u-text-right">
                        <aix-button
                            (click)="onClickUpdateOrder('updateForm')"
                            [isDisabled]="!selectedFormId"
                            [buttonType]="buttonTypeSecondary"
                            [buttonLabel]="'Update Form'"
                        >
                        </aix-button>
                        @if (selectedFormId) {
                            <p class="sublabel">Update form id {{selectedFormId}}.</p>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div class="admin-action-card admin-action-container">
            <div>
                <h5 class="u-pb4">Change Needs Changes to Needs Clarification</h5>
                <p>The advisor or investor will be able to return the order to the previous review step without voiding signatures.</p>
            </div>

            <div class="u-text-right">
                <aix-button
                    (click)="onClickUpdateOrder('toNeedsClarification')"
                    [isDisabled]="status !== 'nigo'"
                    [buttonType]="buttonTypeSecondary"
                    [buttonLabel]="'NIGO to Needs Clarification'"
                >
                </aix-button>
                @if (status === 'needsClarification') {
                    <p class="sublabel">Order is already in the Needs Clarification status.</p>
                }
                @if (status !== 'nigo' && status !== 'needsClarification') {
                    <p class="sublabel">Order is not in the Needs Changes status.</p>
                }
            </div>
        </div>
        <div class="admin-action-card admin-action-container">
            <div>
                <h5 class="u-pb4">Mark Order Complete</h5>
                <p>The order will be marked complete, regardless of the current status.</p>
            </div>

            <div class="u-text-right">
                <aix-button
                    (click)="onClickUpdateOrder('markComplete')"
                    [isDisabled]="status === 'completed'"
                    [buttonType]="buttonTypeSecondary"
                    [buttonLabel]="'Mark Complete'"
                >
                </aix-button>
                @if (status === 'completed') {
                    <p class="sublabel">Order is already in the Completed status.</p>
                }
            </div>
        </div>
        <div class="admin-action-card admin-action-container">
            <div>
                <h5 class="u-pb4">Resubmit Order to Transfer Agent</h5>
                <p>The order will be resubmitted to the transfer agent.</p>
            </div>

            <div class="u-text-right">
                <aix-button
                    (click)="onClickUpdateOrder('resubmit')"
                    [isDisabled]="status !== 'submitted' && status !== 'offline'"
                    [buttonType]="buttonTypeSecondary"
                    [buttonLabel]="'Resubmit'"
                >
                </aix-button>
                @if (status !== 'submitted' && status !== 'offline') {
                    <p class="sublabel">Order is not in a submitted or offline status.</p>
                }
            </div>
        </div>
    </div>
</section>
<aix-modal
    #confirmationModal
    modalType="confirmation"
    [modalTitle]="modalText.title"
    (optionSelected)="confirmationModalSelected($event)"
>
    <ng-template #confirmationTemplate>
        <p>{{ modalText.text }}</p>
    </ng-template>
</aix-modal>
