<aix-header-section
    [title]="'Choose Signing Method'"
    [data-testing]="'signing-method-header'"
></aix-header-section>

<aix-notification #notification status="warning" data-testing="smsAuthenticationNotification">
    <p class="u-fw500">This order requires SMS authentication for eSigning.</p>
    <p>
        To eSign, please
        <span class="br-link selectable" (click)="onMakeChanges.emit()">edit order</span> and
        provide mobile numbers.
    </p>
</aix-notification>
<div class="flex-left-top">
    <form [formGroup]="deliveryForm">
        <aix-radio-group
            formControlName="deliveryMethod"
            class="radio-order-process"
            [options]="deliveryMethods"
            [labelField]="'title'"
            [valueField]="'value'"
            [descriptionField]="'description'"
            [isValid]="!!deliveryForm.get('deliveryMethod')?.valid"
            [isRequired]="true"
            [isDisabled]="isReadOnly"
        >
        </aix-radio-group>
    </form>
</div>

<div class="flex-right-top u-mt20">
    <aix-button
        data-testing="continue-button"
        [isDisabled]="!deliveryMethod || isReadOnly"
        (click)="onClickContinue()"
        [buttonType]="continueButtonType"
        [buttonLabel]="'Continue'"
    >
    </aix-button>
</div>
