<div class="aix-form__group">
    <div class="expandable-text" [hidden]="!expanded">
        <span
            class="fa-regular fa-xmark u-color-tertiary-black expandable-text-close-btn"
            data-testing="close-expandable-text-button"
            (click)="toggle()"
        ></span>
        <div
            #textContainerRef
            class="aix-form__group-message free-text__text"
            [data-testing]="config.refId!"
            [innerHTML]="(valueToRender$ | async)! | aixSanitize"
        ></div>
    </div>
    <button class="aix-btn aix-btn--link br-link" (click)="toggle()">
        {{ expanded ? config.collapseLabel : config.expandLabel }}
    </button>
</div>
