import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

export interface AdminPushUpdatedFormToOrderBody {
    orderId: string;
    formId: string;
}

@Injectable()
export class OrderAdminService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    convertOrderNigoToNeedsClarification(orderId: string): Observable<any> {
        return this.http.post<null>(
            `${this.environment.adminApi}orders/${orderId}/nigoToNeedsClarification`,
            {}
        );
    }

    convertOrderToWetsign(orderId: string): Observable<any> {
        return this.http.post<null>(
            `${this.environment.adminApi}orders/${orderId}/convertToWetsign`,
            {}
        );
    }

    adminMarkOrderComplete(orderId: string): Observable<any> {
        return this.http.post<null>(
            `${this.environment.adminApi}orders/${orderId}/markComplete`,
            {}
        );
    }

    adminResubmitOrder(orderId: string): Observable<any> {
        return this.http.post<null>(`${this.environment.adminApi}orders/${orderId}/resubmit`, {});
    }

    adminPushUpdatedFormToOrder(orderId: string, formId: string): Observable<any> {
        return this.http.patch<null>(
            `${this.environment.adminApi}orders/${orderId}/forms/${formId}`,
            {}
        );
    }
}
