import { Component, ElementRef } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { ColumnApi, IHeaderParams } from 'ag-grid-community';
import { AixGridColumnsComponent } from './grid-columns';

/**
 * Don't forget to add the CSS class 'aix-grid--header-with-overlays' to
 * the <ag-grid-angular> tag to avoid the popup appearing cut.
 */
@Component({
    template: ` <aix-grid-columns [columnApi]="columnApi"></aix-grid-columns> `,
    standalone: true,
    imports: [AixGridColumnsComponent]
})
export class AixGridColumnsHeaderComponent implements IHeaderAngularComp {
    public params: IHeaderParams;
    public columnApi: ColumnApi;
    private elementRef: ElementRef;

    constructor(elementRef: ElementRef) {
        this.elementRef = elementRef;
    }

    refresh(params: IHeaderParams): boolean {
        // TODO: https://www.ag-grid.com/angular-data-grid/component-header/#refresh
        return true;
    }

    agInit(params: IHeaderParams): void {
        this.params = params;
        this.columnApi = this.params.columnApi;
    }
}
