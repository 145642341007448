<div class="checkbox-custom-wrapper">
    <label
        class="checkbox-custom checkbox-custom-label-size"
        [class.aix-disabled]="checkboxData().disabled"
    >
        <input
            #inputField
            (change)="setChildren(!checkboxData().checked)"
            (click)="onClick()"
            type="checkbox"
            [checked]="checkboxData().checked"
            [class.aix-disabled]="checkboxData().disabled"
            [required]="isRequired()"
            [class.aix-form__control--invalid]="!isValid()"
            [class.aix-form__control--invalid-required]="isRequired() && !checkboxData().checked"
            [class.aix-form__control--invalid-dirty]="
                isRequired() && !checkboxData().checked && isDirty()
            "
        />
        <span
            class="checkbox-custom__indicator"
            [class.aix-form__control--invalid]="isRequired()"
            [class.aix-form__control--invalid-required]="isRequired()"
        >
        </span>
        <span
            class="checkbox-custom__label checkbox-custom__label--xl"
            data-testing="checkbox-group-item-label"
        >
            {{ checkboxData().displayName }}
            <span class="checkbox-custom__sublabel">{{ checkboxData().description }}</span>
        </span>
    </label>
    <br />
    @for (child of checkboxData().childNodes;track child) {
    <div class="checkbox-custom--indented radio-custom--indented">
        <aix-checkbox-item
            (onCheckboxChange)="onChange()"
            (onCheckboxClick)="onClick()"
            [checkboxData]="child"
            [isRequired]="isRequired()"
            [isValid]="isValid()"
            [isDirty]="isDirty()"
        ></aix-checkbox-item>
    </div>
    }
</div>
