@if (fileOwnerMap!.size > 0) {
<div>
    @for (owner of fileOwnerMap | keyvalue;track owner.value.id) {
    <div>
        <aix-filelist-items
            [files]="owner.value | sortBy:'asc':'name'"
            [uploadUrl]="uploadUrl"
            [uploadCompleted]="uploadCompleted"
            [enableIgnore]="enableIgnore"
            [isReadOnly]="isReadOnly"
            (ignoreFile)="ignoreElem.emit($event)"
            (unignoreFile)="unignoreElem.emit($event)"
            (editFile)="editElem.emit($event)"
            (removeFile)="removeElem.emit($event)"
            [fileUploadOpen]="fileUploadOpen"
        ></aix-filelist-items>
    </div>
    }
</div>
}
