import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'aix-ui-document-tabs',
    templateUrl: './document-tabs.component.html',
    styleUrls: ['./document-tabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, NgClass]
})
export class AixUIDocumentTabsComponent {
    @Input() activeTab = 'Order';
    @Input() tabs: string[] = ['Order'];

    @Output() onClickTab = new EventEmitter<string>();

    constructor() {}
}
