@if (!isPrivacy && !isOutages) {
<div>
    <div class="nav-alert-container">
        <aix-system-notifications></aix-system-notifications>
        <div class="branding-notification">
            <aix-notification #brandingNotification status="alert" [canClose]="false">
                You’re currently previewing your changes, please save any changes before navigating
                away from this page.
            </aix-notification>
        </div>
        @if (!isPrivacy) {
        <div
            #navbar
            class="navbar hide-if-print br-navbar--bg__background-color -expanded"
            [ngClass]="navbarClasses"
            [ngStyle]="styles.utilityContainer"
        >
            <div class="container flex-center-center">
                <div>
                    <img class="navbar__logo" [src]="logo" />
                </div>
                <div class="flex-spacer"></div>
                @if (!isRegister) {
                <div>
                    <ul class="navbar-items">
                        @for (item of menuItems; track item) {
                        <li>
                            @if (!isTermsAndConditions) {
                            <a
                                class="br-navbar--text__color br-navbar--hover__color--hover"
                                [class.br-navbar--hover__color]="(activeRouteName$ | async) === item.routeName"
                                (click)="onClickLink(item)"
                                [ngStyle]="getNavbarLinkStyle(item.routeName)"
                                (mouseover)="onHoverLink($event.target)"
                                (mouseleave)="onLeaveLink($event.target, item.routeName)"
                            >
                                {{ item.label }}
                            </a>
                            }
                        </li>
                        }
                        <li>
                            <a
                                #supportDropdown
                                data-testing="show-support-dropdown"
                                class="br-navbar--text__color br-navbar--hover__color--hover"
                                [class.br-navbar--hover__color]="supportDropdownClasses['-open']"
                                [ngStyle]="getNavbarLinkStyle('')"
                                (mouseenter)="onHoverLink($event.target)"
                                (mouseleave)="onLeaveMenuIcon($event.target, 'support')"
                                (click)="showSupportDropdown()"
                            >
                                <i class="fa-regular fa-circle-question"></i>
                            </a>
                            <ul
                                [ngClass]="supportDropdownClasses"
                                [ngStyle]="styles.utilityContainer"
                                data-testing="support-dropdown"
                                class="-dropdown br-navbar--bg__background-color"
                            >
                                <li
                                    class="br-navbar--text__color"
                                    [ngStyle]="getNavbarLinkStyle('')"
                                >
                                    Need help?
                                    <div class="-text--normal">Please contact our help desk.</div>
                                </li>
                                <li
                                    class="br-navbar--text__color"
                                    [ngStyle]="getNavbarLinkStyle('')"
                                >
                                    Email us:
                                    <div class="-text--normal">
                                        <a
                                            href="mailto:{{ supportEmail }}"
                                            class="br-navbar--text__color br-navbar--hover__color--hover"
                                            [ngStyle]="getNavbarLinkStyle('')"
                                            (mouseenter)="onHoverLink($event.target)"
                                            (mouseleave)="onLeaveLink($event.target, '')"
                                        >
                                            {{ supportEmail }}</a
                                        >
                                    </div>
                                </li>
                                <li
                                    class="br-navbar--text__color"
                                    [ngStyle]="getNavbarLinkStyle('')"
                                >
                                    Call us:
                                    <div class="-text--normal">
                                        <a
                                            href="tel:{{supportPhone}}"
                                            class="br-navbar--text__color br-navbar--hover__color--hover"
                                            [ngStyle]="getNavbarLinkStyle('')"
                                            (mouseenter)="onHoverLink($event.target)"
                                            (mouseleave)="onLeaveLink($event.target, '')"
                                        >
                                            {{ supportPhone }}</a
                                        >
                                        @if ((data$ | async)?.organization?.extension) {
                                        <span>
                                            ext. {{ (data$ | async)?.organization?.extension }}
                                        </span>
                                        }
                                    </div>
                                    <div class="-text--normal">Mon-Fri / 9 AM - 6 PM ET</div>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a
                                #profileDropdown
                                data-testing="show-profile-dropdown"
                                class="br-navbar--text__color br-navbar--hover__color--hover"
                                [class.br-navbar--hover__color]="(router?.url || '').indexOf('profile') > -1 || profileDropdownClasses['-open']"
                                [ngStyle]="getNavbarLinkStyle('profile')"
                                (mouseenter)="onHoverLink($event.target)"
                                (mouseleave)="onLeaveMenuIcon($event.target, 'profile')"
                                (click)="showProfileDropdown()"
                            >
                                <i class="fa-regular fa-user"></i>
                            </a>

                            <ul
                                [ngClass]="profileDropdownClasses"
                                [ngStyle]="styles.utilityContainer"
                                class="-dropdown br-navbar--bg__background-color"
                            >
                                <li
                                    class="br-navbar--text__color"
                                    [ngStyle]="getNavbarLinkStyle('')"
                                >
                                    <div class="u-pb16">
                                        <div class="-text--small">Signed In As</div>
                                        {{ (data$ | async)?.fullName }}
                                        <div class="-text--normal">
                                            {{ (data$ | async)?.email }}
                                        </div>
                                    </div>
                                </li>
                                @if (!isTermsAndConditions) {
                                <li (click)="hideProfileDropdown()">
                                    <a
                                        [routerLink]="['/profile']"
                                        class="br-navbar--text__color br-navbar--hover__color--hover"
                                        [ngStyle]="getNavbarLinkStyle('')"
                                        (mouseenter)="onHoverLink($event.target)"
                                        (mouseleave)="onLeaveLink($event.target, '')"
                                    >
                                        User Profile
                                    </a>
                                </li>
                                }
                                <li>
                                    <a
                                        class="br-navbar--text__color br-navbar--hover__color--hover"
                                        [ngStyle]="getNavbarLinkStyle('')"
                                        (mouseenter)="onHoverLink($event.target)"
                                        (mouseleave)="onLeaveLink($event.target, '')"
                                        (click)="logOut()"
                                    >
                                        Log Out
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                }
            </div>
        </div>
        }
    </div>
</div>
}
