import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment, stringify } from '@trade-platform/ui-utils';
import {
    productDecoder,
    productExploreRecordDecoder,
    productExploreRecordListDecoder,
    productFamilyDecoder,
    productFiltersDecoder,
    productHeadersDecoder,
    productRecordListDecoder,
    SendExploreRecordDecoder
} from '../../decoders/product/decoder';
import {
    AixCheckboxItem,
    ExploreFilterOptions,
    ExploreFilterOptionsType,
    ExploreSend,
    Product,
    ProductExploreRecord,
    ProductFamily,
    ProductHeaders,
    ProductRecord,
    ProductsFilterOptions,
    ProductsFilterOptionsParsed,
    SendExploreRecord
} from '../../models/product/model';
import { ResponseWithHeaders, responseWithHeadersDecoder } from '../models/response-with-headers';
import { ProductsFilterType } from './actions';

@Injectable()
export class ProductService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    getProduct(id: string): Observable<ResponseWithHeaders<Product, ProductHeaders>> {
        return this.http
            .get<Product>(`${this.environment.api}funds/${id}`, { observe: 'response' })
            .pipe(
                concatMap(p =>
                    fromPromise(
                        responseWithHeadersDecoder(
                            productDecoder,
                            productHeadersDecoder,
                            'Product'
                        ).decodeToPromise(p)
                    )
                )
            );
    }

    getProductFamily(id: string, orderType?: string): Observable<ProductFamily> {
        return this.http
            .get<ProductFamily>(
                orderType != null && orderType === 'maintenance'
                    ? `${this.environment.api}${orderType}/funds/family/${id}`
                    : `${this.environment.api}funds/family/${id}`
            )
            .pipe(concatMap(p => fromPromise(productFamilyDecoder.decodeToPromise(p))));
    }

    getProducts(orderType?: string, options?: ProductsFilterOptions): Observable<ProductRecord[]> {
        let params = '';
        if (options) {
            const parsed: ProductsFilterOptionsParsed = {};
            Object.keys(options).forEach(key => {
                if (options[key as ProductsFilterType]) {
                    parsed[key as ProductsFilterType] =
                        options[key as ProductsFilterType]?.split('--');
                }
            });
            const encoded = stringify(parsed);
            if (encoded) {
                params = '?' + encoded;
            }
        }

        return this.http
            .get<ProductRecord[]>(
                orderType !== null && orderType === 'maintenance'
                    ? `${this.environment.api}${orderType}/funds${params}`
                    : `${this.environment.api}funds${params}`
            )
            .pipe(concatMap(p => fromPromise(productRecordListDecoder.decodeToPromise(p))));
    }

    getProductsFilters(orderType?: string): Observable<AixCheckboxItem[]> {
        return this.http
            .get<any>(
                orderType !== null && orderType === 'maintenance'
                    ? `${this.environment.api}funds/filters?type=${orderType}`
                    : `${this.environment.api}funds/filters`
            )
            .pipe(concatMap(p => fromPromise(productFiltersDecoder.decodeToPromise(p))));
    }

    getProductsExplore(options?: ExploreFilterOptions): Observable<ProductExploreRecord[]> {
        let params = '';
        if (options) {
            const parsed: ProductsFilterOptionsParsed = {};
            Object.keys(options).forEach(key => {
                if (options[key as ExploreFilterOptionsType]) {
                    parsed[key as ProductsFilterType] =
                        options[key as ExploreFilterOptionsType]?.split('--');
                }
            });
            const encoded = stringify(parsed);
            if (encoded) {
                params = '?' + encoded;
            }
        }

        return this.http
            .get<ProductExploreRecord[]>(`${this.environment.api}funds/explore${params}`)
            .pipe(concatMap(p => fromPromise(productExploreRecordListDecoder.decodeToPromise(p))));
    }

    getProductExploreFilters(): Observable<AixCheckboxItem[]> {
        return this.http
            .get<any>(`${this.environment.api}funds/explore/filters`)
            .pipe(concatMap(p => fromPromise(productFiltersDecoder.decodeToPromise(p))));
    }

    getProductExplore(fundSponsorId: string): Observable<ProductExploreRecord> {
        return this.http
            .get<ProductExploreRecord>(`${this.environment.api}funds/explore/${fundSponsorId}`)
            .pipe(concatMap(p => fromPromise(productExploreRecordDecoder.decodeToPromise(p))));
    }

    sendProductExplore(data: ExploreSend): Observable<SendExploreRecord> {
        return this.http
            .post<SendExploreRecord>(`${this.environment.api}funds/explore`, data)
            .pipe(concatMap(p => fromPromise(SendExploreRecordDecoder.decodeToPromise(p))));
    }
}
