import { JsonDecoder } from 'ts.data.json';
import { distributionsDecoder } from '../distributions/decoder';
import { Distributions } from '../../models/distributions/model';
import { fundingInstructionDecoder } from '../funding-instructions/decoder';
import { FundingInstructions } from '../../models/funding-instructions/model';
import { investorDecoder } from '../investor/decoder';
import { Investor } from '../../models/investor/model';
import {
    Account,
    AccountCustomFields,
    AccountDocument,
    AccountDocumentPayload,
    AccountDocumentsFilter,
    AccountDocumentType,
    AccountOrdersFilter,
    AccountPosition,
    AccountPositionDisplayField,
    AccountPositionFootnote,
    AccountRecord,
    AccountSecurity
} from '../../models/account/model';
import { AccountInvestment } from '../../models/registrations/model';
import { AccountInvestmentDecoder } from '../registrations/decoder';
import { filterListDecoder } from '../filters/decoders';

// Account

export const accountPositionFootnoteDecoder = JsonDecoder.object<AccountPositionFootnote>(
    {
        symbol: JsonDecoder.string,
        text: JsonDecoder.string
    },
    'accountPositionFootnote'
);

export const accountPositionDisplayFieldDecoder = JsonDecoder.object<AccountPositionDisplayField>(
    {
        dataType: JsonDecoder.string,
        displayName: JsonDecoder.string,
        footnotes: JsonDecoder.array(accountPositionFootnoteDecoder, 'AccountPositionFootnote[]'),
        headerName: JsonDecoder.string,
        sequence: JsonDecoder.number,
        value: JsonDecoder.string
    },
    'accountPositionDisplayField'
);

export const accountPositionDecoder = JsonDecoder.object<AccountPosition>(
    {
        accountId: JsonDecoder.optional(JsonDecoder.string),
        currentValueFootnote: JsonDecoder.optional(JsonDecoder.string),
        cusip: JsonDecoder.string,
        dateClosed: JsonDecoder.optional(JsonDecoder.string),
        dateOpened: JsonDecoder.optional(JsonDecoder.string),
        disclaimerText: JsonDecoder.optional(JsonDecoder.string),
        displayName: JsonDecoder.string,
        displayFields: JsonDecoder.optional(
            JsonDecoder.array(accountPositionDisplayFieldDecoder, 'AccountPositionDisplayField[]')
        ),
        fundManagerName: JsonDecoder.optional(JsonDecoder.string),
        id: JsonDecoder.string,
        fundId: JsonDecoder.optional(JsonDecoder.string),
        marketValue: JsonDecoder.number,
        name: JsonDecoder.string,
        positionStatus: JsonDecoder.optional(JsonDecoder.string),
        priceAsOf: JsonDecoder.optional(JsonDecoder.string),
        priceType: JsonDecoder.optional(JsonDecoder.string),
        readOnly: JsonDecoder.optional(JsonDecoder.boolean),
        recordedAt: JsonDecoder.optional(JsonDecoder.string),
        securityCustomName: JsonDecoder.optional(JsonDecoder.string),
        securityDisplayName: JsonDecoder.optional(JsonDecoder.string),
        securityId: JsonDecoder.optional(JsonDecoder.string),
        securityName: JsonDecoder.optional(JsonDecoder.string),
        symbol: JsonDecoder.string,
        unitPrice: JsonDecoder.optional(JsonDecoder.number),
        units: JsonDecoder.optional(JsonDecoder.number)
    },
    'AccountPosition'
);

export const accountSecurityDecoder = JsonDecoder.object<AccountSecurity>(
    {
        id: JsonDecoder.string,
        displayName: JsonDecoder.optional(JsonDecoder.string),
        name: JsonDecoder.optional(JsonDecoder.string)
    },
    'AccountSecurity'
);

export const accountCustomFieldsDecoder = JsonDecoder.object<AccountCustomFields>(
    {
        lastMaintenanceDate: JsonDecoder.optional(JsonDecoder.string),
        externalCustodianId: JsonDecoder.optional(JsonDecoder.string),
        externalCustodianName: JsonDecoder.optional(JsonDecoder.string)
    },
    'AccountCustomFields'
);

export const accountDocumentDecoder = JsonDecoder.object<AccountDocument>(
    {
        id: JsonDecoder.string,
        accountId: JsonDecoder.string,
        type: JsonDecoder.string,
        subtype: JsonDecoder.string,
        displayName: JsonDecoder.string,
        filename: JsonDecoder.string,
        url: JsonDecoder.string,
        version: JsonDecoder.string,
        documentDate: JsonDecoder.string,
        securityName: JsonDecoder.optional(JsonDecoder.string),
        contactName: JsonDecoder.optional(JsonDecoder.string),
        thumbnail: JsonDecoder.optional(JsonDecoder.string),
        download: JsonDecoder.optional(JsonDecoder.boolean),
        name: JsonDecoder.optional(JsonDecoder.string),
        userUploaded: JsonDecoder.optional(JsonDecoder.boolean)
    },
    'AccountDocument'
);

export const accountDocumentsDecoder = JsonDecoder.array<AccountDocument>(
    accountDocumentDecoder,
    'AccountDocument[]'
);

export const accountDocumentPayloadDecoder = JsonDecoder.object<AccountDocumentPayload>(
    {
        id: JsonDecoder.string,
        options: JsonDecoder.succeed
    },
    'accountDocumentPayload'
);

export const accountDocumentsFiltersDecoder = JsonDecoder.array(
    JsonDecoder.object<AccountDocumentsFilter>(
        {
            label: JsonDecoder.string,
            key: JsonDecoder.string,
            queues: JsonDecoder.optional(filterListDecoder)
        },
        'AccountDocumentsFilter'
    ),
    'AccountDocumentsFilter[]'
);

export const accountOrdersFiltersDecoder = JsonDecoder.array(
    JsonDecoder.object<AccountOrdersFilter>(
        {
            label: JsonDecoder.string,
            id: JsonDecoder.string,
            index: JsonDecoder.number,
            queues: JsonDecoder.optional(filterListDecoder)
        },
        'AccountOrdersFilter'
    ),
    'AccountOrdersFilter[]'
);

export const accountDecoder = JsonDecoder.object<Account>(
    {
        accountNumber: JsonDecoder.optional(JsonDecoder.string),
        accountOwnershipType: JsonDecoder.optional(JsonDecoder.string),
        accountType: JsonDecoder.string,
        accountTypeName: JsonDecoder.optional(JsonDecoder.string),
        contactNames: JsonDecoder.optional(JsonDecoder.string),
        custodianName: JsonDecoder.optional(JsonDecoder.string),
        dateClosed: JsonDecoder.optional(JsonDecoder.string),
        dateOpened: JsonDecoder.optional(JsonDecoder.string),
        displayName: JsonDecoder.optional(JsonDecoder.string),
        entity: JsonDecoder.optional(investorDecoder).map(entity => entity || ({} as Investor)),
        id: JsonDecoder.optional(JsonDecoder.string),
        investors: JsonDecoder.optional(
            JsonDecoder.array<Investor>(investorDecoder, 'Investor[]')
        ).map(investors => investors || ([] as Investor[])),
        name: JsonDecoder.optional(JsonDecoder.string),
        customName: JsonDecoder.optional(JsonDecoder.string),
        ownershipType: JsonDecoder.optional(JsonDecoder.string),
        totalMarketValue: JsonDecoder.optional(JsonDecoder.number),
        totalCashValue: JsonDecoder.optional(JsonDecoder.number),
        isTransferOnDeath: JsonDecoder.optional(JsonDecoder.boolean),
        beneficiaries: JsonDecoder.optional(JsonDecoder.array(investorDecoder, 'Investor[]')),
        fundingInstructions: JsonDecoder.optional(fundingInstructionDecoder).map(
            fundingInstructions => fundingInstructions || ({} as FundingInstructions)
        ),
        distributions: JsonDecoder.optional(distributionsDecoder).map(
            distributions => distributions || ({} as Distributions)
        ),
        taxIdNumber: JsonDecoder.optional(JsonDecoder.string),
        ppmNumber: JsonDecoder.optional(JsonDecoder.string),
        customFields: JsonDecoder.optional(accountCustomFieldsDecoder),
        investments: JsonDecoder.optional(
            JsonDecoder.array<AccountInvestment>(AccountInvestmentDecoder, 'AccountInvestment[]')
        ).map(investments => investments || []),
        positions: JsonDecoder.optional(
            JsonDecoder.array<AccountPosition>(accountPositionDecoder, 'AccountPosition[]')
        ),
        hasAccountDocuments: JsonDecoder.optional(JsonDecoder.boolean),
        productNames: JsonDecoder.optional(JsonDecoder.string),
        firmName: JsonDecoder.optional(JsonDecoder.string),
        firmCRDNumber: JsonDecoder.optional(JsonDecoder.string),
        advisorName: JsonDecoder.optional(JsonDecoder.string),
        advisorCRDNumber: JsonDecoder.optional(JsonDecoder.string),
        advisorEmail: JsonDecoder.optional(JsonDecoder.string),
        advisorPhone: JsonDecoder.optional(JsonDecoder.string),
        dataSource: JsonDecoder.optional(JsonDecoder.string),
        securities: JsonDecoder.optional(
            JsonDecoder.array<AccountSecurity>(accountSecurityDecoder, 'AccountSecurity[]')
        )
    },
    'Account'
);

export const accountListDecoder = JsonDecoder.array<Account>(accountDecoder, 'Account[]');

// AccountRecord

export const accountRecordDecoder = JsonDecoder.object<AccountRecord>(
    {
        id: JsonDecoder.string,
        name: JsonDecoder.string,
        accountNumber: JsonDecoder.string,
        totalMarketValue: JsonDecoder.number,
        totalCashValue: JsonDecoder.number
    },
    'AccountRecord'
);

export const accountRecordListDecoder = JsonDecoder.array<AccountRecord>(
    accountRecordDecoder,
    'AccountRecord[]'
);

export const AccountsDocumentDecoder = JsonDecoder.array(
    JsonDecoder.object<AccountDocumentType>(
        {
            name: JsonDecoder.string,
            value: JsonDecoder.string
        },
        'AccountsDocument'
    ),
    'AccountsDocument[]'
);
