import { Profile } from '@trade-platform/ui-shared';

export const WEALTH_MANAGER = 'wealthManager';
export const CUSTODIAN_MANAGER = 'custodianManager';
export const FUND_MANAGER = 'fundManager';

// if firm exists on this object, it’s a wealth manager view
// if holding option exists, it’s a custodian view
// if fund sponsor exists, it’s a fund manager view
export const getUserManagerType = (profile: Profile) => {
    if (profile.firm) {
        return WEALTH_MANAGER;
    } else if (profile.holdingOption) {
        return CUSTODIAN_MANAGER;
    } else if (profile.fundSponsor) {
        return FUND_MANAGER;
    }

    return null;
};
