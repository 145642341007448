<label
    [data-testing]="config.refId! + '__label'"
    class="aix-form__label aix-label aix-label--light-grey u-mb18"
>
    {{ config.label }}
</label>

<div
    class="aix-slide-toggle-wrapper"
    [ngClass]="{ 'aix-form__control--required': templateData.fieldIsRequired }"
>
    <label class="aix-switch" data-testing="toggle-label">
        <input
            type="checkbox"
            [name]="controlName"
            [aixDynamicNextPendingField]="{ config: config }"
            [checked]="templateData.valueToRender"
            (change)="onToggle($event)"
            [data-testing]="config.refId + '__input'"
            [disabled]="templateData.fieldIsDisabled"
            [required]="templateData.fieldIsRequired"
            [class.aix-disabled]="templateData.fieldIsDisabled"
            [attr.tabindex]="templateData.fieldIsDisabled ? '-1' : ''"
        />
        <span
            class="aix-slider round br-primary__border-color br-primary__border-color--before"
            [class.br-primary__background-color--before]="!templateData.ctrlIsInvalid"
        ></span>
    </label>
    <label class="label-content">
        {{ templateData.valueToRender ? config.labelTrue : config.labelFalse }}
    </label>
</div>
