import { Component, input, model, output } from '@angular/core';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgFor, SlicePipe } from '@angular/common';

export interface AixCheckboxItem {
    label?: string;
    key?: any;
    queues?: AixCheckboxItemQueue[];
}

export interface AixCheckboxItemQueue {
    id: string;
    label: string;
}

@Component({
    selector: 'aix-checkbox-list',
    templateUrl: './aix-checkbox-list.html',
    styleUrls: ['./aix-checkbox-list.scss'],
    standalone: true,
    imports: [NgFor, AixDataTestingDirective, SlicePipe]
})
export class AixCheckboxListComponent {
    activeFilter = model<AixCheckboxItemQueue[]>([]);

    filters = input<AixCheckboxItemQueue[]>();

    onSelectFilter = output<AixCheckboxItemQueue[]>();

    selectFilter(filter: AixCheckboxItemQueue) {
        if (this.activeFilter() && this.activeFilter().some(active => active.id === filter.id)) {
            this.activeFilter.set(this.activeFilter().filter(active => active.id !== filter.id));
        } else {
            this.activeFilter.set(this.activeFilter() || []);
            this.activeFilter().push(filter);
        }
        this.onSelectFilter.emit(this.activeFilter());
    }

    isActiveFilter(key: string | undefined) {
        if (!this.activeFilter() || !key) {
            return false;
        }

        return this.activeFilter().reduce((acc, item) => {
            return acc || key === item.id;
        }, false);
    }
}
