<div class="aix-form__group-multiple-text">
    @for (item of _selection; track item; let last = $last) {
    <span
        class="aix-pill multiple-text"
        [class.error]="validateItem(item)"
        [class.disabled]="isDisabled()"
        [class.last-pill]="last"
    >
        {{ item }}&nbsp;@if (!validateItem(item)) {
        <span class="fa-regular fa-xmark u-ml8" (click)="removeChip(item)"></span>
        } @if (validateItem(item)) {
        <span
            class="fa-solid fa-exclamation u-ml8"
            style="color: #ef5350"
            (click)="removeChip(item)"
        ></span>
        }
    </span>
    }

    <input
        #multipleFieldRef
        type="text"
        tabindex="0"
        autocomplete="new-password"
        class="aix-form__control-multiple-text-input"
        data-testing="multiple-text-input"
        [placeholder]="placeholder()"
        (keydown)="onKeyDown($event)"
        (blur)="onBlur($event)"
        [disabled]="isDisabled()"
    />

    <label class="aix-form__label aix-label">{{ label() }}</label>
</div>
