<section [ngClass]="sectionClasses">
    <aix-header-section
        [sectionClasses]="'u-mb0'"
        [title]="'Supporting Documents'"
        data-testing="filelist-header"
    >
        <aix-button
            data-testing="upload-button"
            [isDisabled]="orderFileUpload.fileUploadOpen || isReadOnly"
            (click)="uploadDocuments()"
            [buttonType]="uploadButtonType"
            [buttonLabel]="'Upload'"
        >
        </aix-button>
    </aix-header-section>
    @if (uploadedFilesCount === 0 && !hasUploadedFiles && !orderFileUpload.fileUploadOpen) {
    <p class="u-mb8 u-mt16">There are no uploaded documents.</p>
    }

    <aix-file-upload #orderFileUpload [isReadOnly]="isReadOnly" [enableIgnore]="true">
    </aix-file-upload>
</section>
