@if (type === 'linear') {
<div class="aix-progress" [ngStyle]="{height: strokeWidth + 'px'}">
    <div
        class="aix-progress__bar"
        [ngStyle]="{width: progressN + '%', height: strokeWidth + 'px'}"
    ></div>
</div>
} @if (type === 'circular') {
<svg [attr.width]="sqSize" [attr.height]="sqSize" [attr.viewBox]="viewBox">
    <circle
        class="circle-background"
        [attr.cx]="sqSize / 2"
        [attr.cy]="sqSize / 2"
        [attr.stroke-width]="innerStrokeWidth + 'px'"
        [attr.r]="radius"
    ></circle>

    <circle
        class="circle-progress br-primary__stroke"
        [attr.cx]="sqSize / 2"
        [attr.cy]="sqSize / 2"
        [attr.stroke-linecap]="strokeLinecap"
        [attr.stroke-width]="strokeWidth + 'px'"
        [attr.transform]="'rotate(-90 ' + sqSize / 2 + ' ' + sqSize / 2 + ')'"
        [attr.r]="radius"
        [ngStyle]="{ 'stroke-dasharray': dashArray, 'stroke-dashoffset': dashOffset ? dashOffset : 0 }"
    ></circle>

    <text
        class="circle-text"
        x="50%"
        [attr.y]="subtextPosition"
        text-anchor="middle"
        [ngStyle]="{'font-size': fontSize / 4.1667 + 'em'}"
    >
        <tspan class="circle-text-number" data-testing="circle-progress-value">{{ progressN }}</tspan>
        <tspan class="circle-text-percent-sign" dy="-0.5em" style="font-size: 0.6em">%</tspan>
    </text>

    @if (subtext) {
    <text
        class="circle-text-complete"
        x="50%"
        [attr.y]="(sqSize / 2) + (fontSize * 2)"
        text-anchor="middle"
        [ngStyle]="{'font-size': fontSize / 10 + 'em'}"
    >
        Complete
    </text>
    }
</svg>
}
