import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    input,
    OnInit,
    Output
} from '@angular/core';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    BUTTON_TYPE
} from '@trade-platform/ui-components';

export interface AixProductCardButtonOption {
    label: string;
    value: string;
}

@Component({
    selector: 'aix-product-card-begin-button',
    templateUrl: 'product-card-begin-button.html',
    styleUrls: ['product-card-begin-button.scss'],
    imports: [AixDataTestingDirective, AixButtonComponent],
    standalone: true
})
export class AixProductCardBeginButtonComponent implements OnInit {
    label = input.required<string>();
    options = input.required<AixProductCardButtonOption[]>();

    selectedOption: AixProductCardButtonOption;
    isOpen = false;
    linkButtonType = BUTTON_TYPE.link;

    @Output() optionSelected: EventEmitter<AixProductCardButtonOption> = new EventEmitter();

    constructor(private ref: ElementRef) {}

    ngOnInit() {
        this.selectedOption = this.options()[0];
    }

    optionClicked(option: AixProductCardButtonOption) {
        this.selectedOption = option;
        this.optionSelected.emit(option);
    }

    @HostListener('document:click', ['$event'])
    onClickOutside($event: MouseEvent) {
        if (!this.ref.nativeElement.contains($event.target)) {
            this.isOpen = false;
        }
    }
}
