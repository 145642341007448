<ng-template
    #orderStepTemplate
    [aixTemplate]="'orderStep'"
    let-title="title"
    let-step="step"
    let-activeStep="activeStep"
>
    <div class="flex-center-center">
        <span
            data-testing="order-step-icon"
            class="order-step-icon u-color-tertiary-black"
            [ngClass]="{
                'order-step-icon__completed': activeStep > step,
                'br-primary__color': activeStep > step,
                'order-step-icon__current': activeStep === step,
                'br-primary--dark__color': activeStep === step
            }"
        >
            @if (activeStep <= step) {
            <span>{{ step }}</span>
            }
        </span>
        <h3
            data-testing="order-step-label"
            class="order-step-label u-color-tertiary-black"
            [ngClass]="{
                'br-primary__color': activeStep > step,
                'br-primary--dark__color': activeStep === step
            }"
        >
            {{ title }}
        </h3>
    </div>
</ng-template>
