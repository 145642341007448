import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { ProductExploreRecord } from '../../models/product/model';

@Component({
    selector: 'aix-product-image-card',
    templateUrl: './product-image-card.html',
    styleUrls: ['./product-image-card.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixButtonComponent, AixDataTestingDirective]
})
export class AixProductCardImageComponent {
    product: ProductExploreRecord;
    fundStructure = '';

    @Input() set productCard(productCard: ProductExploreRecord) {
        this.product = productCard;
    }
    @Output() cardSelected = new EventEmitter<ProductExploreRecord>();

    viewMoreButtonType = BUTTON_TYPE.primary;

    onCardClick(card: ProductExploreRecord) {
        this.cardSelected.emit(card);
    }
}
