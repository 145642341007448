<div class="aix-dropdown aix-blink">
    <div class="aix-dropdown__control aix-dropdown__control-standalone">
        <div class="country-code-dropdown">
            <div class="u-flex country-code-dropdown-selector" (click)="toggleOpenDropdown()">
                <div class="iti__flag flag u-mr8" [ngClass]="getSelectedFlagStyle()"></div>
                <span> +{{ getSelectedValue() }} </span>
                <span class="icon fa-solid fa-caret-down u-ml8 country-code-dropdown-caret"></span>
            </div>
            <input
                #searchFieldRef
                type="text"
                tabindex="0"
                autocomplete="new-password"
                class="country-code-dropdown-hidden-input"
                data-testing="dropdown-input"
                [value]="getSearchFieldValue()"
                (click)="openDropdown()"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
                (focus)="onFocus($event)"
                (blur)="onBlur($event)"
            />
        </div>
    </div>
    <ul
        #dropDownListRef
        class="aix-dropdown__list country-code-dropdown-list"
        data-testing="dropdown-list"
        [class.has-scroll]="hasScroll"
        (mousedown)="onMouseDownList()"
        (mouseup)="onMouseLeaveList()"
        (mouseout)="onMouseLeaveList()"
        (mouseleave)="onMouseLeaveList()"
        [hidden]="!isDropdownOpen"
    >
        @for (option of _getFilteredOptions(); track option) {
        <li
            class="aix-dropdown__item"
            [data-testing]="'dropdown-item-' + option?.name"
            [ngClass]="{ selected: option?.id === selected }"
            (click)="onClickOption($event, option)"
        >
            <div class="u-flex">
                <div class="iti__flag flag u-mr8" [ngClass]="getFlagStyle(option)"></div>
                <span> {{ option?.name }} </span>
                <span class="country-code-dropdown-extension"> +{{ option?.value }} </span>
            </div>
        </li>
        }
    </ul>
</div>
