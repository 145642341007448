<div
    class="loading"
    data-testing="aixLoader"
    [class.loading--relative]="relative()"
    [class.loading--transparent]="transparent()"
    [style.marginTop.px]="margin()?.top"
    [style.marginRight.px]="margin()?.right"
    [style.marginBottom.px]="margin()?.bottom"
    [style.marginLeft.px]="margin()?.left"
    [ngStyle]="style()"
>
    <span
        class="fa-solid fa-spinner fa-pulse br-primary__color"
        [style.font-size.px]="size()"
        [style.color]="negative() ? '#FFFFFF' : ''"
    >
    </span>
</div>
