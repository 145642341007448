<div class="aix-form__container aix-flex-grid__col">
    <ng-template #headerTemplate>
        <span class="-text--large u-pl16" [data-testing]="'label'">
            <span
                >{{ title() }}
                @if (selectedOptionLabel) {
                <span>– {{ selectedOptionLabel }}</span>
                }</span
            ><span>&nbsp;</span><span>*</span>
        </span>
    </ng-template>

    <ng-template #bodyTemplate>
        <div class="radio-custom-expansion-panel-content">
            @for (columnOfOptions of options();track trackByOptionArrayFn($index, columnOfOptions,
            valueField())) {
            <div class="radio-custom-column radio-custom-column__1of2">
                @for (option of columnOfOptions;track trackByOptionFn(i, option, valueField());let i
                = $index) {
                <div class="aix-flex-grid aix-form__grid">
                    <div class="aix-form__group">
                        @if (option.isTitle) {
                        <h5 class="radio-custom-title">
                            {{ option[labelField()] }}
                        </h5>
                        } @if (!option.isTitle) {
                        <div class="radio-custom-wrapper-full">
                            <label
                                class="radio-custom radio-custom-label-size"
                                [data-testing]="option[valueField()]"
                                [class.aix-disabled]="isDisabled() || option.disabled"
                            >
                                <input
                                    type="radio"
                                    [name]="name"
                                    [value]="option[valueField()]"
                                    [checked]="value() === option[valueField()]"
                                    (click)="onUserInput($event, option)"
                                    [class.aix-disabled]="isDisabled() || option.disabled"
                                    [attr.disabled]="isDisabled() || option.disabled ? '' : null"
                                    [attr.tabindex]="isDisabled() || option.disabled ? '-1' : ''"
                                />
                                <span class="radio-custom__indicator"></span>
                                <span class="radio-custom__label radio-custom__label--xl">
                                    <span data-testing="title">
                                        {{ labelField() ?? option[labelField()] }}
                                        @if (option[descriptionField()]) {
                                        <span class="radio-custom__sublabel">
                                            {{ option[descriptionField() ?? ''] }}
                                        </span>
                                        }
                                    </span>
                                </span>
                                @if (tooltipField() && option[tooltipField()]) {
                                <span
                                    aixTooltip
                                    [aixTooltipContent]="option[tooltipField()]"
                                    [data-testing]="option[valueField()] + '__tooltip'"
                                ></span>
                                }
                            </label>
                        </div>
                        }
                    </div>
                </div>
                }
            </div>
            }
        </div>
    </ng-template>

    <aix-expansion-panel
        #expansionPanelRef
        class="u-full-width"
        [isClosed]="!!value()"
        [data-testing]="'expansion-panel'"
        [headerTemplate]="headerTemplate"
        [bodyTemplate]="bodyTemplate"
        [arrowPosition]="'right'"
    >
    </aix-expansion-panel>
</div>
