<div class="aix-form__group aix-blink u-flex" [formGroup]="form">
    <aix-country-code-dropdown
        #countryCodeDropdownRef
        formControlName="countryCode"
        [initialValue]="_initialValue.countryCode"
        (valueChanges)="onUserChangesCountryCode($event)"
    >
    </aix-country-code-dropdown>

    @if (form.get('countryCode')?.value === '1') {
    <aix-phone
        formControlName="phoneNumber"
        data-testing="USPhone"
        [initialValue]="_initialValue.phoneNumber"
        [isValid]="!!form.get('phoneNumber')?.valid"
        [isRequired]="isRequired()"
        [isDisabled]="isDisabled()"
        (valueChanges)="onUserInput($event)"
    >
        <label class="aix-form__label aix-label">
            {{ label() }}
        </label>
    </aix-phone>
    } @if (form.get('countryCode')?.value !== '1') {
    <aix-number
        formControlName="phoneNumber"
        data-testing="nonUSPhone"
        [initialValue]="_initialValue.phoneNumber"
        [isValid]="!!form.get('phoneNumber')?.valid"
        [isRequired]="isRequired()"
        [isDisabled]="isDisabled()"
        (valueChanges)="onUserInput($event)"
    >
        <label class="aix-form__label aix-label">
            {{ label() }}
        </label>
    </aix-number>
    }
</div>
