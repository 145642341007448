<ul class="{{ ulClasses() }}">
    @for (option of options(); track option) {
    <li
        class="{{ liClasses() }}"
        [ngClass]="{
            selected: option === selectedOption()
        }"
        (click)="onClickOption(option)"
    >
        <ng-container *ngTemplateOutlet="optionTemplate(); context: { option: option }">
        </ng-container>
    </li>
    }
</ul>
