import { Component, input } from '@angular/core';
import { ColorStyle } from '@trade-platform/ui-utils';
import {
    AixBreadcrumb,
    AixBreadcrumbsComponent
} from '../aix-breadcrumbs/aix-breadcrumbs.component';
import { NgIf, NgStyle } from '@angular/common';

@Component({
    selector: 'aix-page-header',
    templateUrl: './aix-page-header.component.html',
    styleUrls: ['./aix-page-header.component.scss'],
    standalone: true,
    imports: [NgStyle, AixBreadcrumbsComponent, NgIf]
})
export class AixPageHeaderComponent {
    header = input<string>();
    headerStyle = input<ColorStyle>();
    subHeader = input<string>(); // NOTE: This displays on the bottom right using flex;
    breadcrumbs = input<AixBreadcrumb[]>([]);

    constructor() {}
}
