@if (fileUploadOpen) {
<div class="file-upload-container u-mt24" data-testing="file-upload-container">
    @if (!uploadCompleted) {
        <div>
            <div class="flex-between-center u-mb16">
                <span class="u-font-size--16 u-fw500">Upload</span>
                <span
                    class="fa-regular fa-xmark u-color-tertiary-black"
                    data-testing="close-file-upload"
                    (click)="fileUploadOpen = false"
                ></span>
            </div>
            @if (instructionalText) {
                <div class="u-mt16">
                    {{ instructionalText }}
                </div>
            }
            <aix-upload-document
                [uploadUrl]="uploadUrl"
                [type]="type"
                [multiple]="true"
                [isModal]="true"
                [fileSizeLimit]="fileSizeLimit"
                (filesSelected)="onFilesSelected($event)"
                (filesUploaded)="onFilesUploaded()"
                (uploadError)="onFileUploadError()"
            >
            </aix-upload-document>
        </div>
    } @else if (type === fileType.supplemental) {
        <div>
            <div class="flex-between-center u-mb16">
                <span class="u-font-size--16 u-fw500">Label</span>
                <span
                    class="fa-regular fa-xmark u-color-tertiary-black"
                    (click)="uploadCompleted = false; fileUploadOpen = false;"
                ></span>
            </div>
            <aix-label-uploaded-file-documents
                class="aix-form__container u-block"
                [documentTypes]="(storeFacade.documentTypesSuccess$ | async)"
                (filesUpdated)="onRelationsSaved()"
                (dismissModal)="uploadCompleted = false; fileUploadOpen = false;"
            >
            </aix-label-uploaded-file-documents>
        </div>
    } @else {
        <div>
            <div class="flex-between-center u-mb16">
                <span class="u-font-size--16 u-fw500">Label Forms</span>
                <span
                    class="fa-regular fa-xmark u-color-tertiary-black"
                    (click)="uploadCompleted = false; fileUploadOpen = false;"
                ></span>
            </div>
            <aix-label-uploaded-form-documents
                class="aix-form__container u-block"
                (filesUpdated)="onRelationsSaved()"
                (dismissModal)="uploadCompleted = false; fileUploadOpen = false;"
            >
            </aix-label-uploaded-form-documents>
        </div>
    }
</div>
} @if (!hideFilelist && type === fileType.supplemental) {
<aix-filelist
    #filelistRef
    [displayComponentHeader]="displayComponentHeader"
    [enableIgnore]="enableIgnore"
    [isReadOnly]="isReadOnly"
    (clickElem)="setRelations()"
    (uploadElem)="onUploadDocuments()"
    (editElem)="setRelations()"
    (removeElem)="removeFile($event)"
    (ignoreElem)="ignoreFile($event)"
    (unignoreElem)="unignoreFile($event)"
    [orderComments]="orderFileComments"
    [uploadCompleted]="uploadCompleted"
    [fileUploadOpen]="fileUploadOpen"
>
</aix-filelist>
}

<aix-modal
    #deleteModal
    modalType="confirmation"
    [modalZIndex]="6000"
    [modalTitle]="'Remove document?'"
    (optionSelected)="onRemoveUploadOptionSelected($event)"
>
    <ng-template #deleteTemplate>
        <p class="u-fw500">This document will be removed.</p>
        <p class="u-mb16">You can upload a new version of this document.</p>
    </ng-template>
</aix-modal>

<aix-modal
    #ignoreModal
    data-testing="ignore-document-modal"
    [modalZIndex]="6000"
    modalType="confirmation"
    [modalTitle]="'Ignore document?'"
    (optionSelected)="onIgnoreUploadOptionSelected($event)"
>
    <ng-template #ignoreTemplate>
        <p class="u-fw500">This document will be ignored.</p>
        <p class="u-mb16">
            You may be required to provide additional clarification for this order.
        </p>
    </ng-template>
</aix-modal>

<aix-modal
    #ignoreRequiredModal
    data-testing="ignore-document-required-modal"
    [modalZIndex]="6000"
    modalType="confirmation"
    [modalTitle]="'Ignore document?'"
    [buttonDisableFunction]="acceptIgnoreRequired"
    (optionSelected)="onIgnoreUploadOptionSelected($event)"
    (onCloseModal)="onCloseIgnoreUploadModal()"
>
    <ng-template #ignoreTemplate>
        <p class="u-fw500">
            You are choosing to ignore a document that is required to process this order.
        </p>
        <p class="u-mb16">Failure to provide this document may result in delays.</p>
        <p class="u-mb16">
            <aix-checkbox (valueChanges)="acknowledgeIgnoreRequired = $event"
                >I acknowledge that I am ignoring a required document</aix-checkbox
            >
        </p>
    </ng-template>
</aix-modal>
