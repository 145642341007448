<div class="search-box">
    <input
        #searchBox
        data-testing="search-box-input"
        type="text"
        placeholder="Filter"
        (keyup)="search($event.target)"
    />
    @if (!searchBox.value.trim()) {
    <i aria-hidden="true" class="icon fa-regular fa-filter"></i>
    } @if (searchBox.value.trim()) {
    <a class="u-mr16 br-link" (click)="clear()">Clear</a>
    }
</div>
