<div class="aix-form__group aix-blink">
    <input
        #inputField
        class="aix-form__control"
        type="text"
        autocomplete="new-password"
        inputmode="numeric"
        (keydown)="onKeyDown($event)"
        (input)="onUserInput($event)"
        (paste)="onUserInput($event)"
        (focus)="onFocusInput($event)"
        (blur)="onBlurInput($event)"
        [attr.placeholder]="config()?.placeholder ?? ''"
        [disabled]="isDisabled()"
        [required]="isRequired()"
        [class.aix-disabled]="isDisabled()"
        [class.aix-form__control--dirty]="isDirty()"
        [class.aix-form__control--invalid]="!isValid()"
        [class.aix-form__control--invalid-required]="isRequired() && !inputField.value"
        [class.aix-form__control--invalid-disabled]="isDisabled() && !isValid()"
        [attr.tabindex]="isDisabled() ? '-1' : ''"
        [data-testing]="config()?.refId!"
    />
    <label class="aix-form__label aix-label">
        <ng-content></ng-content>
    </label>
</div>

<div class="field-decoration">
    <ng-content select="[fieldDecoration]"></ng-content>
</div>
