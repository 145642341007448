<div [formGroup]="commentTypeForm">
    <aix-radio-group
        data-testing="commentType"
        formControlName="commentType"
        [options]="commentTags"
        [labelField]="'title'"
        [valueField]="'value'"
        [descriptionField]="'description'"
        [isValid]="!!commentTypeForm.get('commentType')?.valid"
        [isRequired]="true"
        (valueChanges)="onCommentTagChanged($event)"
    >
    </aix-radio-group>
</div>
