<aix-templates #aixTemplates (onInit)="aixTemplatesInitialized = true"></aix-templates>

<ng-template #formsStepContent>
    <div class="order-step-content">
        @if (totalUnresolvedFormComments >= 0) {
        <div class="order-step-form-progress">
            @if (!hasOrderFormComments()) {
            <aix-step-progress
                data-testing="order-progress"
                [maxSteps]="totalForms"
                [progress]="completedForms"
                [sqSize]="130"
                [strokeWidth]="10"
                [fontSize]="10"
                class="u-pr32"
                label="Complete"
                type="circular"
            >
            </aix-step-progress>
            } @if (hasOrderFormComments()) {
            <aix-step-progress
                data-testing="action-items-progress"
                [maxSteps]="totalFormComments"
                [progress]="totalFormComments - totalUnresolvedFormComments"
                [sqSize]="130"
                [strokeWidth]="10"
                [strokeColor]="'u-warning-orange--stroke'"
                [fontSize]="10"
                [showTotal]="false"
                class="u-pr32"
                [label]="(totalUnresolvedFormComments === 1) ? 'Action Item' : 'Action Items'"
                type="circular"
            >
            </aix-step-progress>
            }

            <p class="order-step-description">{{ getOrderStepDescription() }}</p>
        </div>
        }

        <div class="order-step-forms u-mt40">
            <aix-header-section [title]="'Required Forms'"></aix-header-section>
            <aix-formlist
                [forms]="(storeFacade.orderSuccess$ | async)?.forms"
                [orderFormComments]="orderComments"
                [clickable]="true"
                (clickElem)="goToForm($event)"
            ></aix-formlist>
        </div>

        <div
            class="order-step-form-row order-step-form-row__actions order-step-form-row__actions__complete u-mt24"
        >
            @if (unfinishedForms && !hasOrderFormComments()) {
            <aix-button
                [isDisabled]="isReadOnly"
                (click)="goToFirstUnfinishedForm()"
                data-testing="complete-forms-button"
                [buttonType]="primaryButtonType"
                [buttonLabel]="'Continue'"
            ></aix-button>
            } @if (!unfinishedForms && (storeFacade.orderSuccess$ | async)?.status ===
            orderStatus.draft) {
            <aix-button
                (click)="completeForms()"
                [buttonType]="primaryButtonType"
                data-testing="continue-button"
                [buttonLabel]="'Continue'"
                [isDisabled]="(storeFacade.orderFormsCompleteRemoteData$ | async | isInProgress) || hasOrderFormComments() || isReadOnly"
            ></aix-button>
            } @if (unfinishedForms && hasOrderFormComments()) {
            <aix-button
                [isDisabled]="isReadOnly"
                (click)="goToFirstUnfinishedForm()"
                data-testing="edit-button"
                [buttonLabel]="'Edit Order'"
                [buttonType]="primaryButtonType"
            ></aix-button>
            }
        </div>
    </div>
</ng-template>

@if (aixTemplatesInitialized) {

<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(1, 1)"
    [bodyTemplate]="formsStepContent"
    [isClosed]="storeFacade.orderRemoteData$ | async | isInProgress"
    [isDisabled]="false"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(2, 1)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(3, 1)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(4, 1)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(5, 1)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'right'"
>
</aix-expansion-panel>

}
