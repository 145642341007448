import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { TradePlatformWebAppComponent } from './app/app.component';
import { DynamicFormsConfig, DynamicFormsModule } from '@trade-platform/dynamic-forms';
import { AixUiComponentsModule } from '@trade-platform/ui-components';
import { DateFnsModule } from 'ngx-date-fns';
import { Angulartics2Module } from 'angulartics2';
import { AgGridModule } from 'ag-grid-angular';
import { FullRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { RemoteDataModule } from 'ngx-remotedata';
import { AppRoutingModule } from './app/routes';
import { JwtModule } from '@auth0/angular-jwt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { OrderFormCommentsPipe } from '@advisor-ui/orders';
import { bootstrapApplication, BrowserModule, Title } from '@angular/platform-browser';
import {
    AdvisorUiAppServicesModule,
    AixAuthService,
    AixChangesService,
    AixLoginApiService,
    AixRedirectService,
    AixRouteService,
    CanLoadByRole,
    ComponentDeactivateGuard,
    ComponentsStoreFacade,
    ExternalDocusignRedirectGuard,
    FeatureGuard,
    InactivityInterceptor,
    InactivityService,
    KeepAliveService,
    LoginMachine,
    LogoutGuard,
    LogoutService,
    ProfileChecklistGuard,
    ProfileGuard,
    ProfileStoreFacade,
    SentryErrorHandler,
    UnauthorizedInterceptor
} from '@advisor-ui/app-services';
import { ActionReducer, MetaReducer, ScannedActionsSubject, StoreModule } from '@ngrx/store';
import {
    ADVISOR_UI_REDUCER_TOKEN,
    AixDispatcher,
    AixEffectActions,
    AixRegisterService,
    AixSharedLibConfig,
    AixSharedLibModule,
    AixUsersService,
    Auth,
    AuthEffects,
    BrandingEffects,
    BrandingService,
    CacheInterceptor,
    getBaseReducers,
    HoldingOptionService,
    InvestorEffects,
    InvestorService,
    MaintenanceOrderService,
    OrderEffects,
    OrderService,
    OutagesEffects,
    OutagesService,
    PrivacyPolicyEffects,
    PrivacyPolicyService,
    ProductEffects,
    ProductMaterialsEffects,
    ProductService,
    ProfileEffects,
    RegisterEffects,
    resetReducer,
    SentryCatchallErrorsEffect,
    TermsEffects,
    TermsService,
    TitleService
} from '@trade-platform/ui-shared';
import { getFromStorage } from '@trade-platform/ui-utils';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// JWT
export function getAccessToken(): string | null {
    const auth = getFromStorage<Auth>('auth');

    return auth ? auth.accessToken : null;
}

export function whiteListedDomains() {
    return [environment.apiDomain, environment.adminApiDomain];
}

export const JWT_CONFIG = {
    tokenGetter: getAccessToken,
    allowedDomains: whiteListedDomains()
};
// End JWT

export const metaReducers: MetaReducer<any>[] = [resetReducer];
if (environment.enableDebugging) {
    metaReducers.push(debug);
}

const devImports = [];
const enableDebugging = environment.enableDebugging || getFromStorage('enableDebugging');
if (enableDebugging) {
    devImports.push(
        StoreDevtoolsModule.instrument({
            logOnly: !environment.enableDebugging
        })
    );
}

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        const state2 = reducer(state, action);
        if (action.type !== '@ngrx/store-devtools/recompute') {
            console.groupCollapsed(action.type);
            console.log('Action: ', action);
            console.log('State: ', state2);
            console.groupEnd();
        }
        return state2;
    };
}

const APP_PROVIDERS = [
    AixEffectActions,
    { provide: ScannedActionsSubject, useClass: AixDispatcher },
    AixAuthService,
    AixChangesService,
    AixRouteService,
    Title,
    TitleService,
    CanLoadByRole,
    AixUsersService,
    AixRegisterService,
    HoldingOptionService,
    ProductService,
    OrderService,
    MaintenanceOrderService,
    InvestorService,
    TermsService,
    PrivacyPolicyService,
    OutagesService,
    { provide: ADVISOR_UI_REDUCER_TOKEN, useFactory: getBaseReducers },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    LogoutGuard,
    ProfileGuard,
    FeatureGuard,
    ProfileChecklistGuard,
    ComponentDeactivateGuard,
    ExternalDocusignRedirectGuard,
    ProfileStoreFacade,
    ComponentsStoreFacade,
    AixLoginApiService,
    LoginMachine,
    BrandingService,
    KeepAliveService,
    AixRedirectService,
    InactivityService,
    OrderFormCommentsPipe,
    LogoutService
];
const AIX_HTTP_INTERCEPTORS = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: UnauthorizedInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CacheInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: InactivityInterceptor,
        multi: true
    }
];

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(TradePlatformWebAppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            FormsModule,
            ReactiveFormsModule,
            AixSharedLibModule.forRoot({
                environment: environment
            } as AixSharedLibConfig),
            AdvisorUiAppServicesModule.forRoot({
                environment: environment
            }),
            JwtModule.forRoot({
                config: JWT_CONFIG
            }),
            AppRoutingModule,
            RemoteDataModule,
            StoreModule.forRoot(ADVISOR_UI_REDUCER_TOKEN, {
                metaReducers,
                // TODO: turn flags to true when possible
                runtimeChecks: {
                    strictStateSerializability: false,
                    strictActionSerializability: false,
                    // FIXME: turn flag to true
                    strictStateImmutability: false,
                    // FIXME: turn flag to true
                    strictActionImmutability: false,
                    // FIXME: turn flag to true
                    strictActionWithinNgZone: false,
                    // FIXME: turn flag to true
                    strictActionTypeUniqueness: false
                }
            }),
            EffectsModule.forRoot([
                ProfileEffects,
                RegisterEffects,
                ProductEffects,
                AuthEffects,
                OrderEffects,
                InvestorEffects,
                TermsEffects,
                OutagesEffects,
                PrivacyPolicyEffects,
                SentryCatchallErrorsEffect,
                BrandingEffects,
                ProductMaterialsEffects
            ]),
            StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
            ...devImports,
            AgGridModule,
            Angulartics2Module.forRoot(),
            DateFnsModule.forRoot(),
            // AIX UI Components
            AixUiComponentsModule,
            DynamicFormsModule.forRoot({
                environment: environment
            } as DynamicFormsConfig)
        ),
        APP_PROVIDERS,
        AIX_HTTP_INTERCEPTORS,
        provideHttpClient(withInterceptorsFromDi())
    ]
}).catch(err => console.error(err));
