import { Language } from './language.interface';

export const languageBase: Language = {
    startOrder: {
        header: 'New Order',
        title: 'New Buy Order',
        formName: 'Start Order Form'
    },
    purchaseStatus: {
        header: 'All Orders',
        text: 'Orders',
        tabName: 'buy',
        allOrdersFilters: 'All Orders',
        allActiveOrdersFilter: 'All Active Orders',
        custodianLabel: 'Custodian',
        hideAdvisorColumn: false,
        tabs: {
            first: {
                label: 'Buy',
                tabName: 'buy'
            },
            second: {
                label: 'Maintenance',
                tabName: 'maintenance'
            },
            third: {
                label: 'Product Materials',
                tabName: 'productMaterials'
            }
        }
    },
    maintenanceStatus: {
        header: 'All Orders',
        text: 'Orders',
        tabName: 'maintenance',
        allMaintenanceOrdersFilter: 'All Orders',
        allActiveMaintenanceOrdersFilter: 'All Active Orders',
        tabs: {
            first: {
                label: 'Buy',
                tabName: 'buy'
            },
            second: {
                label: 'Maintenance',
                tabName: 'maintenance'
            },
            third: {
                label: 'Product Materials',
                tabName: 'productMaterials'
            }
        }
    },
    overview: {
        header: 'Dashboard',
        tabName: 'buy',
        tabs: {
            first: {
                label: 'Buy',
                tabName: 'buy',
                text: 'Choose a product to begin investing',
                ordersInProgressText: 'orders in progress'
            },
            second: {
                label: 'Maintenance',
                tabName: 'maintenance',
                text: 'Choose a product to begin account maintenance.'
            }
        }
    },
    orderOverview: {
        breadcrumbs: {
            buy: 'Buy',
            maintenance: 'Maintenance',
            sell: 'Sell'
        },
        custodianLabel: 'Custodian',
        hideFilelistEntityName: false,
        hideFilelistDocumentOwner: false,
        hideFormDocumentOwner: false,
        hideUploadDescriptionOwner: false,
        settlementDetails: {
            hideTradeDate: false,
            hideSettlementDate: false,
            hideAmount: false,
            hideShares: false,
            hidePrice: false,
            hideFundAccountNumber: false,
            hideProcessingNotes: false
        },
        maintenanceSettlementDetails: {
            hideTradeDate: true,
            hideSettlementDate: false,
            hideProcessingNotes: false,
            hideFundAccountNumber: false
        }
    },
    accountsList: {
        custodianLabel: 'Custodian',
        hideProductsColumn: false,
        hideValueColumn: false,
        hideFirmColumn: false,
        hideAdvisorColumn: false,
        hideSourceColumn: false
    },
    productList: {
        hideCompanyNameColumn: false
    },
    accountOverview: {
        custodianLabel: 'Custodian',
        hideUsersFirmColumn: false
    }
};
