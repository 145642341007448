import { Component, forwardRef, input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { countries, OneOfModel, states } from '@trade-platform/ui-shared';
import { isNonEmptyString } from '@trade-platform/ui-utils';
import { isArray } from 'lodash-es';
import { NgIf } from '@angular/common';
import {
    AixDropdownComponent,
    AixInputComponent,
    AixNextPendingFieldDirective,
    AixZipComponent
} from '@trade-platform/ui-components';

@Component({
    selector: 'aix-address',
    templateUrl: './address.html',
    styleUrls: ['./address.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AixAddressGroupComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: AixAddressGroupComponent
        }
    ],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        AixDropdownComponent,
        AixInputComponent,
        AixZipComponent,
        AixNextPendingFieldDirective
    ]
})
export class AixAddressGroupComponent implements ControlValueAccessor, OnInit, OnDestroy {
    title = input<string>();
    includePOBox = input<boolean>();
    isRequired = input<boolean>(true);
    isDisabled = input<boolean>(false);
    omitCountry = input<boolean>(false);
    prefix = input<string>('');

    isDestroying = false;
    states: OneOfModel[] = states;
    countries: OneOfModel[] = countries;
    form: UntypedFormGroup;

    subscriptions: Subscription[] = [];

    private _onChange: (value: any | null | undefined) => void;
    private _destroy$: Subject<void> = new Subject<void>();

    constructor(private _fb: UntypedFormBuilder) {
        this._createFormGroup();
        this._setupObservables();
    }

    ngOnInit() {
        this.isDestroying = false;
        if (!this.isRequired()) {
            this.form.get('country')?.clearValidators();
            this.form.get('street')?.clearValidators();
            this.form.get('city')?.clearValidators();
            this.form.get('state')?.clearValidators();
            this.form.get('zip')?.clearValidators();
            this.form.updateValueAndValidity();
        }
        this.patchForm();
    }

    patchForm() {
        const data = {};
        this.form.patchValue(data);
    }

    ngOnDestroy() {
        this.isDestroying = true;
        this.form.updateValueAndValidity();
        this.subscriptions.forEach(s => s.unsubscribe());

        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

    validate(ctrl: UntypedFormControl) {
        return this.form.valid || this.isDestroying ? null : { invalid: true };
    }

    writeValue(value: any | null | undefined): void {
        if (!value) {
            this.clearField('country');
            this.clearField('street');
            this.clearField('city');
            this.clearField('state');
            this.clearField('zip');
            return;
        }

        if (value.country && isNonEmptyString(value.country)) {
            let countryOption;
            if (value.country.length == 2) {
                countryOption = countries.find(c => c.id === value.country);
            } else {
                countryOption = countries.find(c => c.name === value.country);
            }
            if (countryOption) {
                value.country = [countryOption];
            }
        }
        if (value.state && isNonEmptyString(value.state) && value.state.length == 2) {
            const stateOption = states.find(s => s.id === value.state);

            if (stateOption) {
                if (value.country && value.country[0].id !== 'US') {
                    value.state = stateOption.name;
                } else {
                    value.state = [stateOption];
                }
            }
        }
        this.form.patchValue(value);
        this.form.updateValueAndValidity();
    }

    registerOnChange(fn: (value: any | null | undefined) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        // TODO: implement this method
        // throw new Error('registerOnTouched not implemented');
    }

    clearField(path: string) {
        this.form.get(path)?.setValue(null);
    }

    onCountryChange(e: any) {
        setTimeout(() => {
            if (e && e.length === 1 && e[0].id !== 'US' && isArray(this.form?.value?.state)) {
                this.clearField('state');
            } else if (
                e &&
                (e.length === 0 || e[0]?.id === 'US') &&
                !isArray(this.form?.value?.state)
            ) {
                this.clearField('state');
            }
            this.form.updateValueAndValidity();
        }, 100);
    }

    private _createFormGroup() {
        const formControls: any = {
            street: this._fb.control(null, [Validators.required]),
            city: this._fb.control(null, [Validators.required]),
            state: this._fb.control(null, [Validators.required]),
            zip: this._fb.control(null, [Validators.required])
        };
        if (!this.omitCountry()) {
            formControls.country = this._fb.control(null, [Validators.required]);
        }
        this.form = this._fb.group(formControls);
    }

    private _setupObservables() {
        this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(value => {
            if (this._onChange) {
                this._onChange(value);
            }
        });
    }
}
