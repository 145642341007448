import { DynamicFormComponent } from '@trade-platform/dynamic-forms';
import { delay, first, repeat, throttleTime } from 'rxjs/operators';
import { KeepAliveService } from '../services/keep-alive-service';
import { UntypedFormGroup } from '@angular/forms';

/**
 * Update auth0 session every minute when performing changes in forms
 *
 * @param form DynamicForm ref or UntypedFormGroup
 * @param keepAliveService KeepAliveService
 */
export const checkSessionOnFormChange = (
    form: DynamicFormComponent | UntypedFormGroup,
    keepAliveService: KeepAliveService
) =>
    // Call keep live when there's a change in the form in the last minute.
    // Five minutes later of the last change in the form the repeat()
    // will call again the keep alive automatically
    form.valueChanges.pipe(throttleTime(60 * 1000)).subscribe(_ => {
        keepAliveService
            .keepAlive()
            .pipe(first(), delay(5 * 60 * 1000), repeat(2))
            .subscribe();
    });
