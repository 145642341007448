<aix-header-section
    class="u-block u-mt40"
    [title]="'Pre-Signature Review'"
    [data-testing]="'pre-signature-review-header'"
>
    @if (order?.status !== orderStatus.readyForPresignatureReview || inPreviewSend) {
    <aix-button
        [buttonType]="linkButtonType"
        [buttonLabel]="'Cancel Review'"
        data-testing="cancelReviewButton"
        (click)="cancelReview()"
    >
    </aix-button>
    }
</aix-header-section>

@if (order?.status === orderStatus.readyForPresignatureReview && !inPreviewSend) {
<div>
    @if (!isRequired) {
    <p>Pre-Signature Review is available for this order. Select an option to continue.</p>
    } @if (isRequired) {
    <p>Pre-Signature Review is required for this order.</p>
    }

    <div class="flex-left-top u-mt8">
        <form [formGroup]="deliveryForm">
            <aix-radio-group
                formControlName="preSignatureReviewMethod"
                class="radio-order-process"
                [options]="preSignatureReviewMethods"
                [labelField]="'title'"
                [valueField]="'value'"
                [descriptionField]="'description'"
                [isValid]="!!deliveryForm.get('preSignatureReviewMethod')?.valid"
                [isRequired]="true"
            >
            </aix-radio-group>
        </form>
    </div>
</div>
} @if ((order?.status === orderStatus.readyForPresignatureReview && inPreviewSend) || (order?.status
=== orderStatus.pendingPresignatureReview) || (order?.status ===
orderStatus.presignatureReviewComplete && order?.presignatureReview?.steps?.length)) {
<div>
    @for (step of order.presignatureReview?.steps; track step) {
    <div class="investor__element" data-testing="preReviewElement">
        <div class="investor__info flex-between-top">
            <div class="u-flex">{{ step.approvalName }}</div>
            <div class="u-flex flex-col" data-testing="preReviewStatus">
                @if (step.status === 'not yet sent') {
                <p class="u-text-right u-color-tertiary-black">Not yet sent.</p>
                } @if (step.status === 'pending') {
                <p class="u-green u-text-right">
                    <span class="fa-regular fa-paper-plane u-mr8"></span>Sent
                </p>
                } @if (step.status === 'approved') {
                <p class="u-green u-text-right">
                    <span class="fa-regular fa-check u-mr8"></span>Approved
                </p>
                } @if (step.status !== 'not yet sent') {
                <p class="u-font-size--12 u-color-tertiary-black u-text-right">
                    <span>{{ step.updatedAt | date:'MM/dd/yyyy h:mm a' }}</span>
                </p>
                }
            </div>
        </div>
    </div>
    }
</div>
}
