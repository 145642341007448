import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    AdminMarkOrderCompleteAction,
    AdminMarkOrderCompleteFailureAction,
    AdminMarkOrderCompleteSuccessAction,
    AdminPushUpdatedFormToOrderAction,
    AdminPushUpdatedFormToOrderFailureAction,
    AdminPushUpdatedFormToOrderSuccessAction,
    AdminResubmitOrderAction,
    AdminResubmitOrderFailureAction,
    AdminResubmitOrderSuccessAction,
    OrderAdminActionTypes,
    OrderNigoToNeedsClarificationAction,
    OrderNigoToNeedsClarificationFailureAction,
    OrderNigoToNeedsClarificationSuccessAction,
    OrderToWetsignAction,
    OrderToWetsignFailureAction,
    OrderToWetsignSuccessAction
} from './actions';
import { OrderAdminService } from './service';

@Injectable()
export class OrderAdminEffects {
    constructor(private actions$: Actions, private service: OrderAdminService) {}

    convertOrderNigoToNeedsClarification$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderNigoToNeedsClarificationAction>(
                OrderAdminActionTypes.ORDER_NIGO_TO_NEEDS_CLARIFICATION
            ),
            switchMap(action =>
                this.service.convertOrderNigoToNeedsClarification(action.payload.orderId).pipe(
                    map(_ => new OrderNigoToNeedsClarificationSuccessAction()),
                    catchHttpError(error =>
                        of(new OrderNigoToNeedsClarificationFailureAction({ error }))
                    )
                )
            )
        )
    );

    convertOrderToWetsign$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderToWetsignAction>(OrderAdminActionTypes.ORDER_TO_WETSIGN),
            switchMap(action =>
                this.service.convertOrderToWetsign(action.payload.orderId).pipe(
                    map(_ => new OrderToWetsignSuccessAction()),
                    catchHttpError(error => of(new OrderToWetsignFailureAction({ error })))
                )
            )
        )
    );

    adminMarkOrderComplete$ = createEffect(() =>
        this.actions$.pipe(
            ofType<AdminMarkOrderCompleteAction>(OrderAdminActionTypes.ORDER_MARK_COMPLETE),
            switchMap(action =>
                this.service.adminMarkOrderComplete(action.payload.orderId).pipe(
                    map(_ => new AdminMarkOrderCompleteSuccessAction()),
                    catchHttpError(error => of(new AdminMarkOrderCompleteFailureAction({ error })))
                )
            )
        )
    );

    adminResubmitOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType<AdminResubmitOrderAction>(OrderAdminActionTypes.ORDER_RESUBMIT),
            switchMap(action =>
                this.service.adminResubmitOrder(action.payload.orderId).pipe(
                    map(_ => new AdminResubmitOrderSuccessAction()),
                    catchHttpError(error => of(new AdminResubmitOrderFailureAction({ error })))
                )
            )
        )
    );

    adminPushUpdatedFormToOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType<AdminPushUpdatedFormToOrderAction>(
                OrderAdminActionTypes.ORDER_PUSH_UPDATED_FORM
            ),
            switchMap(action =>
                this.service
                    .adminPushUpdatedFormToOrder(action.payload.orderId, action.payload.formId)
                    .pipe(
                        map(_ => new AdminPushUpdatedFormToOrderSuccessAction()),
                        catchHttpError(error =>
                            of(new AdminPushUpdatedFormToOrderFailureAction({ error }))
                        )
                    )
            )
        )
    );
}
