import { HttpParams } from '@angular/common/http';
import { objectHasValue } from '@trade-platform/ui-utils';
import { JsonDecoder } from 'ts.data.json';

export type PaginationOptions<F> = {
    page: number;
    per: number;
    filters?: { [key in keyof F]: string };
    arrayFilters?: { [key in keyof F]: string[] };
};
export type Page<T> = {
    data: T[];
    totalCount: number;
    totalPages: number;
};
export const paginationDecoder = <T>(
    dataDecoder: JsonDecoder.Decoder<T>,
    totalCount: number,
    totalPages: number,
    decoderName: string
) =>
    JsonDecoder.object<Page<T>>(
        {
            data: JsonDecoder.array<T>(dataDecoder, `${decoderName}[]`),
            totalCount: JsonDecoder.constant(totalCount),
            totalPages: JsonDecoder.constant(totalPages)
        },
        `Page<${decoderName}>`,
        {
            data: 'body'
        }
    );

export const createPaginationHttpParams = <F>(opts: PaginationOptions<F>): HttpParams => {
    let params = new HttpParams()
        .append('page', opts.page.toString())
        .append('per', opts.per.toString());
    if (opts.filters !== undefined) {
        const filters = opts.filters;
        Object.keys(opts.filters)
            .filter(
                filter =>
                    filters.hasOwnProperty(filter) &&
                    filters[filter as keyof F] !== '' &&
                    objectHasValue(filters[filter as keyof F])
            )
            .forEach(filter => {
                params = params.append(filter, filters[filter as keyof F]);
            });
    }
    if (opts.arrayFilters !== undefined) {
        const arrayFilters = opts.arrayFilters;
        Object.keys(opts.arrayFilters)
            .filter(
                filter =>
                    arrayFilters.hasOwnProperty(filter) &&
                    arrayFilters[filter as keyof F].length > 0 &&
                    objectHasValue(arrayFilters[filter as keyof F])
            )
            .forEach(filter => {
                arrayFilters[filter as keyof F].forEach(filterElem => {
                    params = params.append(filter, filterElem);
                });
            });
    }
    return params;
};
