<div class="navbar br-navbar--bg__background-color -expanded">
    <div class="container u-full-width">
        <img class="navbar__logo" [src]="logo" />
    </div>
</div>

<h4 class="title br-navbar--bg__background-color br-navbar--text__color">Register Your Account</h4>

@if (states | anyIsInProgress) {
<aix-loading></aix-loading>
} @if (!duplicateSubmission) {
<aix-error-box [states]="states"></aix-error-box>
} @if (!formSent) {
<section class="u-mt64">
    <h3>
        Your email has been verified. To complete your account registration, please provide your
        information below.
    </h3>

    <p class="u-mt16">
        The information collected is used solely for the purpose of establishing your profile. All
        required fields are marked with an asterisk (*).
    </p>

    <form [formGroup]="form" class="u-mt24">
        <!-- SEI PROCESS -->
        @if (registerInfo.type === 'sei') {
        <div>
            <div class="header--section">
                <div class="--heading">
                    <h3>Register Your Account</h3>
                </div>
            </div>

            <h5>Your Details</h5>

            <div class="aix-form__group u-mt16">
                <aix-input
                    formControlName="email"
                    [isRequired]="false"
                    [isDisabled]="true"
                    [config]="{ refId: 'email' }"
                    [isValid]="form.get('email')?.valid"
                >
                    Email Address
                </aix-input>
            </div>

            <div class="aix-form__group u-mt16">
                <aix-input
                    formControlName="firstName"
                    [isRequired]="true"
                    [isDisabled]="false"
                    [placeholder]="'Your first name'"
                    [config]="{ refId: 'firstName' }"
                    [isValid]="form.get('firstName')?.valid"
                >
                    First Name
                </aix-input>
            </div>

            <div class="aix-form__group u-mt16">
                <aix-input
                    formControlName="lastName"
                    [isRequired]="true"
                    [isDisabled]="false"
                    [placeholder]="'Your last name'"
                    [config]="{ refId: 'lastName' }"
                    [isValid]="form.get('lastName')?.valid"
                >
                    Last Name
                </aix-input>
            </div>

            <div class="aix-form__group u-mt16">
                <aix-input
                    formControlName="firmName"
                    [isRequired]="true"
                    [isDisabled]="false"
                    [placeholder]="'Your firm name'"
                    [config]="{ refId: 'firmName' }"
                    [isValid]="form.get('firmName')?.valid"
                >
                    Firm Name
                </aix-input>
            </div>

            <div class="header--section">
                <div class="--heading">
                    <h3>Collective Investment Trust Information</h3>
                </div>
            </div>

            <h5>Collective Investment Trust Details</h5>

            <div class="aix-form__group u-mt16">
                <aix-dropdown
                    #collectiveDropdownRef
                    class="standalone-dropdown aix-form__control--required"
                    [class.aix-form__control--dirty]="collectiveDropdownRef.isDirty"
                    [class.aix-form__control--invalid]="
                        !collectiveDropdownRef.isDirty ||
                        (collectiveDropdownRef.isDirty &&
                            (form.value.collectiveTrustNames?.length >= 0
                                ? !form.value.collectiveTrustNames?.length
                                : !form.value.collectiveTrustNames))
                    "
                    [class.aix-form__control--invalid-required]="
                        !collectiveDropdownRef.isDirty ||
                        (collectiveDropdownRef.isDirty &&
                            (form.value.collectiveTrustNames?.length >= 0
                                ? !form.value.collectiveTrustNames?.length
                                : !form.value.collectiveTrustNames))
                    "
                    formControlName="collectiveTrustNames"
                    labelField="name"
                    valueField="id"
                    placeholder="Select the name(s) of the collective investment trust that your are looking to access."
                    [options]="seiProducts"
                    [multiple]="true"
                >
                </aix-dropdown>
                <label class="aix-form__label aix-label">Collective Investment Trust Name(s)</label>
            </div>
            <div class="aix-form__group u-mt16">
                <aix-textarea
                    formControlName="additionalInformation"
                    data-testing="additionalInformation"
                    [height]="138"
                    [config]="{ placeholder: 'Include additional information' }"
                    [isRequired]="false"
                    [isDisabled]="false"
                    [isValid]="true"
                >
                    Additional Information
                </aix-textarea>
            </div>
        </div>
        }

        <div [hidden]="hideAccountType || registerInfo.type === 'sei'">
            <div class="header--section">
                <div class="--heading">
                    <h3>Register Your Account</h3>
                </div>
            </div>

            <h5>How will you use this account?*</h5>

            <aix-radio-group
                formControlName="accountType"
                [options]="accountTypes"
                [labelField]="'title'"
                [valueField]="'value'"
                [isValid]="!!form.get('accountType')?.valid"
                [isRequired]="true"
                (valueChanges)="onClickAccountType($event)"
            >
            </aix-radio-group>
        </div>

        @if (form.value?.accountType && registerInfo.type !== 'sei') {
        <div>
            <div class="header--section u-mt24">
                <div class="--heading">
                    @if (form.value?.accountType === 'personal') {
                    <h3>Investor Information</h3>
                    } @if (form.value?.accountType === 'financial') {
                    <h3>Financial Professional Information</h3>
                    }
                </div>
            </div>

            <h5>Your {{ form.value?.accountType !== 'personal' ? 'professional ' : '' }}details</h5>

            <div class="aix-form__group u-mt16">
                <aix-input
                    formControlName="email"
                    [isRequired]="false"
                    [isDisabled]="true"
                    [config]="{ refId: 'email' }"
                    [isValid]="form.get('email')?.valid"
                >
                    {{ form.value?.accountType !== 'personal' ? 'Professional' : '' }} Email Address
                </aix-input>
            </div>

            <div class="aix-form__group u-mt16">
                <aix-input
                    formControlName="firstName"
                    [isRequired]="true"
                    [isDisabled]="false"
                    [placeholder]="'Your first name'"
                    [config]="{ refId: 'firstName' }"
                    [isValid]="form.get('firstName')?.valid"
                >
                    First Name
                </aix-input>
            </div>

            <div class="aix-form__group u-mt16">
                <aix-input
                    formControlName="lastName"
                    [isRequired]="true"
                    [isDisabled]="false"
                    [placeholder]="'Your last name'"
                    [config]="{ refId: 'lastName' }"
                    [isValid]="form.get('lastName')?.valid"
                >
                    Last Name
                </aix-input>
            </div>

            @if (form.value?.accountType === 'financial') {
            <div>
                <div class="aix-form__group u-mt16">
                    <aix-input
                        formControlName="firmName"
                        [isRequired]="true"
                        [isDisabled]="false"
                        [placeholder]="'Your firm name'"
                        [config]="{ refId: 'firmName' }"
                        [isValid]="form.get('firmName')?.valid"
                    >
                        Firm Name
                    </aix-input>
                </div>

                <div class="aix-form__group u-mt16">
                    <aix-input
                        formControlName="firmCRDNumber"
                        [isRequired]="true"
                        [isDisabled]="false"
                        [placeholder]="'Your firm CRD Number'"
                        [config]="{ refId: 'firmCRDNumber' }"
                        [isValid]="form.get('firmCRDNumber')?.valid"
                    >
                        Firm CRD Number
                    </aix-input>

                    <p class="u-mt8">
                        Not sure about your firm CRD number? Please check BrokerCheck:
                        <a href="https://brokercheck.finra.org" target="_blank" class="br-link"
                            >https://brokercheck.finra.org</a
                        >
                    </p>
                </div>

                <div class="aix-form__group u-mt16">
                    <aix-dropdown
                        #roleDropdownRef
                        class="standalone-dropdown class.aix-form__control--required"
                        [class.aix-form__control--invalid-required]="
                            roleDropdownRef.isDirty &&
                            (form.value.role?.length >= 0
                                ? !form.value.role?.length
                                : !form.value.role)
                        "
                        [class.aix-form__control--invalid]="
                            roleDropdownRef.isDirty &&
                            (form.value.role?.length >= 0
                                ? !form.value.role?.length
                                : !form.value.role)
                        "
                        formControlName="role"
                        labelField="name"
                        valueField="id"
                        placeholder="Select the option that best describes your role"
                        [options]="roles"
                        (ngModelChange)="onClickRole($event)"
                    >
                    </aix-dropdown>
                    <label class="aix-form__label aix-label">Your Primary Role</label>
                </div>
            </div>
            }
        </div>
        } @if (form.value?.role && form.value?.accountType === 'financial') {
        <div>
            @if (role === 'advisor' || role === 'both') {
            <div class="aix-form__group u-mt16">
                <aix-input
                    formControlName="crdNumber"
                    [isRequired]="true"
                    [isDisabled]="false"
                    [placeholder]="'Your CRD Number'"
                    [config]="{ refId: 'crdNumber' }"
                    [isValid]="form.get('crdNumber')?.valid"
                >
                    Your CRD Number
                </aix-input>
            </div>
            } @if (role === 'assistant' || role === 'both') {
            <div>
                <div class="header--section u-mt24">
                    <div class="--heading">
                        <h3>Additional Advisor Information</h3>
                    </div>
                </div>

                <p class="u-mt16">
                    Please provide the information for any advisor(s) that you support so that your
                    account is setup to act on their behalf.
                </p>

                <div class="aix-form__group" formArrayName="additionalAdvisors">
                    @for (c of _additionalAdvisors?.controls; track c; let j = $index) {
                    <aix-additional-advisor-information
                        [removeButton]="j === 0"
                        [formControlName]="j"
                        (removeAdvisor)="removeAdditionalAdvisor(j)"
                    >
                    </aix-additional-advisor-information>
                    }

                    <div class="flex-right-center">
                        <button
                            class="aix-btn secondary br-btn--secondary full-width u-mt16"
                            type="button"
                            (click)="addAdditionalAdvisor()"
                        >
                            <span class="fa-regular fa-plus u-mr8"></span>Add Additional Advisor
                        </button>
                    </div>
                </div>
            </div>
            }
        </div>
        } @if (form.value?.role || form.value?.accountType === 'personal') {
        <div>
            <div class="header--section u-mt24">
                <div class="--heading">
                    <h3>Investment Information</h3>
                </div>
            </div>

            <h5>Investment Details</h5>

            <div class="aix-form__group u-mt16">
                <aix-textarea
                    formControlName="investmentDetails"
                    data-testing="investmentDetails"
                    [height]="138"
                    [config]="{
                        placeholder:
                            'Include the name of the investment or product(s) that you are looking to access.'
                    }"
                    [isRequired]="true"
                    [isDisabled]="false"
                    [isValid]="form.get('investmentDetails')?.valid"
                >
                    Investment Name
                </aix-textarea>
            </div>
        </div>
        }

        <div class="flex-right-center u-mt24 u-mb64">
            <button
                type="button"
                class="aix-btn aix-btn--primary br-btn br-btn--primary"
                [class.aix-button__disabled]="!form.valid"
                [disabled]="!form.valid"
                (click)="onClickSubmit()"
            >
                Submit
            </button>
        </div>
    </form>
</section>
} @if (formSent) {
<section class="u-mt64">
    <div class="flex flex-center-center flex-col sent-message">
        <span class="fa-regular fa-circle-check br-primary__color u-font-size--52 u-mt16"></span>
        <h3 class="u-mt16">Your information has been submitted.</h3>
        <p class="u-mt16">
            You will receive an email notification when your account has been created or if more
            information is needed to process your request.
        </p>
        <p class="u-mt8">Requests are reviewed and responded to within one business day.</p>
    </div>
</section>
}
