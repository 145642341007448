<div class="aix-toolbar u-mt24">
    <aix-button
        data-testing="continue-button"
        [buttonType]="continueButtonType"
        [buttonLabel]="'Continue'"
        (click)="onClickContinue.emit()"
        [isDisabled]="!signaturesCompleted || isReadOnly"
    >
    </aix-button>
</div>
